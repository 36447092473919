import instance from 'api/instance';
import {
  ICreatePost,
  IDeletePost,
  IGetPostById,
  IGetPosts,
  IUpdatePost,
  IPostByCategoryPostId,
} from './types';
import qs from 'query-string';

export const getPosts: IGetPosts = (params) =>
  instance.get(`posts?${qs.stringify(params)}`);

export const getPostById: IGetPostById = (id) => instance.get(`posts/${id}`);

export const getPostByCategoryPostId: IPostByCategoryPostId = (data) =>
  instance.get(`posts?${qs.stringify(data)}`);

export const createPost: ICreatePost = (data) => instance.post('posts', data);

export const updatePost: IUpdatePost = (id, data) =>
  instance.put(`posts/${id}`, data);

export const deletePost: IDeletePost = (id) => instance.delete(`posts/${id}`);
