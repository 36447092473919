import React from 'react';
import './Footer.sass';

export const Footer = () => {
	return (
		<div className='admin-footer'>
			<div className='admin-footer__copyright'>
				&copy; 2021 MARK IT SOLUTION
			</div>
		</div>
	);
};
