import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { IFormDefaultProps, IFormMultiSelect } from 'shared/Form/types';
import instance from 'api/instance';
import './FormItemMultiSelect.sass';
import { areEqual } from 'helpers/areEqual';

interface Props {
  data: IFormMultiSelect;
  defaultProps: IFormDefaultProps;
}

const FormItemMultiSelectComp: React.FC<Props> = ({ data, defaultProps }) => {
  const { value, label, options, searchable, helperText, errorMsg } = data;
  const { onChange, name, disabled, required } = defaultProps;

  const [searchableResults, setSearchableResults] = useState<IOption[]>();

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    if (!searchable) return;
    const { labelKey, url, valueKey, withPaginate } = searchable;

    const params = {
      ...(withPaginate
        ? {
            skip: 0,
            take: 20,
          }
        : {}),
    };

    const res = await instance
      .get(`${url}?${qs.stringify(params)}`)
      .then((res) => (withPaginate ? res.data.data : res.data));
    setSearchableResults(
      res?.map((i: any) => ({
        value: i[valueKey],
        label: i[labelKey],
      })),
    );
  };

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    onChange([{ name, value: e.target.value }]);
  };

  return (
    <FormControl
      variant="outlined"
      required={required}
      disabled={disabled}
      error={!!errorMsg}
    >
      <InputLabel variant="outlined">{label}</InputLabel>
      <Select
        label={label}
        multiple
        variant="outlined"
        value={value}
        onChange={handleChange}
      >
        {(options || searchableResults)?.map((i) => (
          <MenuItem
            key={i.label}
            className="select__options-item"
            value={i.value}
          >
            {i.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMsg || helperText}</FormHelperText>
    </FormControl>
  );
};

export const FormItemMultiSelect = React.memo(
  FormItemMultiSelectComp,
  areEqual,
);
