import { areEqual } from 'helpers/areEqual';
import React from 'react';
import { IFormDefaultProps, IFormHTML } from 'shared/Form/types';
import HtmlEditor from 'shared/HtmlEditor/HtmlEditor';

interface Props {
	data: IFormHTML;
	defaultProps: IFormDefaultProps;
}

const FormItemHTMLComp: React.FC<Props> = ({ data, defaultProps }) => {
	const { label, value, helperText, errorMsg } = data;
	const { name, onChange, disabled, required } = defaultProps;

	return (
		<HtmlEditor
			value={value}
			label={label}
			helperText={helperText}
			errorMsg={errorMsg}
			onChange={(value) => onChange([{ name, value }])}
			disabled={disabled}
			required={required}
			order={data?.order}
		/>
	);
};

export const FormItemHTML = React.memo(FormItemHTMLComp, areEqual);
