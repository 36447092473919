import React, { useState, useRef, useEffect } from 'react';
import { TableCell, TableHead } from '@material-ui/core';
import { AppState } from 'store/store';
import { langServices } from 'api/services';
import { Table, TableBody, TableRow } from '@material-ui/core';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Confirmation from 'shared/Confirmation/Confirmation';
import handleNotification from 'helpers/handleNotification';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { useSelector, useDispatch } from 'react-redux';
import * as langActions from 'store/ducks/langs/actions';
import getImageByImageSize from 'helpers/getImageByImageSize';
import { IResLang } from 'api/services/langs/types';
import './LangsPage.sass';
import { Link } from 'react-router-dom';

export const LangsPage: React.FC = () => {
	const confirmation = useRef<any>();
	const { langs, currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;
	const { user } = useSelector((state: AppState) => state.userReducer);
	const [loading, setLoading] = useState(false);
	const [langId, setLangId] = useState<number | undefined>();
	const dispatch = useDispatch();

	const deleteLang = async () => {
		if (!langId) return;
		if (langId === currentLang?.id) {
			return handleNotification(i18n.langs.changeCurrentLang);
		}
		setLoading(true);
		try {
			await langServices.deleteLang(langId);
			handleNotification(i18n.langs.removedLang, 'success');
			dispatch(langActions.getLangs());
		} catch {
			handleNotification(main.tryAgain);
		} finally {
			setLoading(false);
			setLangId(undefined);
		}
	};

	return (
		<>
			<Confirmation
				submit={deleteLang}
				title={i18n.langs.removeLang}
				text={i18n.langs.removeLangQuestion}
				load={loading}
				ref={confirmation}
			/>
			<MainTemplate
				title={i18n.langs.langs}
				url='/languages/create'
				hideBtn={user?.role !== 'SU'}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.code}</TableCell>
							<TableCell>{main.image}</TableCell>
							<TableCell>{main.createdDate}</TableCell>
							<TableCell className='table-last-item'>{main.update}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{langs &&
							langs?.map((i: IResLang) => (
								<TableRow key={i.id}>
									<TableCell>{i.title}</TableCell>
									<TableCell>{i.code}</TableCell>
									<TableCell>
										{i.file && (
											<div className='page-table__image'>
												<img
													className='page-table__image-icon'
													src={getImageByImageSize({
														src: i.file.src,
													})}
												/>
											</div>
										)}
									</TableCell>
									<TableCell>
										{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
									</TableCell>
									<TableCell className='table-last-item'>
										{user?.role === 'SU' && (
											<Link
												to={`/languages/${i.id}`}
												className='table-icon table-icon--black'>
												<EditOutlinedIcon />
											</Link>
										)}
										<DeleteForeverIcon
											className='table-icon'
											color='secondary'
											onClick={() => {
												confirmation.current.onShow();
												setLangId(i.id);
											}}
										/>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</MainTemplate>
		</>
	);
};
