import React from 'react';
import { TextField } from '@material-ui/core';
import { IFormDefaultProps, IFormText } from 'shared/Form/types';
import { areEqual } from 'helpers/areEqual';

interface Props {
	data: IFormText;
	defaultProps: IFormDefaultProps;
}

const FormItemTextComp: React.FC<Props> = ({ data, defaultProps }) => {
	const { label, value, helperText, errorMsg, inputType = 'text' } = data;
	const { name, onChange, required, disabled } = defaultProps;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		onChange([{ name, value: e.target.value }]);
	};

	return (
		<div className='form-item-text'>
			<TextField
				variant='outlined'
				value={value}
				label={`${label} ${required ? '*' : ''}`}
				onChange={handleChange}
				helperText={errorMsg || helperText}
				disabled={disabled}
				error={!!errorMsg}
				type={inputType}
				autoComplete='off'
			/>
		</div>
	);
};

export const FormItemText = React.memo(FormItemTextComp, areEqual);
