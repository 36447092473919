import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { transliterate } from 'helpers/transliterate';
import { pageServices, settingsServices, templateServices } from 'api/services';
import { IResPage, IPage } from 'api/services/pages/types';
import { IResTemplate } from 'api/services/templates/types';
import handleNotification from 'helpers/handleNotification';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Form from 'shared/Form/Form';
import { validationPayload } from 'shared/Form/helpers/validation';
import {
	FormControl,
	Grid,
	FormControlLabel,
	Input,
	InputLabel,
	Select,
	TextField,
	Switch,
	MenuItem,
	Button,
} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import './CreatePage.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Link, useHistory } from 'react-router-dom';
import { ISeoPayload, seoFields, seoView } from 'constants/seo';

export const CreatePage: React.FC = () => {
	const { user } = useSelector((state: AppState) => state.userReducer);
	const [titlePage, setTitlePage] = useState('');
	const [visibility, setVisibility] = useState(true);
	const [templateId, setTemplateId] = useState<number>();
	const [parentId, setParentId] = useState<string>();
	const [templates, setTemplates] = useState<IResTemplate[]>([]);
	const [slug, setSlug] = useState('');
	const [pages, setPages] = useState<IResPage[]>([]);
	const [loading, setLoading] = useState(false);
	const [activeTemplate, setActiveTemplate] = useState<IResTemplate>();
	const [hasError, setHasError] = useState(false);
	const [seo, setSeo] = useState<ISeoPayload>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;
	const history = useHistory();
	const formRef = useRef<any>();
	const seoRef = useRef<any>();

	useEffect(() => {
		getPages();
		getTemplates();
		getSettings();
	}, [currentLang?.code]);

	useEffect(() => {
		setActiveTemplate(templates.find((i) => i.id === templateId));
	}, [templateId, templates.length]);

	const getSettings = async () => {
		const res = await settingsServices
			.getSettings()
			.then((res) => res.data.data);
		const { title, description, ogImage, keywords } = res?.seo as any;
		if (res) {
			setSeo({
				title,
				description,
				ogImage,
				keywords,
			});
		}
	};

	const generationLink = (value: string) => {
		setTitlePage(value);
		setSlug(transliterate(value.toLowerCase()));
	};

	const getPages = async () => {
		const res = await pageServices.getPages().then((res) => res.data);
		setPages(res);
	};

	const getTemplates = async () => {
		const query = {
			type: 'PAGE' as 'PAGE',
		};
		const res = await templateServices
			.getTemplates(query)
			.then((res) => res.data);
		setTemplates(res);
	};

	const savePage = async () => {
		if (!titlePage || !templateId || !slug.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}

		const formRes: validationPayload = formRef.current?.submit();
		if (formRes && (!formRes || (formRes?.hasErrors && user?.role !== 'SU'))) {
			handleNotification(main.fillFields);
			return;
		}
		const seoRes = seoRef.current?.submit();

		const pageData: IPage = {
			seoDescription: seoRes?.description,
			seoTitle: seoRes.res.title,
			seoOgImage: seoRes.res.ogImage,
			seoKeywords: seoRes.res.keywords,
			visibility,
			title: titlePage,
			slug: slug === '/' ? '' : slug,
			data: formRes?.res ?? {},
			templateId: Number(templateId),
			parentId: Number(parentId) || null,
		};
		setLoading(true);
		try {
			const res = await pageServices
				.createPage(pageData)
				.then((res) => res.data);
			handleNotification(main.saveSuccess, 'success');
			history.push(`/pages/${res.id}`);
		} catch {
			handleNotification(main.tryAgain);
			setLoading(false);
			setHasError(false);
		}
	};

	return (
		<MainTemplate title={i18n.pages.createPage} hideBtn>
			<Grid container alignItems='flex-start'>
				<Grid item xs={8} className='create-page__main'>
					<Grid item xs={12} className='create-page__container'>
						<FormControl
							className='create-page__main-title'
							error={!titlePage && hasError}>
							<Input
								className='create-page__main-title-input'
								value={titlePage || ''}
								autoFocus
								placeholder={main.title}
								onChange={(e) => generationLink(e.target.value as string)}
							/>
						</FormControl>
						{activeTemplate && (
							<Form
								ref={formRef}
								fields={activeTemplate?.properties}
								view={activeTemplate?.view}
							/>
						)}
					</Grid>
					<Grid item xs={12} className='create-page__seo'>
						<Form
							ref={seoRef}
							view={seoView}
							fields={seoFields}
							initialData={seo}
						/>
					</Grid>
				</Grid>
				<Grid item xs={4} container spacing={2} className='create-page__aside'>
					<Grid item xs={12}>
						<TextField
							className='create-page__aside-input'
							value={slug || ''}
							label={i18n.pages.pathPage}
							error={!slug.trim() && hasError}
							variant='outlined'
							onChange={(e) => setSlug(e.target.value as string)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' error={!templateId && hasError}>
							<InputLabel id='categories'>{main.template}</InputLabel>
							<Select
								label={main.template}
								value={templateId || ''}
								onChange={(e) => setTemplateId(e.target.value as number)}>
								{templates.map((i) => (
									<MenuItem className='select__item' key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					{!!activeTemplate?.components.length && (
						<Grid item xs={12}>
							<ul className='page__component-list'>
								{activeTemplate?.components.map((i) => (
									<li className='page__component-list-item' key={i.id}>
										<Link
											className='page__component-list-item-link'
											to={`/components/${i.id}`}>
											{i.title}
										</Link>
									</li>
								))}
							</ul>
						</Grid>
					)}
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={visibility}
									onChange={() => setVisibility(!visibility)}
									color='primary'
								/>
							}
							label={main.published}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined'>
							<InputLabel id='categories'>{i18n.pages.parentPage}</InputLabel>
							<Select
								label={i18n.pages.parentPage}
								value={parentId || ''}
								onChange={(e) => setParentId(e.target.value as string)}>
								{pages.map((i) => (
									<MenuItem className='select__item' key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} container justifyContent='flex-end'>
						<Button
							startIcon={!loading && <SaveAltIcon />}
							color='primary'
							variant='contained'
							onClick={savePage}
							disabled={loading}>
							{loading ? (
								<Loader width={100} height={10} type='ThreeDots' color='#fff' />
							) : (
								main.save
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
