import React, { useEffect, useState, useRef } from 'react';
import {
	FormControl,
	Grid,
	FormControlLabel,
	Input,
	InputLabel,
	Select,
	TextField,
	Switch,
	MenuItem,
	Button,
} from '@material-ui/core';
import { transliterate } from 'helpers/transliterate';
import { pageServices, templateServices } from 'api/services';
import { getPageById } from 'api/services/pages/pages';
import { IResPage, IPage } from 'api/services/pages/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useParams } from 'react-router-dom';
import { IResTemplate } from 'api/services/templates/types';
import Loader from 'react-loader-spinner';
import handleNotification from 'helpers/handleNotification';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Form from 'shared/Form/Form';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { UserRoles } from 'api/services/user/types';
import { ISeoPayload, seoFields, seoView } from 'constants/seo';
import './UpdatePage.sass';

export const UpdatePage: React.FC = () => {
	const [titlePage, setTitlePage] = useState('');
	const [visibility, setVisibility] = useState(false);
	const [templateId, setTemplateId] = useState<number>();
	const [parentId, setParentId] = useState<number | string>();
	const [templateList, setTemplateList] = useState<IResTemplate[]>([]);
	const [slug, setSlug] = useState('');
	const [pages, setPages] = useState<IResPage[]>([]);
	const [loading, setLoading] = useState(false);
	const { pageId } = useParams<{ pageId: string }>();
	const [data, setData] = useState<IAnyObject>();
	const [activeTemplate, setActiveTemplate] = useState<IResTemplate>();
	const formRef = useRef<any>();
	const seoRef = useRef<any>();
	const [seo, setSeo] = useState<ISeoPayload>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;

	useEffect(() => {
		getPages();
		getCurrentPage();
		getTemplates();
	}, [currentLang?.code]);

	useEffect(() => {
		setActiveTemplate(templateList.find((i) => i.id === templateId));
	}, [templateId, templateList.length]);

	const getCurrentPage = async () => {
		const res = await getPageById(pageId).then((res) => res.data);
		setVisibility(res.visibility);
		setSlug(res.slug || '');
		setTitlePage(res.title);
		setTemplateId(res.templateId);
		setData(res.data);
		setParentId(res.parentId ?? '');
		setSeo({
			title: res.seoTitle,
			description: res.seoDescription,
			keywords: res.seoKeywords,
			ogImage: res.seoOgImage,
		});
	};

	const generationLink = (value: string) => {
		setTitlePage(value);
		setSlug(() => transliterate(value));
	};

	const getTemplates = async () => {
		const query = {
			type: 'PAGE' as 'PAGE',
		};
		const res = await templateServices
			.getTemplates(query)
			.then((res) => res.data);
		setTemplateList(res);
	};

	const getPages = async () => {
		const res = await pageServices
			.getPages({ exclude: pageId })
			.then((res) => res.data);
		setPages(res);
	};

	const savePage = async () => {
		if (!titlePage) return;
		const formRes = formRef.current?.submit();
		if (
			formRes &&
			(!formRes || (formRes?.hasErrors && user?.role !== UserRoles.SU))
		) {
			handleNotification(main.fillFields);
			return;
		}
		const seoRes = seoRef.current?.submit();
		const pageData: IPage = {
			visibility,
			title: titlePage,
			data: formRes?.res || {},
			slug,
			parentId: Number(parentId) || null,
			templateId: Number(templateId),
			seoDescription: seoRes.res.description,
			seoTitle: seoRes.res.title,
			seoOgImage: seoRes.res.ogImage,
			seoKeywords: seoRes.res.keywords,
		};

		setLoading(true);
		try {
			await pageServices.updatePage(pageId, pageData);
			handleNotification(main.saveSuccess, 'success');
			getPages();
		} catch {
			handleNotification(main.tryAgain);
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={i18n.pages.updatePage} hideBtn>
			<Grid container alignItems='flex-start'>
				<Grid item xs={8} className='update-page__main'>
					<Grid item xs={12} className='update-page__container'>
						<FormControl className='update-page__main-title'>
							<Input
								className='update-page__main-title-input'
								value={titlePage || ''}
								placeholder={main.title}
								autoFocus
								onChange={(e) => generationLink(e.target.value)}
							/>
						</FormControl>
						{activeTemplate && (
							<Form
								ref={formRef}
								fields={activeTemplate?.properties}
								view={activeTemplate?.view}
								initialData={data}
								hasInitialData={true}
							/>
						)}
					</Grid>
					<Grid item xs={12} className='update-page__seo'>
						<Form
							ref={seoRef}
							view={seoView}
							fields={seoFields}
							initialData={seo}
						/>
					</Grid>
				</Grid>
				<Grid item xs={4} container spacing={2} className='update-page__aside'>
					<Grid item xs={12}>
						<TextField
							className='update-page__aside-input'
							value={slug || ''}
							label={i18n.pages.pathPage}
							variant='outlined'
							onChange={(e) => setSlug(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined'>
							<InputLabel id='categories'>{main.template}</InputLabel>
							<Select
								label={main.template}
								value={templateId || ''}
								onChange={(e) => setTemplateId(e.target.value as number)}>
								{templateList.map((i) => (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={visibility}
									onChange={() => setVisibility(!visibility)}
									color='primary'
								/>
							}
							label={main.published}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined'>
							<InputLabel id='categories'>{i18n.pages.parentPage}</InputLabel>
							<Select
								label={i18n.pages.parentPage}
								value={parentId || ''}
								onChange={(e) => setParentId(e.target.value as number)}>
								{pages.map((i) => (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} container justifyContent='flex-end'>
						<Button
							startIcon={<SaveAltIcon />}
							color='primary'
							variant='contained'
							onClick={savePage}
							disabled={loading}>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								main.save
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
