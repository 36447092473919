import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import { IComponent } from 'api/services/components/types';
import { componentsServices } from 'api/services';
import { useHistory } from 'react-router';
import redirect from 'helpers/redirect';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { useEffect } from 'react';

export const CreateComponentPage: React.FC = () => {
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);
	const history = useHistory();

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	const createComponent = async (e: React.FormEvent) => {
		e.preventDefault();
		let validation = true;
		if (!name.trim()) {
			validation = false;
		}

		if (!title.trim()) {
			validation = false;
		}

		if (!validation) {
			setHasError(true);
			handleNotification(main.fillField);
			return;
		}

		const data: IComponent = {
			name,
			title,
		};

		try {
			const res = await componentsServices
				.createComponent(data)
				.then((res) => res.data);
			handleNotification(main.savedComponent, 'success');
			history.push(`/components/${res.id}`);
		} catch (e: any) {
			handleNotification(e?.response?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={main.createComponent} hideBtn>
			<form onSubmit={createComponent} className='create-components-page'>
				<TextField
					variant='outlined'
					label={main.title}
					className='template-page__field form-field'
					autoFocus
					value={title}
					error={hasError && !title.trim()}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='template-page__field form-field'
					value={name}
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						main.createComponent
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
