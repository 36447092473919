import { TemplateTypes } from 'api/services/templates/types';
import data from 'store/ducks/langs/data';

export default (lang: string) => {
	const { post, page } = data[lang].main;
	return [
		{
			value: TemplateTypes.POST,
			label: post,
		},
		{
			value: TemplateTypes.PAGE,
			label: page,
		},
	];
};
