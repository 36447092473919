import React, { useEffect, useState, useRef } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { taxonomyServices } from 'api/services';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import getImageByImageSize from 'helpers/getImageByImageSize';
import Confirmation from 'shared/Confirmation/Confirmation';
import redirect from 'helpers/redirect';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';

import './TaxonomiesPage.sass';
import { getTaxonomies } from 'store/ducks/taxonomies/actions';

export const TaxonomiesPage: React.FC = () => {
	const allStates = useSelector((state: AppState) => state);
	const { i18n } = allStates.langReducer;
	const { main } = i18n;
	const { user } = allStates.userReducer;
	const [taxonomyId, setTaxonomyId] = useState<number | undefined>();
	const { taxonomies } = allStates.taxonomyReducer;
	const confirmation = useRef<any>();
	const dispatch = useDispatch();

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	const deleteTaxonomy = async () => {
		if (!taxonomyId) return;
		await taxonomyServices.deleteTaxonomy(taxonomyId).then((res) => res.data);
		dispatch(getTaxonomies());
		handleNotification(main.removeSuccess, 'success');
	};

	const clearId = () => {
		setTaxonomyId(undefined);
	};

	return (
		<>
			<Confirmation
				title={i18n.taxonomies.removeTaxonomy}
				text={i18n.taxonomies.removeTaxonomyQuestion}
				submit={deleteTaxonomy}
				changeState={clearId}
				ref={confirmation}
			/>
			<MainTemplate title={main.taxonomies} url='/taxonomies/create'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.title}</TableCell>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.image}</TableCell>
							<TableCell>{main.updatedDate}</TableCell>
							<TableCell className='table-last-item'>{main.update}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{taxonomies?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>
									<div className='page-table__image'>
										<img
											src={getImageByImageSize({ src: i.file?.src })}
											alt=''
										/>
									</div>
								</TableCell>
								<TableCell>
									{moment(i.updatedAt).format('DD.MM.YYYY HH:mm')}
								</TableCell>
								<TableCell className='table-last-item'>
									<Link
										to={`/taxonomies/${i.id}`}
										className='table-icon table-icon--black'>
										<EditIcon />
									</Link>
									<DeleteForeverIcon
										color='secondary'
										className='table-icon'
										onClick={() => {
											confirmation.current.onShow();
											setTaxonomyId(i.id);
										}}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</MainTemplate>
		</>
	);
};
