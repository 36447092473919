export enum AuthTypes {
	SIGN_IN = '@auth/SIGN_IN',
	SIGN_OUT = '@auth/SIGN_OUT',
}

type ISignIn = {
	type: AuthTypes.SIGN_IN;
};

type ISignOut = {
	type: AuthTypes.SIGN_OUT;
};

export type AuthActionTypes = ISignIn | ISignOut;

export interface IAuthInitState {
	accessToken?: string;
	authorized: boolean;
}
