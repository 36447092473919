import React from 'react';
import { TextField } from '@material-ui/core';
import { IFormDefaultProps, IFormTextArea } from 'shared/Form/types';
import { areEqual } from 'helpers/areEqual';
import cn from 'classnames';

import './FormItemTextArea.sass';

interface Props {
	data: IFormTextArea;
	defaultProps: IFormDefaultProps;
}

const FormItemTextAreaComp: React.FC<Props> = ({ data, defaultProps }) => {
	const { label, value, helperText, errorMsg } = data;
	const { name, onChange, disabled, required } = defaultProps;

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange([{ name, value: e.target.value }]);
	};
	return (
		<TextField
			multiline
			label={label}
			rows={5}
			value={value}
			variant='outlined'
			onChange={handleChange}
			helperText={errorMsg || helperText}
			disabled={disabled}
			className={cn({ 'form-item-text-area--error': !!errorMsg })}
		/>
	);
};

export const FormItemTextArea = React.memo(FormItemTextAreaComp, areEqual);
