import instance from 'api/instance';
import {
  ICreateDict,
  IDeleteDict,
  IGetDictById,
  IGetDicts,
  IUpdateDict,
} from './types';

export const getDicts: IGetDicts = () => instance.get('dicts');

export const getDictById: IGetDictById = (id) => instance.get(`dicts/${id}`);

export const createDict: ICreateDict = (data) => instance.post('dicts', data);

export const updateDict: IUpdateDict = (id, data) =>
  instance.put(`dicts/${id}`, data);

export const deleteDict: IDeleteDict = (id) => instance.delete(`dicts/${id}`);
