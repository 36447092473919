import React, { useState, useEffect } from 'react';
import { ListItem, SvgIconTypeMap } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import PagesIcon from '@material-ui/icons/Pages';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import CategoryIcon from '@material-ui/icons/Category';
import NotesIcon from '@material-ui/icons/Notes';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GavelIcon from '@material-ui/icons/Gavel';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import getImageByImageSize from 'helpers/getImageByImageSize';
import ImageIcon from '@material-ui/icons/Image';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LanguageIcon from '@material-ui/icons/Language';
import { v1 as uuidv1 } from 'uuid';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import _ from 'lodash';
import cn from 'classnames';

import './Sidebar.sass';

interface INAV {
	link?: string;
	icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
	text: string;
	img?: string;
	id: string;
	items?: INAV[];
	roles: string[];
	taxonomyId?: number;
}

interface Props {
	setSidebarStatus(status: boolean): void;
	sidebarStatus: boolean;
}

const roles = ['SU', 'USER', 'ADMIN'];

export const Sidebar: React.FC<Props> = ({
	setSidebarStatus,
	sidebarStatus,
}) => {
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;

	const [sidebarItems, setSidebarItems] = useState<INAV[]>([]);
	const [selectedSidebarItems, setSelectedSidebarItems] = useState<string[]>(
		[],
	);
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { taxonomies } = useSelector(
		(state: AppState) => state.taxonomyReducer,
	);

	useEffect(() => {
		getTaxonomies();
		return () => setSidebarItems([]);
	}, [currentLang?.code, taxonomies?.length]);

	const checkSelectedItems = (id: string) => {
		if (!id) return;
		return selectedSidebarItems.some((l) => l === id);
	};

	const dropdownHandler = async (item: INAV) => {
		const isMultiple = !!item.items;
		const { id } = item;
		if (!isMultiple) return;
		setSelectedSidebarItems((prev) => [...prev, id]);
		if (checkSelectedItems(id)) {
			const removeItem = selectedSidebarItems.filter((i) => i !== id);
			setSelectedSidebarItems(removeItem);
		}
	};

	const getTaxonomies = async () => {
		const initValue: INAV[] = [
			{
				text: main.pages,
				link: '/pages',
				icon: PagesIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.media,
				link: '/media',
				icon: PermMediaIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.templates,
				link: '/templates',
				icon: FileCopyIcon,
				roles: ['SU'],
				id: uuidv1(),
			},
			{
				text: main.components,
				link: '/components',
				icon: GavelIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.menu,
				link: '/menu',
				icon: MenuIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.forms,
				link: '/forms',
				icon: NotesIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.directories,
				link: '/dicts',
				icon: ImageIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.users,
				link: '/users',
				icon: GroupIcon,
				roles: roles,
				id: uuidv1(),
			},
			{
				text: main.settings,
				icon: SettingsApplicationsIcon,
				roles: roles,
				id: uuidv1(),
				items: [
					{
						text: main.main,
						icon: SettingsIcon,
						link: '/settings',
						roles: roles,
						id: uuidv1(),
					},
					{
						text: main.taxonomies,
						icon: GroupWorkIcon,
						link: '/taxonomies',
						roles: ['SU'],
						id: uuidv1(),
					},
					{
						text: main.imgSizes,
						link: '/img-sizes',
						icon: ImageIcon,
						roles: roles,
						id: uuidv1(),
					},
					{
						text: main.langs,
						link: '/languages',
						icon: LanguageIcon,
						roles: roles,
						id: uuidv1(),
					},
				],
			},
		];
		const clone = _.cloneDeep(initValue);
		if (
			sidebarItems.some((i) => i.text === main.postCategories) ||
			sidebarItems.some((i) => i.text === main.postList)
		)
			return;
		const taxArr = taxonomies?.map((i) => ({
			text: i.title,
			img: i.file?.src,
			roles: roles,
			id: uuidv1(),
			items: [
				{
					text: main.postCategories,
					link: `/taxonomies/${i.id}/categories`,
					icon: CategoryIcon,
					roles: roles,
					id: uuidv1(),
				},
				{
					text: main.postList,
					link: `/taxonomies/${i.id}/posts`,
					icon: ListAltIcon,
					roles: roles,
					id: uuidv1(),
				},
			],
		}));
		if (taxArr && taxArr.length > 0) clone.splice(1, 0, ...taxArr);

		setSidebarItems(clone);
	};

	const handleSidebarVisibility = () => {
		window.localStorage.setItem(
			'showSidebar',
			!sidebarStatus ? 'true' : 'false',
		);
		setSidebarStatus(!sidebarStatus);
	};

	const renderMenu = (
		list: INAV[] | undefined = sidebarItems,
		isChild = false,
	) => {
		return list?.map((i) => (
			<React.Fragment key={i.id}>
				{i.roles.some((ii) => ii === user?.role) && (
					<ListItem
						component={i.link ? Link : 'button'}
						className={cn('sidebar__menu-item', {
							'sidebar__menu-item--child': isChild,
						})}
						to={i.link}
						onClick={() => dropdownHandler(i)}>
						{i.icon && <i.icon className='sidebar__menu-item-icon' />}
						{i.img && (
							<img
								className='sidebar__menu-item-icon'
								alt=''
								src={getImageByImageSize({ src: i.img })}
							/>
						)}
						{i.text}
						{i.items && (
							<div
								className={cn('sidebar__menu-item-arrow', {
									'sidebar__menu-item-arrow--rotate': checkSelectedItems(i.id),
								})}>
								<ArrowForwardIosIcon />
							</div>
						)}
					</ListItem>
				)}
				{i.items && checkSelectedItems(i.id) && renderMenu(i.items, true)}
			</React.Fragment>
		));
	};

	return (
		<>
			<div className={cn('sidebar', { 'sidebar--hide': sidebarStatus })}>
				<div
					className={cn('sidebar__handle', {
						'sidebar__handle--hide': sidebarStatus,
					})}>
					<div
						className='sidebar__handle-button'
						onClick={handleSidebarVisibility}>
						<ArrowLeftIcon />
					</div>
				</div>
				<div className='sidebar__menu'>{renderMenu()}</div>
			</div>
		</>
	);
};
