import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import arrayToTree from 'array-to-tree';
import moment from 'moment';
import { menuItemServices } from 'api/services';
import { IMenuItem, IResMenuItem } from 'api/services/menuItems/types';
import { menuServices } from 'api/services';
import handleNotification from 'helpers/handleNotification';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomDND from 'shared/CustomDND/CustomDND';
import CustomModal from 'shared/CustomModal/CustomModal';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import Form from 'shared/Form/Form';
import { fields, view } from './formData';
import { SERVER_URL } from 'constants/project';
import 'react-nestable/dist/styles/index.css';
import './MenuItemPage.sass';

export const MenuItemPage: React.FC = () => {
	const { menuId } = useParams<{ menuId: string }>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, menu } = i18n;
	const [activeMenuItem, setActiveMenuItem] = useState<IResMenuItem>();
	const [menuItems, setMenuItems] = useState<IResMenuItem[]>([]);
	const [loading, setLoading] = useState(true);
	const confirmationRef = useRef<any>(null);
	const createModalRef = useRef<any>();
	const updateModalRef = useRef<any>();
	const createFormRef = useRef<any>();
	const updateFormRef = useRef<any>();

	useEffect(() => {
		getMenu();
	}, [currentLang?.code]);

	const buildTree = async (menuItems: IResMenuItem[]) => {
		const tree = await arrayToTree(menuItems, {
			parentProperty: 'parentId',
			customID: 'id',
			childrenProperty: 'children',
		});
		setMenuItems(tree);
	};

	const getMenu = async () => {
		const res = await menuServices.getMenuById(menuId).then((res) => res.data);
		buildTree(res.menuItems);
		setLoading(false);
	};

	const createMenuItem = async () => {
		const formRes = createFormRef.current?.submit();
		if (formRes && (!formRes || formRes.hasErrors)) {
			handleNotification(main.fillFields);
			return;
		}
		const {
			label,
			link,
			newWindow,
			postId,
			pageId,
			taxonomyId,
			postCategoryId,
			type,
			fileId,
			image,
		} = formRes.res;

		const requestData: IMenuItem = {
			label,
			link,
			newWindow,
			menuId: Number(menuId),
			fileId,
			postId,
			pageId,
			taxonomyId,
			postCategoryId,
			type,
			image,
		};
		try {
			await menuItemServices.createMenuItem(requestData);
			handleNotification(main.saved, 'success');
			getMenu();
			createModalRef.current.onHide();
		} catch (e: any) {
			handleNotification(e?.response.data.message || main.tryAgain);
		}
	};

	const updateMenuItem = async () => {
		if (!activeMenuItem) return;

		const formRes = updateFormRef.current?.submit();
		if (!formRes || formRes.hasErrors) {
			handleNotification(main.fillFields);
			return;
		}
		const {
			label,
			link,
			newWindow,
			postId,
			pageId,
			taxonomyId,
			postCategoryId,
			type,
			fileId,
			image,
		} = formRes.res;

		const requestData: IMenuItem = {
			label,
			link,
			newWindow,
			menuId: Number(menuId),
			fileId,
			postId,
			pageId,
			taxonomyId,
			postCategoryId,
			type,
			image,
		};
		try {
			await menuItemServices.updateMenuItem(activeMenuItem.id, requestData);
			handleNotification(menu.updatedMenu, 'success');
			getMenu();
			updateModalRef.current.onHide();
		} catch (e: any) {
			handleNotification(e?.response.data.message || main.tryAgain);
		}
	};

	const deleteMenuItem = async () => {
		if (!activeMenuItem) return;
		await menuItemServices.deleteMenuItem(activeMenuItem.id);
		handleNotification(menu.removedMenu, 'success');
		getMenu();
	};

	const renderItem = ({ item, collapseIcon }: any) => (
		<ul className='menu-item-page__list nestable__list'>
			<li className='nestable__text menu-item-page__text--padding'>
				{collapseIcon} {item.label}
			</li>
			<li className='nestable__text menu-item-page__text--padding'>
				{item.link}
			</li>
			<li className='nestable__text'>{item.newWindow ? main.yes : main.no}</li>
			<li className='nestable__image'>
				{item.image && (
					<img src={`${SERVER_URL}/${item.image}`} alt={item.file?.alt || ''} />
				)}
			</li>
			<li className='nestable__text'>
				{moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
			</li>
			<li className='table-last-item'>
				<EditIcon
					onClick={() => {
						setActiveMenuItem(item);
						updateModalRef.current?.onShow();
					}}
					className='table-icon'
				/>
				<DeleteForeverIcon
					onClick={() => {
						setActiveMenuItem(item);
						confirmationRef.current.onShow();
					}}
					color='secondary'
					className='table-icon'
				/>
			</li>
		</ul>
	);

	return (
		<MainTemplate
			title={menu.menuLinks}
			handler={() => createModalRef.current?.onShow()}>
			<div className='menu-items__container'>
				<ul className='nestable__list menu-item-page__list'>
					<li className='nestable__text MuiTableCell-head'>{main.name}</li>
					<li className='nestable__text MuiTableCell-head'>{main.link}</li>
					<li className='nestable__text MuiTableCell-head'>{main.newWindow}</li>
					<li className='nestable__text MuiTableCell-head'>{main.image}</li>
					<li className='nestable__text MuiTableCell-head'>
						{main.updatedDate}
					</li>
					<li className='nestable__text MuiTableCell-head table-last-item'>
						{main.action}
					</li>
				</ul>
				<CustomDND
					items={menuItems}
					renderItems={renderItem}
					submitDrag={menuItemServices.changeMenuItemOrder}
					refetchData={getMenu}
				/>
			</div>

			<Confirmation
				ref={confirmationRef}
				text={menu.removeLinkQuestion}
				submit={deleteMenuItem}
				title={menu.removeLink}
				load={loading}
				changeState={() => setActiveMenuItem(undefined)}
			/>
			<CustomModal
				ref={createModalRef}
				submit={createMenuItem}
				title={menu.createLink}
				text={main.create}
				changeState={() => setActiveMenuItem(undefined)}>
				<Form fields={fields} view={view} ref={createFormRef} />
			</CustomModal>
			<CustomModal
				ref={updateModalRef}
				submit={updateMenuItem}
				title={menu.editLink}
				text={main.update}
				changeState={() => setActiveMenuItem(undefined)}>
				<Form
					fields={fields}
					view={view}
					initialData={activeMenuItem}
					hasInitialData
					ref={updateFormRef}
				/>
			</CustomModal>
		</MainTemplate>
	);
};
