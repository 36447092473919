import { Reducer } from 'redux';
import { AuthTypes, IAuthInitState } from './types';

const initState = {
	accessToken: undefined,
	authorized: false,
};

const reducer: Reducer<IAuthInitState> = (
	state = initState,
	{ type, payload },
) => {
	switch (type) {
		case AuthTypes.SIGN_IN:
			return {
				...state,
				accessToken: payload.accessToken,
				authorized: true,
			};
		case AuthTypes.SIGN_OUT:
			return {
				...state,
				accessToken: '',
				authorized: false,
			};
		default:
			return state;
	}
};

export default reducer;
