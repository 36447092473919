import { UserTypes } from './types';
import { IResUser } from 'api/services/user/types';

export const updateProfile = (user: IResUser) => {
	localStorage.setItem('user', JSON.stringify(user));
	return {
		type: UserTypes.UPDATE_USER,
		payload: {
			user: user,
		},
	};
};
