import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import { ILang } from 'api/services/langs/types';
import { IFile } from 'api/services/file/types';
import getImageByImageSize from 'helpers/getImageByImageSize';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { langServices } from 'api/services';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { useSelector } from 'react-redux';
import redirect from 'helpers/redirect';
import { AppState } from 'store/store';

import './UpdateLangPage.sass';

export const UpdateLangPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	const [file, setFile] = useState<IFile>();
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [hasError, setHasError] = useState(false);
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, langs } = i18n;

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	useEffect(() => {
		getLangData();
	}, [currentLang?.code]);

	const updateLang = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!name.trim() || !file || !code.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}
		setLoading(true);
		const data: ILang = {
			title: name,
			code,
			fileId: file.id,
		};

		try {
			await langServices.updateLang(Number(id), data);
			handleNotification(langs.savedLang, 'success');
			history.push('/languages');
		} catch (e: any) {
			handleNotification(e?.response?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	const getLangData = async () => {
		const res = await langServices
			.getLangById(Number(id))
			.then((res) => res.data);
		setName(res.title);
		setCode(res.code);
		setFile(res.file);
	};

	const selectFile = (file: IFile) => {
		setFile(file);
	};

	return (
		<MainTemplate title={langs.updateLang} hideBtn>
			<form>
				<TextField
					variant='outlined'
					label={main.name}
					className='form-field'
					value={name}
					required
					autoFocus
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={langs.codeLang}
					required
					className='form-field'
					value={code}
					error={hasError && !code.trim()}
					onChange={(e) => setCode(e.target.value)}
				/>
				{file && (
					<div className='page-form__file'>
						<img
							className='page-form__file-icon'
							src={getImageByImageSize({ src: file.src })}
						/>
						<div className='page-form__file-delete'>
							<DeleteForeverIcon
								color='secondary'
								onClick={() => setFile(undefined)}
							/>
						</div>
					</div>
				)}
				<Grid
					container
					justifyContent='space-between'
					className='page-form__actions'>
					<FileUploader
						selectFile={selectFile}
						width={40}
						height={40}
						text={file ? main.changeIcon : langs.selectLangIcon}
					/>
					<Button
						className='action-button'
						size='large'
						color='primary'
						type='submit'
						variant='contained'
						onClick={updateLang}
						disabled={loading}>
						{loading ? (
							<Loader height={10} type='ThreeDots' color='#fff' />
						) : (
							main.save
						)}
					</Button>
				</Grid>
			</form>
		</MainTemplate>
	);
};
