import React, { useEffect, useRef, useState } from 'react';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { formServices } from 'api/services';
import { IResForm } from 'api/services/forms/types';
import { Link } from 'react-router-dom';
import {
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Table,
} from '@material-ui/core';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomModal from 'shared/CustomModal/CustomModal';
import Form from 'shared/Form/Form';
import { fields, view } from 'pages/FormPage/formData';
import handleNotification from 'helpers/handleNotification';

export const FormsPage: React.FC = () => {
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, requests } = i18n;
	const { authorized } = useSelector((state: AppState) => state.authReducer);
	const [data, setData] = useState<IResForm[]>();
	const [loading, setLoading] = useState(false);
	const [activeForm, setActiveForm] = useState<IResForm>();
	const createModalRef = useRef<any>();
	const createFormRef = useRef<any>();
	const updateModalRef = useRef<any>();
	const updateFormRef = useRef<any>();
	const ref = useRef<any>();

	useEffect(() => {
		getData();
	}, [currentLang?.code]);

	const getData = async () => {
		const res = await formServices.getForms().then((res) => res.data);
		setData(res);
	};

	const openUpdateModal = (item: IResForm) => {
		setActiveForm(item);
		updateModalRef.current.onShow();
	};

	const openCreateModal = () => {
		createModalRef.current.onShow();
	};

	const openDeleteModal = (item: IResForm) => {
		setActiveForm(item);
		ref.current.onShow();
	};

	const createForm = async () => {
		setLoading(true);
		const formRes = createFormRef.current?.submit();

		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFields);
			setLoading(false);
			return;
		}

		const requestData = {
			email: formRes.res.email,
			name: formRes.res.name,
			title: formRes.res.title,
		};
		try {
			await formServices.creeateForm(requestData);
			getData();
			createFormRef.current.onHide();
		} finally {
			setLoading(false);
		}
	};

	const updateForm = async () => {
		if (!activeForm) return;
		const formRes = updateFormRef.current?.submit();
		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFields);
			return;
		}

		const requestData = {
			email: formRes.res.email,
			name: formRes.res.name,
			title: formRes.res.title,
		};
		try {
			setLoading(true);
			await formServices.updateForm(activeForm.id, requestData);
			// updateFormRef.current.onHide();
			getData();
		} finally {
			setLoading(false);
		}
	};

	const deleteForm = async () => {
		if (!activeForm) return;

		try {
			setLoading(true);
			await formServices.deleteForm(activeForm.id);
			setActiveForm(undefined);
			getData();
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={main.forms} handler={openCreateModal} hideBtnByRole>
			<div className='forms-page'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.description}</TableCell>
							<TableCell>{main.mail}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>{i.email}</TableCell>
								<TableCell className='table-last-item'>
									<Link to={`forms/${i.id}`} className='table-icon'>
										<ArrowForwardIcon className='table-icon table-icon--black' />
									</Link>
									{user?.role === 'SU' && (
										<>
											<EditIcon
												className='table-icon'
												onClick={() => openUpdateModal(i)}
											/>
											<DeleteForeverIcon
												color='secondary'
												onClick={() => openDeleteModal(i)}
												className='table-icon'
											/>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<Confirmation
				ref={ref}
				text={requests.removeFormQuestion}
				submit={deleteForm}
				title={requests.removeForm}
				load={loading}
				changeState={() => setActiveForm(undefined)}
			/>
			<CustomModal
				ref={createModalRef}
				submit={createForm}
				title={requests.createForm}
				text={main.create}
				load={loading}
				changeState={() => setActiveForm(undefined)}>
				<Form ref={createFormRef} view={view} fields={fields} />
			</CustomModal>
			<CustomModal
				ref={updateModalRef}
				submit={updateForm}
				title={requests.editForm}
				text={main.refresh}
				load={loading}
				changeState={() => setActiveForm(undefined)}>
				<Form
					ref={updateFormRef}
					view={view}
					fields={fields}
					initialData={activeForm}
				/>
			</CustomModal>
		</MainTemplate>
	);
};
