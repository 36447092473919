import { FileActionTypes } from 'api/services/file/types';

export interface IForm {
	[key: string]: IFormItem;
}

export type IFormItem =
	| IFormText
	| IFormTextArea
	| IFormSelect
	| IFormFile
	| IFormMultiSelect
	| IFormSwitch
	| IFormRepeater
	| IFormTitle
	| IFormHTML;

export enum FormType {
	TEXT = 'TEXT',
	SELECT = 'SELECT',
	TEXTAREA = 'TEXTAREA',
	FILE = 'FILE',
	MULTISELECT = 'MULTISELECT',
	SWITCH = 'SWITCH',
	REPEATER = 'REPEATER',
	TITLE = 'TITLE',
	FIELDS = 'FIELDS',
	HTML = 'HTML',
}

export enum FormItemTypes {
	TO_BOOLEAN = 'TO_BOOLEAN',
	TO_NUMBER = 'TO_NUMBER',
	TO_STRING = 'TO_STRING',
}

export interface IFormDefaultItem {
	label?: string;
	hidden?: boolean;
	disabled?: boolean;
	required?: boolean;
	hiddenRef?: IFormItemRef[][];
	disabledRef?: IFormItemRef[][];
	requiredRef?: IFormItemRef[][];
	unique?: true; // for submit
	onChangeRegexp?: RegExp;
	onSubmitRegexp?: RegExp;
	helperText?: string;
	errorMsg?: string;
	containerClassName?: string;
	extraKey?: string;
	equalRef?: string;
	order?: number;
}

export type IFromItemRefKey = 'index' | 'value';

export interface IFormItemRef {
	ref: string;
	operator: FormOperator;
	value?: any;
	key?: IFromItemRefKey;
}

export interface IFormRadioDefault {
	value: string;
}

export interface IFormTitle extends IFormDefaultItem {
	type: FormType.TITLE;
	value: string;
}

export interface IFormHTML extends IFormDefaultItem {
	type: FormType.HTML;
	value: string;
}

export interface IFormRepeater extends IFormDefaultItem {
	type: FormType.REPEATER;
	initialValue: IForm;
	initialValueRef?: string;
	value: IForm[];
	maxLength?: number;
	view: string[][];
	addText?: string;
	autoCreateRow?: boolean;
	isAccordeon?: boolean;
	rowClassName?: string;
	isFields?: boolean;
}

export interface IFormSwitch extends IFormDefaultItem {
	type: FormType.SWITCH;
	value: boolean;
}

export interface IFormText extends IFormDefaultItem {
	type: FormType.TEXT;
	value: string;
	isFieldName?: boolean;
	minLength?: number;
	inputType?: 'password';
}

export interface IFormTextArea extends IFormDefaultItem {
	type: FormType.TEXTAREA;
	value: string;
}

export interface IFormSelect extends IFormDefaultItem {
	type: FormType.SELECT;
	value: string;
	options?: IOption[];
	searchable?: IFormSearchDictApi;
	dict?: DictType;
}

export interface IFormMultiSelect extends IFormDefaultItem {
	type: FormType.MULTISELECT;
	value: (string | number)[];
	options?: IOption[];
	searchable?: IFormSearchDictApi;
}

export interface IFormSearchDictApi {
	valueKey: string;
	labelKey: string;
	url: string;
	query?: IAnyObject;
	withPaginate?: boolean;
	search?: boolean;
}

export interface IFormFile extends IFormDefaultItem {
	type: FormType.FILE;
	value: string;
	maxSize?: number;
	width?: number;
	height?: number;
	fileType?: FileActionTypes;
	fileId?: boolean;
}

export type IFormView = {
	title?: string;
	block: string[][];
}[];

export type IFormOnChange = (data: { name: string; value: any }[]) => void;

export interface IFormDefaultProps {
	name: string;
	onChange: IFormOnChange;
	required: boolean;
	disabled: boolean;
	hidden: boolean;
}

export type IFormAddHandler = (name: string) => void;

export type IFormDeleteHandler = (name: string, index: number) => void;

export enum FormOperator {
	FILLED = 'FILLED',
	EMPTY = 'EMPTY',
	EQUAL = 'EQUAL',
	NOT_EQUAL = 'NOT_EQUAL',
	TEST = 'TEST',
}

export enum DictType {
	ALL_FIELDS = 'ALL_FIELDS',
	CONDITION_OPERATORS = 'CONDITION_OPERATORS',
}
