import React from 'react';
// import { ReactComponent as Info } from 'assets/images/info.svg';
// import { ReactComponent as Error } from 'assets/images/error.svg';
import { ReactComponent as Warning } from 'assets/images/warning.svg';
import { ReactComponent as Check } from 'assets/images/check.svg';
import { translateNotificationStatuses } from 'helpers/translateNotificationStatuses';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import './Notification.sass';
import { DEFAULT_LANGUAGE } from 'constants/project';

const Notification: React.FC = () => {
	const { notificationTitle, notificationType } = useSelector(
		(state: AppState) => state.notificationReducer,
	);
	const { currentLang } = useSelector((state: AppState) => state.langReducer);
	const typeNotification = () => {
		const cls = 'notification__icon';
		switch (notificationType) {
			case 'error':
				return <Warning className={cls} />;
			case 'success':
				return <Check className={cls} />;
			default:
				return <Warning className={cls} />;
		}
	};
	return (
		<div className='notification notification--warning'>
			<div className='notification__row'>
				{typeNotification()}
				<div className='notification__description'>
					<div className='notification__title'>
						{translateNotificationStatuses(
							notificationType || 'error',
							currentLang?.code || DEFAULT_LANGUAGE,
						)}
					</div>
					<div className='notification__text'>
						{notificationTitle || 'This is an error alert — check it out!'}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Notification;
