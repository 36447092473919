import { AxiosPromise } from 'axios';

export enum IMenuItemType {
  PAGE = 'PAGE',
  POST = 'POST',
  TAXONOMY = 'TAXONOMY',
  POST_CATEGORY = 'POST_CATEGORY',
  DEFAULT = 'DEFAULT',
}

export interface IMenuItem {
  label: string;
  link?: string;
  menuId: number;
  parentId?: number;
  fileId?: number;
  newWindow: boolean;
  postId?: number;
  pageId?: number;
  taxonomyId?: number;
  postCategoryId?: number;
  type?: IMenuItemType;
  image?: string;
}

export interface IResMenuItem extends IMenuItem {
  createdAt: Date;
  updatedAt: Date;
  children?: IResMenuItem[];
  id: number;
}

export interface IMenuItemChangeOrder {
  id: number;
  parentId: number | null;
  order: number;
}

export interface IGetMenuItems {
  (): AxiosPromise<IResMenuItem[]>;
}

export interface IGetMenuItemById {
  (id: number | string): AxiosPromise<IResMenuItem>;
}

export interface ICreateMenuItem {
  (data: IMenuItem): AxiosPromise<IResMenuItem>;
}

export interface IUpdateMenuItem {
  (id: number | string, data: IMenuItem): AxiosPromise<IResMenuItem>;
}

export interface IDeleteMenuItem {
  (id: number | string): AxiosPromise<number>;
}

export interface IChangeOrder {
  (data: { items: IMenuItemChangeOrder[] }): AxiosPromise<void>;
}
