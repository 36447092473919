import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { ITaxonomy } from 'api/services/taxonomies/types';
import { taxonomyServices } from 'api/services';
import { IFile } from 'api/services/file/types';
import { SERVER_URL } from 'constants/project';
import handleNotification from 'helpers/handleNotification';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { TextField, Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';

import './UpdateTaxonomyPage.sass';
import { getTaxonomies } from 'store/ducks/taxonomies/actions';

export const UpdateTaxonomyPage: React.FC = () => {
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, taxonomies } = i18n;
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [file, setFile] = useState<IFile>();
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const { taxonomyId } = useParams<{ taxonomyId: string }>();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		getTaxonomy();
	}, [currentLang?.code]);

	const getTaxonomy = async () => {
		const res = await taxonomyServices
			.getTaxonomyById(taxonomyId)
			.then((res) => res.data);
		setName(res.name);
		setTitle(res.title);
		setFile(res.file);
	};

	const updateTaxonomy = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!name.trim() || !title.trim() || !file) {
			setHasError(true);
			handleNotification(main.fillFields);
			return;
		}
		setLoading(true);
		const data: ITaxonomy = {
			name,
			title,
			fileId: file.id,
		};

		try {
			await taxonomyServices.updateTaxonomy(taxonomyId, data);
			handleNotification(taxonomies.savedTaxonomy, 'success');
			dispatch(getTaxonomies());
			history.push('/taxonomies');
		} catch (e: any) {
			handleNotification(e?.response?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	const selectFile = (file: IFile) => {
		setFile(file);
	};

	return (
		<MainTemplate title={taxonomies.updateTaxonomy} hideBtn>
			<form onSubmit={updateTaxonomy}>
				<TextField
					variant='outlined'
					label={main.title}
					required
					className='form-field'
					value={title}
					autoFocus
					error={hasError && !title.trim()}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='form-field'
					value={name}
					required
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				{file && (
					<div className='page-form__file'>
						<img
							className='page-form__file-icon'
							src={`${SERVER_URL}/${file?.src}`}
							alt=''
						/>
						<div className='page-form__file-delete'>
							<DeleteForeverIcon
								color='secondary'
								onClick={() => setFile(undefined)}
							/>
						</div>
					</div>
				)}
				<Grid
					container
					justifyContent='space-between'
					className='page-form__actions'>
					<FileUploader
						selectFile={selectFile}
						text={file ? main.changeIcon : main.selectIcon}
						width={40}
						height={40}
					/>
					<Button
						className='action-button'
						size='large'
						color='primary'
						type='submit'
						variant='contained'
						disabled={loading}>
						{loading ? (
							<Loader height={10} type='ThreeDots' color='#fff' />
						) : (
							main.save
						)}
					</Button>
				</Grid>
			</form>
		</MainTemplate>
	);
};
