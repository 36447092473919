import React, { useEffect, useState, useRef } from 'react';
import {
	postCategoryServices,
	postService,
	taxonomyServices,
	templateServices,
} from 'api/services';
import { IResPostCategory } from 'api/services/postCategories/types';
import { useParams } from 'react-router-dom';
import {
	Grid,
	Paper,
	TextField,
	Typography,
	FormControl,
	Input,
	InputLabel,
	Select,
	Button,
	MenuItem,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { history } from 'routes/history';
import handleNotification from 'helpers/handleNotification';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { IPost } from 'api/services/posts/types';
import { IResTemplate } from 'api/services/templates/types';
import { transliterate } from 'helpers/transliterate';
import getImageByImageSize from 'helpers/getImageByImageSize';
import { IFile } from 'api/services/file/types';
import Form from 'shared/Form/Form';
import { IResTaxonomy } from 'api/services/taxonomies/types';
import { CLIENT_URL } from 'constants/project';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { seoFields, seoView } from 'constants/seo';
import { UserRoles } from 'api/services/user/types';
import './CreatePostPage.sass';

export const CreatePostPage: React.FC = () => {
	const { taxonomyId, categoryId } =
		useParams<{ taxonomyId: string; categoryId: string }>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const [title, setTitle] = useState('');
	const [shortDescription, setShortDescription] = useState('');
	const [file, setFile] = useState<IFile>();
	const [slug, setSlug] = useState('');
	const [currentCategoryId, setCurrentCategoryId] = useState<
		number | undefined
	>(categoryId ? Number(categoryId) : undefined);
	const [categories, setCategories] = useState<IResPostCategory[]>();
	const [taxonomyName, setTaxonomyName] = useState('');
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [categoryName, setCategoryName] = useState('');
	const [templateId, setTemplateId] = useState<number | string>('');
	const [templates, setTemplates] = useState<IResTemplate[]>();
	const [activeTemplate, setActiveTemplate] = useState<IResTemplate>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, posts } = i18n;
	const formRef = useRef<any>();
	const seoRef = useRef<any>();

	useEffect(() => {
		getPostCategories();
		getTemplates();
		getTaxonomy();
	}, [currentLang?.code]);

	useEffect(() => {
		const template = templates?.find((i) => i.id === templateId);
		if (template) {
			setActiveTemplate(template);
		}
	}, [templateId, templates?.length]);

	const getPostCategories = async () => {
		const res = await postCategoryServices
			.getPostCategories({ taxonomyId })
			.then((res) => res.data);
		setCategories(res);
		if (!res.length) {
			return handleNotification('Создайте категорию поста');
		}
		setCurrentCategoryId(res.find((i) => i.id === Number(categoryId))?.id);
	};

	const getTaxonomy = async () => {
		const res = await taxonomyServices.getTaxonomies().then((res) => res.data);
		res?.forEach((i: IResTaxonomy) => {
			if (i.id === Number(taxonomyId)) {
				setTaxonomyName(`/${i.name}`);
			}
		});
	};

	const getTemplates = async () => {
		const query = {
			taxonomyId,
		};
		const res = await templateServices
			.getTemplates(query)
			.then((res) => res.data);
		setTemplates(res);
		if (!res.length) {
			return handleNotification(
				'Необходимо создать шаблон для данной таксономии',
			);
		}
		setTemplateId(res[0].id);
	};

	const createPost = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!currentCategoryId) {
			handleNotification(posts.mustSelectCategory);
			setHasError(true);
			return;
		}

		if (!title.trim() || !templateId) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}

		const formRes = formRef.current?.submit();
		if (
			formRes &&
			(!formRes || (formRes?.hasErrors && user?.role !== UserRoles.SU))
		) {
			handleNotification(main.fillFields);
			return;
		}
		const seoRes = seoRef.current?.submit();

		const requestData: IPost = {
			title,
			categoryId: currentCategoryId,
			fileId: file?.id,
			slug,
			templateId: templateId as number,
			data: formRes?.res ?? {},
			seoDescription: seoRes?.description,
			seoTitle: seoRes.res.title,
			seoOgImage: seoRes.res.ogImage,
			seoKeywords: seoRes.res.keywords,
			shortDescription,
		};

		setLoading(true);

		try {
			const res = await postService
				.createPost(requestData)
				.then((res) => res.data);
			history.replace(`/taxonomies/${taxonomyId}/posts/${res.id}`);
			handleNotification(main.saveSuccess, 'success');
		} finally {
			setLoading(false);
			setHasError(false);
		}
	};

	const setCategory = (id: number) => {
		setCurrentCategoryId(id);
		const found = categories?.find((i) => i.id === id);
		if (found) {
			setCategoryName(`/${found.name}/`);
		}
	};

	const updateTitle = (value: string) => {
		setTitle(value);
		setSlug(transliterate(value));
	};

	return (
		<MainTemplate title={posts.createPost} hideBtn>
			<Grid container alignItems='flex-start'>
				<Grid item xs={8} className='create-post-page__main'>
					<Grid item xs={12} className='create-post-page__container'>
						<FormControl className='create-post-page__main-title'>
							<Input
								className='create-post-page__main-title-input'
								value={title}
								autoFocus
								error={!title.trim() && hasError}
								placeholder={main.name}
								onChange={(e) => updateTitle(e.target.value)}
							/>
						</FormControl>
						{activeTemplate && (
							<Form
								ref={formRef}
								fields={activeTemplate?.properties}
								view={activeTemplate?.view}
							/>
						)}
					</Grid>
					<Grid item xs={12} className='create-post-page__seo'>
						<Form ref={seoRef} view={seoView} fields={seoFields} />
					</Grid>
				</Grid>
				<Grid
					item
					xs={4}
					container
					spacing={2}
					className='create-post-page__aside'>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label={main.link}
							className='form-field'
							value={slug}
							onChange={(e) => setSlug(transliterate(e.target.value as string))}
						/>
					</Grid>
					<Grid item xs={12}>
						<a
							href={`${CLIENT_URL}${taxonomyName ? taxonomyName : ''}${
								categoryName ? '/' + categoryName : ''
							}${categoryName ? '/' + slug : ''}`}
							className='update-page__aside-link'
							target='_blank'
							rel='noreferrer'>
							{`${CLIENT_URL}${taxonomyName ? taxonomyName : ''}${
								categoryName ? '/' + categoryName : ''
							}${categoryName ? '/' + slug : ''}`}
						</a>
					</Grid>
					<Grid item xs={12}>
						<FormControl
							variant='outlined'
							fullWidth
							className='form-field'
							error={!categoryId && hasError}>
							<InputLabel id='categories'>{main.categories}</InputLabel>
							<Select
								label={main.categories}
								value={currentCategoryId}
								onChange={(e) => setCategory(e.target.value as number)}>
								{categories?.map((i) => {
									return (
										<MenuItem key={i.id} value={i.id}>
											{i.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl
							variant='outlined'
							fullWidth
							className='form-field'
							error={!templateId && hasError}>
							<InputLabel id='categories'>{main.templates}</InputLabel>
							<Select
								label={main.templates}
								value={templateId}
								onChange={(e) => setTemplateId(e.target.value as number)}>
								{templates?.map((i) => {
									return (
										<MenuItem key={i.id} value={i.id}>
											{i.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					{/*///////////////////////////////////////////Если нужны разные размеры постов*/}
					{/* <PostByImageSize file={file} setFile={setFile} /> */}
					<Grid item xs={12}>
						<Paper variant='outlined'>
							<Grid container spacing={3} alignItems='stretch'>
								<Grid item xs={7} className='post-page__block'>
									<Typography
										variant='h4'
										style={{ marginBottom: '20px' }}
										className='post-page__block-title'>
										{main.media}
									</Typography>
									<FileUploader
										text='Выбрать файл'
										selectFile={setFile}
										width={1080}
										height={1080}
									/>
								</Grid>
								<Grid
									className='post-page__media'
									container
									item
									alignItems='center'
									xs={5}>
									{file ? (
										<img
											className='post-page__media-item'
											src={getImageByImageSize({
												file,
												imageSize: '200x200',
											})}
											alt=''
										/>
									) : (
										<Typography className='media-item-title' variant='h6'>
											{main.notSelectedImage}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} justifyContent='flex-end' container>
						<Button
							className='action-button'
							size='large'
							color='primary'
							variant='contained'
							onClick={createPost}
							disabled={loading}>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								posts.createPost
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
