import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { formRequestServices } from 'api/services';
import { IResFormRequest } from 'api/services/form-request/types';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Confirmation from 'shared/Confirmation/Confirmation';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import handleNotification from 'helpers/handleNotification';

export const FormPage: React.FC = () => {
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, requests } = i18n;
	const { formId } = useParams<{ formId: string }>();
	const [data, setData] = useState<IResFormRequest[]>();
	const [page, setPage] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [take, setTake] = useState(20);
	const [id, setId] = useState<number>();
	const [loading, setLoading] = useState(false);
	const ref = useRef<any>();

	useEffect(() => {
		getData();
	}, [currentLang?.code]);

	const getData = async () => {
		const params = {
			take,
			skip: page * take,
			formId: Number(formId),
		};
		const res = await formRequestServices
			.getFormRequests(params)
			.then((res) => res.data);
		setTotalCount(res.count);
		setData(res.data);
	};

	const openDeleteModal = (id: number) => {
		setId(id);
		ref.current.onShow();
	};

	const deleteFormRequest = async () => {
		if (!id) return;
		try {
			setLoading(true);
			await formRequestServices.deleteFormRequest(id);
			ref.current.onHide();
			handleNotification(requests.removedRequest, 'success');
			getData();
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={requests.requests} hideBtn>
			<div className='form-page'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.status}</TableCell>
							<TableCell>{main.phone}</TableCell>
							<TableCell>{main.createdDate}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.viewed ? main.viewed : main.new}</TableCell>
								<TableCell>{i.data.phone}</TableCell>
								<TableCell>
									{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
								</TableCell>
								<TableCell className='table-last-item'>
									<Link
										to={`/forms/${formId}/requests/${i.id}`}
										className='table-icon table-icon--black'>
										<ArrowForwardIcon />
									</Link>
									<DeleteForeverIcon
										color='secondary'
										onClick={() => openDeleteModal(i.id)}
										className='table-icon'
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={main.quantityPerPage}
					count={totalCount}
					page={page}
					component='div'
					onPageChange={(e, newPage) => setPage(newPage)}
					rowsPerPageOptions={[20, 30, 50]}
					rowsPerPage={take}
					onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
				/>
			</div>
			<Confirmation
				ref={ref}
				text={requests.removeRequestQuestion}
				submit={deleteFormRequest}
				title={requests.removeRequest}
				load={loading}
			/>
		</MainTemplate>
	);
};
