import React, { useState, useEffect } from 'react';
import { postCategoryServices } from 'api/services';
import {
	Grid,
	MenuItem,
	Paper,
	Select,
	FormControl,
	InputLabel,
	TextField,
	Typography,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import handleNotification from 'helpers/handleNotification';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { IPostCategory } from 'api/services/postCategories/types';
import { useHistory, useParams } from 'react-router-dom';
import { templateServices } from 'api/services';
import { IResTemplate } from 'api/services/templates/types';
import getImageByImageSize from 'helpers/getImageByImageSize';
import { IFile } from 'api/services/file/types';
import { transliterate } from 'helpers/transliterate';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import './CreatePostCategoryPage.sass';

export const CreatePostCategoryPage: React.FC = () => {
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [file, setFile] = useState<IFile>();
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [templateId, setTemplateId] = useState<number>();
	const [templates, setTemplates] = useState<IResTemplate[]>([]);
	const { taxonomyId } = useParams<{ taxonomyId: string }>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, posts } = i18n;
	const history = useHistory();

	useEffect(() => {
		getTemplates();
	}, [currentLang?.code]);

	const getTemplates = async () => {
		const res = await templateServices
			.getTemplates({ taxonomyId })
			.then((res) => res.data);
		setTemplates(res);
	};

	const createPost = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!name.trim() || !title.trim() || !templateId) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}
		setLoading(true);

		const requestData: IPostCategory = {
			name,
			description,
			title,
			fileId: file?.id || undefined,
			taxonomyId: Number(taxonomyId),
			templateId,
		};

		try {
			await postCategoryServices.createPostCategory(requestData);
			history.push(`/taxonomies/${taxonomyId}/categories`);
			handleNotification(posts.savedCategory, 'success');
		} catch {
			setLoading(false);
			handleNotification(main.tryAgain);
		}
	};

	const generationName = (value: string) => {
		setTitle(value);
		setName(transliterate(value));
	};

	return (
		<MainTemplate title={posts.createCategory} hideBtn>
			<Grid container alignItems='flex-start'>
				<Grid item md={8} className='create-post-category-page__main'>
					<TextField
						autoFocus
						variant='outlined'
						label={main.title}
						className='form-field'
						value={title}
						onChange={(e) => generationName(e.target.value as string)}
						error={hasError && !title.trim()}
					/>
					<TextField
						variant='outlined'
						label={main.description}
						className='form-field'
						value={description}
						multiline
						rows={3}
						onChange={(e) => setDescription(e.target.value as string)}
					/>
					<FormControl
						variant='outlined'
						error={!templateId && hasError}
						className='form-field'>
						<InputLabel id='categories'>{main.defaultTemplate}</InputLabel>
						<Select
							label={main.defaultTemplate}
							value={templateId}
							onChange={(e) => setTemplateId(Number(e.target.value as string))}>
							{templates?.map((i) => {
								return (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					md={4}
					container
					className='create-post-category-page__aside'
					spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label={main.name}
							className='form-field'
							value={name}
							onChange={(e) => setName(transliterate(e.target.value as string))}
							error={hasError && !name.trim()}
						/>
					</Grid>
					<Grid item xs={12}>
						<Paper variant='outlined'>
							<Grid container spacing={3} alignItems='stretch'>
								<Grid item xs={7} className='post-page__block'>
									<Typography
										variant='h4'
										style={{ marginBottom: '20px' }}
										className='post-page__block-title'>
										{main.media}
									</Typography>
									<FileUploader text={main.selectFile} selectFile={setFile} />
								</Grid>
								<Grid
									className='post-page__media'
									container
									item
									alignItems='center'
									xs={5}>
									{file ? (
										<img
											className='post-page__media-item'
											src={getImageByImageSize({
												file,
												imageSize: '200x200',
											})}
											alt=''
										/>
									) : (
										<Typography className='media-item-title' variant='h6'>
											{main.notSelectedImage}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} justifyContent='flex-end' container>
						<Button
							className='action-button'
							size='large'
							color='primary'
							variant='contained'
							onClick={createPost}
							disabled={loading}>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								posts.createCategory
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
