import { DEFAULT_LANGUAGE } from 'constants/project';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const fields: IForm = {
	value: {
		type: FormType.TEXT,
		label: data[DEFAULT_LANGUAGE]?.main.value,
		extraKey: 'value',
		value: '',
		required: true,
	},
	label: {
		type: FormType.TEXT,
		label: data[DEFAULT_LANGUAGE]?.main.text,
		extraKey: 'label',
		value: '',
		required: true,
	},
};

export const view: IFormView = [
	{
		block: [['label'], ['value']],
	},
];
