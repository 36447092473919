import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { dictsServices } from 'api/services';
import { IResDict } from 'api/services/dicts/types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomModal from 'shared/CustomModal/CustomModal';
import {
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	Table,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Form from 'shared/Form/Form';
import { fields, view } from './formData';
import { UserRoles } from 'api/services/user/types';
import './DictsPage.sass';

export const DictsPage: React.FC = () => {
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, directories } = i18n;
	const [dicts, setDicts] = useState<IResDict[]>();
	const [loading, setLoading] = useState(false);
	const createModalRef = useRef<any>();
	const updateModalRef = useRef<any>();
	const [activeDict, setActiveDict] = useState<IResDict>();
	const ref = useRef<any>();
	const formUpdateRef = useRef<any>();
	const formCreateRef = useRef<any>();

	useEffect(() => {
		getDicts();
	}, [currentLang?.code]);

	const getDicts = async () => {
		const res = await dictsServices.getDicts().then((res) => res.data);
		setDicts(res);
	};

	const createDict = async () => {
		const formRes = formCreateRef.current?.submit();
		if (!formRes || formRes.hasErrors) return;

		const requestData = {
			name: formRes.res.name,
			title: formRes.res.title,
		};

		try {
			setLoading(true);
			await dictsServices.createDict(requestData);
			createModalRef.current.onHide();
			getDicts();
		} finally {
			setLoading(false);
		}
	};

	const updateDict = async () => {
		if (!activeDict?.id) return;

		const formRes = formUpdateRef.current?.submit();
		if (formRes && (!formRes || formRes.hasErrors)) return;

		const requestData = {
			name: formRes.res.name,
			title: formRes.res.title,
		};

		try {
			setLoading(true);
			await dictsServices.updateDict(activeDict.id, requestData);
			updateModalRef.current.onHide();
			getDicts();
			setActiveDict(undefined);
		} finally {
			setLoading(false);
		}
	};

	const openUpdateModal = (data: IResDict) => {
		setActiveDict(data);
		updateModalRef.current.onShow();
	};

	const openCreateModal = () => {
		createModalRef.current.onShow();
	};

	const openDeleteModal = (data: IResDict) => {
		setActiveDict(data);
		ref.current.onShow();
	};

	const deleteDict = async () => {
		if (!activeDict?.id) return;
		try {
			setLoading(true);
			await dictsServices.deleteDict(activeDict.id);
			getDicts();
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate
			title={main.directories}
			handler={() => openCreateModal()}
			hideBtnByRole>
			<div className='dicts-page'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.title}</TableCell>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.createdDate}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{dicts?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>
									{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
								</TableCell>
								<TableCell className='table-last-item'>
									<Link
										to={`dicts/${i.id}/options`}
										className='dicts-page__btn'>
										<ArrowForwardIcon
											color='action'
											className='table-icon table-icon--black'
										/>
									</Link>
									{user?.role === UserRoles.SU && (
										<>
											<EditIcon
												className='table-icon dicts-page__btn'
												onClick={() => openUpdateModal(i)}
											/>
											<DeleteForeverIcon
												color='secondary'
												onClick={() => openDeleteModal(i)}
												className='table-icon dicts-page__btn'
											/>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<Confirmation
				ref={ref}
				text={directories.removeDirectoryQuestion}
				submit={deleteDict}
				title={directories.removeDirectory}
				load={loading}
				changeState={() => setActiveDict(undefined)}
			/>
			<CustomModal
				ref={createModalRef}
				submit={createDict}
				title={directories.createDirectory}
				text={main.create}
				load={loading}
				changeState={() => setActiveDict(undefined)}>
				<Form view={view} fields={fields} ref={formCreateRef} />
			</CustomModal>
			<CustomModal
				ref={updateModalRef}
				submit={updateDict}
				title={directories.editDirectory}
				text={main.update}
				load={loading}
				changeState={() => setActiveDict(undefined)}>
				<Form
					view={view}
					fields={fields}
					initialData={activeDict}
					ref={formUpdateRef}
				/>
			</CustomModal>
		</MainTemplate>
	);
};
