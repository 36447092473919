import { IResTaxonomy } from 'api/services/taxonomies/types';

export enum TaxonomyType {
	GET_TAXONOMIES = '@/taxonomy/GET_TAXONOMIES',
}

type IGetTaxonomy = {
	type: TaxonomyType.GET_TAXONOMIES;
};

export interface ITaxonomyInitState {
	taxonomies?: IResTaxonomy[];
}

export type TaxonomyActionTypes = IGetTaxonomy;
