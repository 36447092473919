import { createStore, applyMiddleware, AnyAction } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './rootReducer';

export type AppState = ReturnType<typeof rootReducer>;

const store = createStore(
	rootReducer,
	applyMiddleware(thunk as ThunkMiddleware<AppState, AnyAction>),
);

export default store;
