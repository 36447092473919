import instance from 'api/instance';
import {
  ICreateMenuItem,
  IUpdateMenuItem,
  IDeleteMenuItem,
  IGetMenuItems,
  IGetMenuItemById,
  IChangeOrder,
} from './types';

export const getMenuItems: IGetMenuItems = () => instance.get('menu-items');

export const getMenuItemById: IGetMenuItemById = (id) =>
  instance.get(`menu-items/${id}`);

export const createMenuItem: ICreateMenuItem = (data) =>
  instance.post('menu-items', data);

export const updateMenuItem: IUpdateMenuItem = (id, data) =>
  instance.put(`menu-items/${id}`, data);

export const deleteMenuItem: IDeleteMenuItem = (id) =>
  instance.delete(`menu-items/${id}`);

export const changeMenuItemOrder: IChangeOrder = (items) =>
  instance.patch('menu-items/order', items);
