import React, { useEffect, useState } from 'react';
import { formRequestServices } from 'api/services';
import { IFormRequest } from 'api/services/form-request/types';
import { useParams } from 'react-router-dom';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';

import './FormItemPage.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
export const FormItemPage: React.FC = () => {
	const { formId, reqId } = useParams<{ formId: string; reqId: string }>();
	const [request, setRequest] = useState<IFormRequest>();
	const history = useHistory();
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);

	useEffect(() => {
		getRequest();
	}, []);
	const getRequest = async () => {
		const res = await formRequestServices
			.getFormRequestById(Number(reqId), { formId: Number(formId) })
			.then((res) => res.data.data);
		setRequest(res);
	};
	return (
		<MainTemplate title='Request' hideBtn>
			<div className='form-item-page'>
				<ul className='form-item-page__about'>
					<li className='form-item-page__about-item'>{main.name}:</li>
					<li className='form-item-page__about-item'>{request?.name}</li>
					<li className='form-item-page__about-item'>Email:</li>
					<li className='form-item-page__about-item'>{request?.email}</li>
					<li className='form-item-page__about-item'>{main.phone}:</li>
					<li className='form-item-page__about-item'>{request?.phone}</li>
					<li className='form-item-page__about-item'>{main.message}:</li>
					<li className='form-item-page__about-item'>{request?.message}</li>
				</ul>
				<div
					className='form-item-page__exit'
					onClick={() => history.push(`/forms/${Number(formId)}`)}>
					<Button color='primary' variant='contained'>
						{main.back}
					</Button>
				</div>
			</div>
		</MainTemplate>
	);
};
