import { DEFAULT_LANGUAGE } from 'constants/project';
import { filteredUserRoles } from 'constants/userRoles';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const createFields: IForm = {
	name: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.main.name,
		extraKey: 'name',
	},
	email: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: 'Email',
		extraKey: 'email',
		onSubmitRegexp:
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	password: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.auth.password,
		extraKey: 'password',
		minLength: 6,
		inputType: 'password',
		equalRef: 'rePassword',
	},
	rePassword: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.auth.repeatPassword,
		minLength: 6,
		equalRef: 'password',
		inputType: 'password',
	},
	isActive: {
		type: FormType.SWITCH,
		value: true,
		label: data[DEFAULT_LANGUAGE]?.user.userStatus,
		extraKey: 'isActive',
	},
	role: {
		type: FormType.SELECT,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.user.role,
		extraKey: 'role',
		options: filteredUserRoles(),
		required: true,
	},
};

export const updateFields: IForm = {
	name: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.main.name,
		extraKey: 'name',
	},
	email: {
		type: FormType.TEXT,
		required: true,
		value: '',
		label: 'Email',
		extraKey: 'email',
		onSubmitRegexp:
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	password: {
		type: FormType.TEXT,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.auth.password,
		extraKey: 'password',
		minLength: 6,
		inputType: 'password',
		equalRef: 'rePassword',
	},
	rePassword: {
		type: FormType.TEXT,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.auth.repeatPassword,
		minLength: 6,
		equalRef: 'password',
		inputType: 'password',
	},
	isActive: {
		type: FormType.SWITCH,
		value: true,
		label: data[DEFAULT_LANGUAGE]?.user.userStatus,
		extraKey: 'isActive',
	},
	role: {
		type: FormType.SELECT,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.user.role,
		extraKey: 'role',
		options: filteredUserRoles(),
		required: true,
	},
};

export const view: IFormView = [
	{
		block: [
			['name', 'email'],
			['password', 'rePassword'],
			['role'],
			['isActive'],
		],
	},
];
