import instance from 'api/instance';
import {
	ICreateForm,
	IDeleteForm,
	IGetFormById,
	IGetForms,
	IUpdateForm,
} from './types';

export const getForms: IGetForms = () => instance.get('forms');

export const getFormById: IGetFormById = (id) => instance.get(`forms/${id}`);

export const creeateForm: ICreateForm = (data) => instance.post('forms', data);

export const updateForm: IUpdateForm = (id, data) =>
	instance.put(`forms/${id}`, data);

export const deleteForm: IDeleteForm = (id) => instance.delete(`forms/${id}`);
