import { FileTypes } from 'api/services/file/types';
import { IMenuItemType } from 'api/services/menuItems/types';
import { DEFAULT_LANGUAGE } from 'constants/project';
import { FormOperator, FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const fields: IForm = {
	label: {
		type: FormType.TEXT,
		label: data[DEFAULT_LANGUAGE]?.main.text,
		extraKey: 'label',
		required: true,
		value: '',
	},
	link: {
		type: FormType.TEXT,
		label: data[DEFAULT_LANGUAGE]?.main.link,
		extraKey: 'link',
		required: true,
		value: '',
		hiddenRef: [
			[
				{
					operator: FormOperator.NOT_EQUAL,
					ref: 'type',
					value: IMenuItemType.DEFAULT,
				},
			],
		],
	},
	type: {
		type: FormType.SELECT,
		label: data[DEFAULT_LANGUAGE]?.main.linkType,
		extraKey: 'type',
		required: true,
		value: IMenuItemType.DEFAULT,
		options: [
			{
				value: IMenuItemType.DEFAULT,
				label: data[DEFAULT_LANGUAGE]?.main.externalRef,
			},
			{
				value: IMenuItemType.PAGE,
				label: data[DEFAULT_LANGUAGE]?.main.linkToPage,
			},
			{
				value: IMenuItemType.POST,
				label: data[DEFAULT_LANGUAGE]?.main.linkToPost,
			},
			{
				value: IMenuItemType.TAXONOMY,
				label: data[DEFAULT_LANGUAGE]?.main.linkToTaxonomy,
			},
			{
				value: IMenuItemType.POST_CATEGORY,
				label: data[DEFAULT_LANGUAGE]?.main.linkToCategory,
			},
		],
	},
	post: {
		type: FormType.SELECT,
		label: data[DEFAULT_LANGUAGE]?.main.post,
		extraKey: 'postId',
		required: true,
		value: '',
		hiddenRef: [
			[
				{
					ref: 'type',
					operator: FormOperator.NOT_EQUAL,
					value: IMenuItemType.POST,
				},
			],
		],
		searchable: {
			labelKey: 'title',
			valueKey: 'id',
			url: '/posts',
			query: {
				skip: 0,
				take: 20,
			},
			withPaginate: true,
		},
	},
	page: {
		type: FormType.SELECT,
		label: data[DEFAULT_LANGUAGE]?.main.page,
		extraKey: 'pageId',
		required: true,
		value: '',
		hiddenRef: [
			[
				{
					ref: 'type',
					operator: FormOperator.NOT_EQUAL,
					value: IMenuItemType.PAGE,
				},
			],
		],
		searchable: {
			labelKey: 'title',
			valueKey: 'id',
			url: '/pages',
		},
	},
	taxonomy: {
		type: FormType.SELECT,
		label: data[DEFAULT_LANGUAGE]?.main.taxonomy,
		extraKey: 'taxonomyId',
		required: true,
		value: '',
		hiddenRef: [
			[
				{
					ref: 'type',
					operator: FormOperator.NOT_EQUAL,
					value: IMenuItemType.TAXONOMY,
				},
			],
		],
		searchable: {
			labelKey: 'title',
			valueKey: 'id',
			url: '/taxonomies',
		},
	},
	postCategory: {
		type: FormType.SELECT,
		label: data[DEFAULT_LANGUAGE]?.main.postCategory,
		extraKey: 'postCategoryId',
		required: true,
		value: '',
		hiddenRef: [
			[
				{
					ref: 'type',
					operator: FormOperator.NOT_EQUAL,
					value: IMenuItemType.POST_CATEGORY,
				},
			],
		],
		searchable: {
			labelKey: 'title',
			valueKey: 'id',
			url: '/post-categories',
		},
	},
	newWindow: {
		type: FormType.SWITCH,
		label: data[DEFAULT_LANGUAGE]?.main.newWindowQuestion,
		extraKey: 'newWindow',
		value: false,
	},
	image: {
		type: FormType.FILE,
		label: data[DEFAULT_LANGUAGE]?.main.image,
		extraKey: 'image',
		fileType: FileTypes.IMAGE,
		width: 315,
		height: 175,
		value: '',
	},
};

export const view: IFormView = [
	{
		block: [
			['label'],
			['type'],
			['post'],
			['page'],
			['taxonomy'],
			['postCategory'],
			['link'],
			['newWindow'],
			['image'],
		],
	},
];
