import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Button, Typography } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import cn from 'classnames';
import {
	IForm,
	IFormAddHandler,
	IFormDefaultProps,
	IFormDeleteHandler,
	IFormRepeater,
} from 'shared/Form/types';
import './FormRepeater.sass';
import { getRowVisibility } from 'shared/Form/helpers/getFieldStates';
import { areEqual } from 'helpers/areEqual';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
	data: IFormRepeater;
	defaultProps: IFormDefaultProps;
	addRow: IFormAddHandler;
	deleteRow: IFormDeleteHandler;
	renderItem: (name: string) => JSX.Element | null;
	isFields?: boolean;
	fields: IForm;
	handleSwap: (
		e: React.MouseEvent<SVGSVGElement>,
		name: string,
		index: number,
		down?: boolean,
	) => void;
}

const FormRepeaterComp: React.FC<Props> = ({
	data,
	defaultProps,
	addRow,
	renderItem,
	deleteRow,
	handleSwap,
	fields,
}) => {
	const {
		value,
		label,
		maxLength,
		view,
		addText,
		autoCreateRow,
		isAccordeon,
		containerClassName,
		isFields,
	} = data;
	const { name } = defaultProps;
	const [visibilityIndexes, setVisibilityIndexes] = useState<number[]>([]);
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);

	useEffect(() => {
		if (autoCreateRow && value.length === 0) {
			handleAddRow();
		}
	}, []);

	const handleVisibility = (index: number) => {
		setVisibilityIndexes((prev) => {
			const clone = _.clone(prev);
			const foundIndex = clone.findIndex((i) => i === index);
			if (foundIndex >= 0) {
				clone.splice(foundIndex, 1);
			} else {
				clone.push(index);
			}
			return clone;
		});
	};

	const handleAddRow = () => {
		addRow(name);
		handleVisibility(value.length === 0 ? 0 : value.length - 1);
	};

	const handleDeleteRow = (index: number) => {
		deleteRow(name, index);
		setVisibilityIndexes([]);
	};

	return (
		<div
			className={cn('form-repeater', {
				[containerClassName || '']: containerClassName,
				'form-repeater--without-padding': !label,
			})}>
			{label && (
				<Grid container className='form-repeater__title'>
					<Typography variant='h5' className='form-repeater__title-text'>
						{label}
					</Typography>
				</Grid>
			)}
			{value.map((i, index) => (
				<div
					key={index}
					className={cn('form-repeater__container', {
						'form-repeater__container--border': name === 'fields',
					})}>
					<div className='form-repeater__switch'>
						{index !== 0 && (
							<ArrowDropUpIcon
								className={cn('form-repeater__switch-icon', {
									'form-repeater__switch-icon--disabled': index === 0,
								})}
								onClick={(e) => handleSwap(e, name, index)}
							/>
						)}
						{index !== value.length - 1 && (
							<ArrowDropDownIcon
								className={cn('form-repeater__switch-icon', {
									'form-repeater__switch-icon--disabled':
										index === value.length - 1,
								})}
								onClick={(e) => handleSwap(e, name, index, true)}
							/>
						)}
					</div>
					<div className='form-repeater__field-container'>
						{((visibilityIndexes.some((i) => i === index) && isAccordeon) ||
							!isAccordeon) &&
							(isFields ? (fields?.fields as IFormRepeater)?.view : view).map(
								(ii, iindex) => {
									if (!getRowVisibility(value[index], ii, index)) return null;
									return (
										<div
											key={iindex}
											className={cn('form-repeater__row', {
												'form-repeater__row--some-child': ii.length > 1,
											})}>
											{ii.map((iii: string) => (
												<div className='form-field' key={iii}>
													{renderItem(`${name}.value.[${index}].${iii}`)}
												</div>
											))}
										</div>
									);
								},
							)}
					</div>
					<Button
						variant='contained'
						color='secondary'
						className={cn('form-repeater__delete', {
							'form-repeater__delete--right': name === 'condition',
						})}
						onClick={() => handleDeleteRow(index)}>
						<DeleteIcon />
					</Button>
				</div>
			))}
			{((maxLength && value.length < maxLength) || !maxLength) && (
				<Grid
					container
					justifyContent='flex-end'
					className='form-repeater__add'>
					<Button
						color='primary'
						className='button--black'
						onClick={handleAddRow}
						variant='contained'
						startIcon={<AddOutlinedIcon />}>
						{addText || main.addField}
					</Button>
				</Grid>
			)}
		</div>
	);
};

export const FormRepeater = React.memo(
	FormRepeaterComp,
	(prevProps, nextProps) => {
		return (
			areEqual(prevProps.data, nextProps.data) &&
			areEqual(prevProps.defaultProps, nextProps.defaultProps)
		);
	},
);
