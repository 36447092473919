import { IResTaxonomy } from 'api/services/taxonomies/types';
import { Reducer } from 'redux';
import { ITaxonomyInitState, TaxonomyType } from './types';

const initState = {
	taxonomies: [],
};

const reducer: Reducer<ITaxonomyInitState> = (
	state = initState,
	{ type, payload },
) => {
	switch (type) {
		case TaxonomyType.GET_TAXONOMIES:
			return {
				...state,
				taxonomies: payload.taxonomies,
			};
		default:
			return state;
	}
};

export default reducer;
