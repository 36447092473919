import App from 'App/App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { history } from 'routes/history';
import Routes from 'routes/Routes';
import store from 'store/store';
import 'assets/styles/main.sass';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Router history={history}>
				<App>
					<Routes />
				</App>
			</Router>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);
