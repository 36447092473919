import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import arrayToTree from 'array-to-tree';
import { dictOptionsServices } from 'api/services';
import { IResDictOption } from 'api/services/dict-option/types';
import handleNotification from 'helpers/handleNotification';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomDND from 'shared/CustomDND/CustomDND';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import './DictPage.sass';

export const DictPage: React.FC = () => {
	const { dictId } = useParams<{ dictId: string }>();
	const [loading, setLoading] = useState(false);
	const [treeDictOption, setTreeDictOption] = useState<IResDictOption[]>([]);
	const [dictOptionId, setDictOptionId] = useState<number>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, directories } = i18n;
	const ref = useRef<any>();

	useEffect(() => {
		getDictOptions();
	}, [currentLang?.code]);

	const generateTree = (res: IResDictOption[]) => {
		const catTree = arrayToTree(res, {
			parentProperty: 'parentId',
			customID: 'id',
			childrenProperty: 'children',
		});
		setTreeDictOption(catTree);
	};

	const getDictOptions = async () => {
		const params = {
			dictId: dictId,
		};
		const res = await dictOptionsServices
			.getDictOption(params)
			.then((res) => res.data);
		generateTree(res);
	};

	const clearState = () => {
		setDictOptionId(undefined);
	};

	const openNotification = (id: number) => {
		setDictOptionId(id);
		ref.current.onShow();
	};

	const deleteDictOption = async () => {
		if (!dictOptionId) return;
		try {
			setLoading(true);
			await dictOptionsServices.deleteDictOption(dictOptionId);
			getDictOptions();
			handleNotification(directories.removedOption, 'success');
		} finally {
			setLoading(false);
		}
	};

	const renderItems = ({ item, collapseIcon }: any) => (
		<ul className='dict-page__list nestable__list'>
			<li className='nestable__text'>
				{collapseIcon} {item.label}
			</li>
			<li className='nestable__text'>{item.value}</li>
			<li className='nestable__text'>
				{moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
			</li>
			<li className='table-last-item'>
				<Link
					className='dict-page__btn'
					to={`/dicts/${dictId}/options/${item.id}`}>
					<EditIcon className='table-icon table-icon--black' />
				</Link>
				<DeleteForeverIcon
					color='secondary'
					onClick={() => openNotification(item.id)}
					className='table-icon'
				/>
			</li>
		</ul>
	);

	return (
		<MainTemplate
			title={main.directoryOptions}
			url={`/dicts/${dictId}/options/create`}>
			<div className='dict-page'>
				<ul className='dict-page__list nestable__list'>
					<li className='nestable__text MuiTableCell-head'>
						{main.description}
					</li>
					<li className='nestable__text MuiTableCell-head'>{main.value}</li>
					<li className='nestable__text MuiTableCell-head'>
						{main.updatedDate}
					</li>
					<li className='nestable__text MuiTableCell-head table-last-item'>
						{main.action}
					</li>
				</ul>
				<CustomDND
					items={treeDictOption}
					renderItems={renderItems}
					submitDrag={dictOptionsServices.changeDictOptionOrder}
					refetchData={getDictOptions}
				/>
			</div>
			<Confirmation
				ref={ref}
				text={directories.removeOptionQuestion}
				submit={deleteDictOption}
				title={directories.removeOption}
				load={loading}
				changeState={() => clearState()}
			/>
		</MainTemplate>
	);
};
