import instance from 'api/instance';
import {
  ICreateTemplate,
  IDeleteTemplate,
  IGetTemplates,
  IGetTemplateById,
  IUpdateTemplate,
} from './types';
import qs from 'query-string';

export const getTemplates: IGetTemplates = (query = {}) =>
  instance.get(`/templates?${qs.stringify(query)}`);

export const createTemplate: ICreateTemplate = (data) =>
  instance.post('/templates', data);

export const getTemplateById: IGetTemplateById = (id) =>
  instance.get(`/templates/${id}`);

export const updateTemplate: IUpdateTemplate = (id, data) =>
  instance.put(`/templates/${id}`, data);

export const deleteTemplate: IDeleteTemplate = (id) =>
  instance.delete(`/templates/${id}`);
