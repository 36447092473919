import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
	ContentState,
	convertToRaw,
	EditorState,
	convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { fileServices } from 'api/services';
import { SERVER_URL } from 'constants/project';
import cn from 'classnames';

import './HtmlEditor.sass';

const customContentStateConverter = (contentState: any) => {
	const newBlockMap = contentState.getBlockMap().map((block: any) => {
		const entityKey = block.getEntityAt(0);
		if (entityKey !== null) {
			const entityBlock = contentState.getEntity(entityKey);
			const entityType = entityBlock.getType();
			switch (entityType) {
				case 'IMAGE': {
					const newBlock = block.merge({
						type: 'atomic',
						text: 'img',
					});
					return newBlock;
				}
				default:
					return block;
			}
		}
		return block;
	});
	const newContentState = contentState.set('blockMap', newBlockMap);
	return newContentState;
};

interface Props {
	onChange: (value: string) => void;
	value: string;
	label?: string;
	errorMsg?: string;
	disabled?: boolean;
	required?: boolean;
	helperText?: string;
	order?: number;
}

const HtmlEditor: React.FC<Props> = ({
	onChange,
	value,
	label,
	errorMsg,
	disabled,
	required,
	helperText,
	order,
}) => {
	const [editorState, setEditorState] = useState<EditorState>(
		EditorState.createEmpty(),
	);

	useEffect(() => {
		if (value) {
			setEditorState(
				EditorState.createWithContent(
					customContentStateConverter(
						ContentState.createFromBlockArray(
							convertFromHTML(value).contentBlocks,
							convertFromHTML(value).entityMap,
						),
					),
				),
			);
		}
	}, [order, !!value]);

	const uploadCallback = async (file: File) => {
		const formData = new FormData();
		formData.append('file', file);
		return fileServices.uploadFile(formData).then((res) => ({
			data: {
				link: `${SERVER_URL}/${res.data.src}`,
			},
		}));
	};

	return (
		<div className={cn('html-editor', { 'html-editor--error': errorMsg })}>
			<Editor
				textAlignment={helperText}
				editorState={editorState}
				placeholder={label}
				toolbarClassName='toolbarClassName'
				wrapperClassName='wrapperClassName'
				editorClassName='editorClassName'
				onEditorStateChange={(newState) => {
					setEditorState(newState);
					onChange(draftToHtml(convertToRaw(newState.getCurrentContent())));
				}}
				toolbar={{
					options: [
						'inline',
						'blockType',
						'list',
						'textAlign',
						'colorPicker',
						'link',
						'image',
						'history',
					],
					inline: { inDropdown: true },
					list: { inDropdown: true },
					textAlign: { inDropdown: true },
					link: { inDropdown: true },
					history: { inDropdown: true },
					image: {
						inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
						uploadEnabled: true,
						uploadCallback: uploadCallback,
						alignmentEnabled: false,
						previewImage: true,
						alt: { present: false, mandatory: false },
					},
				}}
			/>
			{!!errorMsg && (
				<span className='html-editor--error-text'>{errorMsg}</span>
			)}
		</div>
	);
};

export default HtmlEditor;
