import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import logo from 'assets/images/logo.png';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import Notification from 'shared/Notification/Notification';
import { useDispatch } from 'react-redux';
import * as userAuthActions from 'store/ducks/auth/actions';
import * as userProfileActions from 'store/ducks/user/actions';
import { AppState } from 'store/store';
import { authServices } from 'api/services';
import CustomModal from 'shared/CustomModal/CustomModal';
import Loader from 'react-loader-spinner';
import handleNotification from 'helpers/handleNotification';
import * as langActions from 'store/ducks/langs/actions';
import { DEFAULT_LANGUAGE } from 'constants/project';

import './Auth.sass';

export const Auth: React.FC = () => {
	const [password, setPassword] = useState<string>('');
	const [hasError, setHasError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');
	const [otp, setOtp] = useState<string>('');
	const [otpField, setOtpField] = useState<boolean>(false);
	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordField, setNewPasswordField] = useState<boolean>(false);
	const { main, auth } = useSelector(
		(state: AppState) => state.langReducer.i18n,
	);

	const { showNotification } = useSelector(
		(state: AppState) => state.notificationReducer,
	);
	const modal = useRef<any>();

	const dispatch = useDispatch();

	useEffect(() => {
		setHasError(false);
	}, [email, password]);

	const authorize = async (e: React.FormEvent) => {
		e.preventDefault();
		if ((!!password && password.length < 6) || !password || !email) {
			setHasError(true);
			handleNotification(main.fillFields);
			setTimeout(() => {
				setHasError(false);
			}, 2000);
			return;
		}
		setLoading(true);
		const userData = {
			email: email,
			password: password,
		};
		try {
			const data = await authServices
				.authUser(userData)
				.then((res) => res.data);
			dispatch(userProfileActions.updateProfile(data.user));
			dispatch(userAuthActions.signIn(data.accessToken));
			handleNotification(auth.authSuccess, 'success');
		} catch (e: any) {
			handleNotification(e.response ? e.response.data.message : main.tryAgain);

			setLoading(false);
		}
	};

	const resetModal = () => {
		setResetPasswordEmail('');
		setOtp('');
		setNewPassword('');
		setNewPasswordField(false);
		setOtpField(false);
	};

	const resetPassword = async () => {
		if (!resetPasswordEmail.trim()) {
			setHasError(true);
			handleNotification(main.fillFields);
			return;
		}
		setLoading(true);
		if (!otp.trim()) {
			try {
				await authServices.resetPassword({ email: resetPasswordEmail });
				setOtpField(true);
			} finally {
				setLoading(false);
			}
			return;
		}
		if (!newPassword.trim()) {
			try {
				await authServices.confirmOtp({ otp, email: resetPasswordEmail });
				setNewPasswordField(true);
			} finally {
				setLoading(false);
			}
			return;
		}
		try {
			await authServices.updatePassword({
				otp,
				password: newPassword,
				email: resetPasswordEmail,
			});
			modal.current.onHide();
			resetModal();
			handleNotification(auth.changedPassword, 'success');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{showNotification && <Notification />}
			<Grid
				justifyContent='center'
				alignItems='center'
				direction='column'
				container
				item
				xs={12}
				className='auth'>
				<img src={logo} alt='logo' className='auth__logo' />
				<form className='auth__form' onSubmit={authorize}>
					<TextField
						error={hasError}
						fullWidth
						variant='outlined'
						label='Email'
						className='auth__input'
						onChange={(e) => setEmail(e.target.value as string)}
					/>
					<TextField
						error={hasError}
						fullWidth
						variant='outlined'
						label={auth.password}
						type='password'
						className='auth__input'
						onChange={(e) => setPassword(e.target.value as string)}
					/>
					<div className='auth-reset-password'>
						<Typography
							variant='h5'
							onClick={() => modal.current.onShow()}
							className='auth-reset-password-title'>
							{auth.resetPassword}
						</Typography>
					</div>
					<div className='button-loader auth__loader'>
						<Button
							variant='contained'
							fullWidth
							className='btn auth__button button-height'
							disabled={loading}
							type='submit'>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								main.login
							)}
						</Button>
					</div>
				</form>
			</Grid>
			<CustomModal
				ref={modal}
				title={auth.resetPassword}
				text={auth.reset}
				changeState={resetModal}
				load={loading}
				submit={resetPassword}>
				<TextField
					value={resetPasswordEmail}
					className='custom-modal-item'
					variant='outlined'
					label={auth.enterEmail}
					disabled={
						!!resetPasswordEmail.trim() && (otpField || newPasswordField)
					}
					autoFocus
					error={hasError && !resetPasswordEmail.trim()}
					onChange={(e) => setResetPasswordEmail(e.target.value as string)}
				/>
				{otpField && (
					<TextField
						value={otp}
						className='custom-modal-item'
						variant='outlined'
						label={auth.enterCode}
						autoFocus
						disabled={!!resetPasswordEmail.trim() && newPasswordField}
						onChange={(e) => setOtp(e.target.value as string)}
					/>
				)}
				{newPasswordField && (
					<TextField
						value={newPassword}
						className='custom-modal-item'
						variant='outlined'
						label={auth.enterNewPassword}
						autoFocus
						onChange={(e) => setNewPassword(e.target.value as string)}
					/>
				)}
			</CustomModal>
		</>
	);
};
