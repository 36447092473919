import { IFile } from 'api/services/file/types';
import { SERVER_URL } from 'constants/project';

const getImageByImageSize = (data: {
	file?: IFile;
	imageSize?: string;
	src?: string;
}): string => {
	const { file, imageSize, src } = data;
	if (file?.fileImageSizes) {
		const fileByImageSize = file.fileImageSizes.find(
			(i) => i.imageSize.name === imageSize,
		);
		return `${SERVER_URL}/${fileByImageSize?.src || file.src}`;
	}
	return `${SERVER_URL}/${src}`;
};

export default getImageByImageSize;
