import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { IFormDefaultProps, IFormSelect } from 'shared/Form/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import instance from 'api/instance';
import './FormItemSelect.sass';
import { areEqual } from 'helpers/areEqual';

interface Props {
	data: IFormSelect;
	defaultProps: IFormDefaultProps;
}

const FormItemSelectComp: React.FC<Props> = ({ data, defaultProps }) => {
	const { value, label, options, searchable, helperText, errorMsg } = data;
	const { onChange, name, disabled, required } = defaultProps;
	const [searchValue, setSearchValue] = useState('');
	const [searchableResults, setSearchableResults] = useState<IOption[]>([]);
	const [searchMainValue, setSearchMainValue] = useState<IOption>();

	useEffect(() => {
		getOptions();
	}, []);

	useEffect(() => {
		getInitData();
	}, [searchableResults, value]);

	const getInitData = () => {
		if (value === searchMainValue?.value) return;
		const initResult =
			searchableResults.find((i: any) => i.value === value) ||
			searchableResults[0];
		setSearchMainValue(initResult);
	};

	useEffect(() => {
		if (searchMainValue) changeData();
	}, [searchMainValue?.value]);

	const changeData = () => {
		onChange([{ name, value: searchMainValue?.value }]);
	};

	const getOptions = async (keyword?: string) => {
		if (!searchable) return;
		if (keyword && keyword.length <= 2) return;
		const { labelKey, url, query = {}, valueKey, withPaginate } = searchable;
		let params: any;
		if (!keyword) {
			params = {
				...query,
				...(withPaginate
					? {
							skip: 0,
							take: 20,
					  }
					: {}),
			};
		} else {
			params = {
				search: keyword,
				...query,
				...(withPaginate
					? {
							skip: 0,
							take: 20,
					  }
					: {}),
			};
		}
		const res = await instance
			.get(`${url}?${qs.stringify(params)}`)
			.then((res) => (withPaginate ? res.data.data : res.data));
		setSearchableResults(
			res?.map((i: any) => ({
				value: i[valueKey],
				label: i[labelKey],
			})),
		);
	};

	const handleChange = (value: string): void => {
		onChange([{ name, value }]);
	};

	return !searchable ? (
		<FormControl
			variant='outlined'
			required={required}
			disabled={disabled}
			error={!!errorMsg}>
			<InputLabel variant='outlined'>{label}</InputLabel>
			<Select
				label={label}
				value={value}
				variant='outlined'
				onChange={(e) => handleChange((e?.target?.value ?? '') as string)}>
				{options?.map((i) => (
					<MenuItem
						key={i.label}
						className='select__options-item'
						value={i.value}>
						{i.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMsg || helperText}</FormHelperText>
		</FormControl>
	) : searchableResults && searchMainValue ? (
		<Autocomplete
			disabled={disabled}
			value={searchMainValue}
			disableClearable
			blurOnSelect
			options={searchableResults}
			getOptionSelected={(option, value) => option.value === value.value}
			inputValue={searchValue}
			onChange={(e, value) => setSearchMainValue(value)}
			onInputChange={(e, value) => setSearchValue(value)}
			getOptionLabel={(option: any) => option.label}
			renderInput={(params: any) => (
				<TextField {...params} label={label} variant='outlined' />
			)}
		/>
	) : (
		<></>
	);
};

export const FormItemSelect = React.memo(FormItemSelectComp, areEqual);
