import React, { useEffect, useRef, useState } from 'react';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import {
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import { imageSizeServices } from 'api/services';
import { IResImageSize } from 'api/services/imageSize/types';
import handleNotification from 'helpers/handleNotification';
import { TextField } from '@material-ui/core';
import CustomModal from 'shared/CustomModal/CustomModal';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Confirmation from 'shared/Confirmation/Confirmation';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import './ImgSizesPage.sass';

export const ImgSizesPage: React.FC = () => {
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, images } = i18n;
	const modalRef = useRef<any>();
	const confirmationRef = useRef<any>();
	const [width, setWidth] = useState('');
	const [height, setHeight] = useState('');
	const [imgSizes, setImgSizes] = useState<IResImageSize[]>();
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deletedItemId, setDeletedItemId] = useState<number | undefined>();

	useEffect(() => {
		getImageSizes();
	}, [currentLang?.code]);

	const getImageSizes = async () => {
		const res = await imageSizeServices.getImageSizes().then((res) => res.data);
		setImgSizes(res);
	};

	const clearState = () => {
		setHeight('');
		setWidth('');
		setHasError(false);
	};

	const CreateImgSize = async () => {
		let validation = true;
		if (!width.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			validation = false;
		}

		if (!height.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			validation = false;
		}

		if (!validation) return;

		const requestData = {
			width: Number(width),
			height: Number(height),
		};

		try {
			setLoading(true);
			await imageSizeServices.createImageSize(requestData);
			modalRef.current.onHide();
			handleNotification(images.savedSizes, 'success');
			getImageSizes();
		} catch (e: any) {
			if (e.response.data.statusCode == '409')
				handleNotification(images.errorSizes);
			setLoading(false);
		}
	};

	const deleteItem = async (id: number) => {
		confirmationRef.current.onShow();
		setDeletedItemId(id);
	};

	const openModal = () => {
		modalRef.current.onShow();
	};

	const confirmDeleteItem = async () => {
		if (!deletedItemId) return;
		await imageSizeServices.deleteImageSize(deletedItemId);
		handleNotification(main.removed, 'success');
		getImageSizes();
	};

	return (
		<MainTemplate title={images.sizes} handler={() => openModal()}>
			<Confirmation
				text={images.removeSizeQuestion}
				title={images.removeSize}
				submit={confirmDeleteItem}
				changeState={() => setDeletedItemId(undefined)}
				ref={confirmationRef}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>№</TableCell>
						<TableCell>{main.name}</TableCell>
						<TableCell>{main.width}</TableCell>
						<TableCell>{main.height}</TableCell>
						<TableCell className='table-last-item'>{main.remove}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{imgSizes?.map((i, index) => {
						return (
							<TableRow key={i.id}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>{i.width}</TableCell>
								<TableCell>{i.height}</TableCell>
								<TableCell className='table-last-item'>
									<DeleteForever
										color='secondary'
										onClick={() => deleteItem(i.id)}
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<CustomModal
				ref={modalRef}
				submit={() => CreateImgSize()}
				title={images.addSize}
				text={images.addSize}
				load={loading}
				changeState={() => clearState()}>
				<TextField
					variant='outlined'
					autoFocus
					label={main.width}
					className='img-sizes-page__field'
					value={width}
					onChange={(e) => setWidth(e.target.value)}
					error={hasError}
				/>
				<TextField
					variant='outlined'
					label={main.height}
					className='img-sizes-page__field'
					value={height}
					onChange={(e) => setHeight(e.target.value)}
					error={hasError}
				/>
			</CustomModal>
		</MainTemplate>
	);
};
