import { Reducer } from 'redux';
import { INotificationInitState, NotificationTypes } from './types';

const initState = {
	showNotification: false,
	notificationTitle: undefined,
	notificationType: 'error',
	id: undefined,
};

const reducer: Reducer<INotificationInitState> = (
	state = initState,
	{ type, payload },
) => {
	switch (type) {
		case NotificationTypes.SHOW_NOTIFICATION:
			return {
				...state,
				showNotification: true,
				notificationTitle: payload.title,
				notificationType: payload.notificationType,
				id: payload.id,
			};
		case NotificationTypes.HIDE_NOTIFICATION:
			return {
				...state,
				showNotification: false,
				notificationTitle: '',
				notificationType: '',
				id: '',
			};
		default:
			return state;
	}
};

export default reducer;
