import instance from 'api/instance';
import {
  ICreateMenu,
  IUpdateMenu,
  IDeleteMenu,
  IGetMenus,
  IGetMenuById,
} from './types';

export const getMenus: IGetMenus = () => instance.get('menus');

export const getMenuById: IGetMenuById = (id) => instance.get(`menus/${id}`);

export const createMenu: ICreateMenu = (data) => instance.post('menus', data);

export const updateMenu: IUpdateMenu = (id, data) =>
  instance.put(`menus/${id}`, data);

export const deleteMenu: IDeleteMenu = (id) => instance.delete(`menus/${id}`);
