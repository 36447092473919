import { IResUser } from 'api/services/user/types';

export enum UserTypes {
	UPDATE_USER = '@profile/UPDATE_USER',
}

type IUserProfile = {
	type: UserTypes.UPDATE_USER;
};

export type UserActionTypes = IUserProfile;

export interface IUserInitState {
	user: IResUser | undefined;
}
