import { UserRoles } from 'api/services/user/types';
import translateRoles from 'helpers/translateRoles';

export const filteredUserRoles = () =>
	userRoles.filter((i) => i.value !== UserRoles.SU);

export const userRoles = [
	{
		value: UserRoles.SU,
		label: translateRoles(UserRoles.SU),
	},
	{
		value: UserRoles.ADMIN,
		label: translateRoles(UserRoles.ADMIN),
	},
	{
		value: UserRoles.USER,
		label: translateRoles(UserRoles.USER),
	},
];
