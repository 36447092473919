import { AxiosPromise } from 'axios';

export interface IUpdateUser {
	(id: number | string, data: IUser): AxiosPromise<IResUser>;
}

export interface IGetUsers {
	(): AxiosPromise<IResUser[]>;
}

export interface IResUser extends IUser {
	createdAt: Date;
	updatedAt: Date;
	id: number | string;
}

export interface IUser {
	isActive: boolean;
	role: UserRoles;
	name: string;
	password?: string;
	email: string;
}

export interface INewUser {
	(data: IUser): AxiosPromise;
}

export interface IUserById {
	(id: number | string): AxiosPromise;
}

export interface IUserRole {
	value: UserRoles.SU | UserRoles.ADMIN | UserRoles.USER;
	label: string;
}

export enum UserRoles {
	SU = 'SU',
	ADMIN = 'ADMIN',
	USER = 'USER',
}
