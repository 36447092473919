import React, { useEffect, useState } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import cn from 'classnames';
import { IResUser } from 'api/services/user/types';
import { userServices } from 'api/services';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import translateRoles from 'helpers/translateRoles';

import './UsersPage.sass';

export const UsersPage: React.FC = () => {
	const [users, setUsers] = useState<IResUser[]>([]);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, user, statuses } = i18n;

	useEffect(() => {
		getAllUsers();
	}, [currentLang?.code]);

	const getAllUsers = async () => {
		const res = await userServices.getUsers().then((res) => res.data);
		setUsers(res);
	};
	return (
		<>
			<MainTemplate title='Пользователи' url='/users/create'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{user.userName}</TableCell>
							<TableCell>{main.status}</TableCell>
							<TableCell>{main.mail}</TableCell>
							<TableCell>{user.userRole}</TableCell>
							<TableCell className='users-page__table-head-item'>
								{main.update}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.name}</TableCell>
								<TableCell
									className={cn('users-page__table-body-item', {
										'users-page__table-body-item--active': i.isActive,
									})}>
									{i.isActive ? statuses.active : statuses.notActive}
								</TableCell>
								<TableCell>{i.email}</TableCell>
								<TableCell>{translateRoles(i.role)}</TableCell>
								<TableCell className='users-action-buttons table-last-item'>
									<Link
										className='table-icon  table-icon--black'
										to={`/users/${i.id}`}>
										<EditIcon />
									</Link>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</MainTemplate>
		</>
	);
};
