import instance from 'api/instance';
import {
  ITaxonomyById,
  INewTaxonomy,
  IUpdateTaxonomy,
  IGetTaxonomy,
} from './types';

export const getTaxonomies: IGetTaxonomy = () => instance.get('/taxonomies');

export const getTaxonomyById: ITaxonomyById = (id) =>
  instance.get(`/taxonomies/${id}`);

export const createTaxonomy: INewTaxonomy = (data) =>
  instance.post('/taxonomies', data);

export const updateTaxonomy: IUpdateTaxonomy = (id, data) =>
  instance.put(`/taxonomies/${id}`, data);

export const deleteTaxonomy: ITaxonomyById = (id) =>
  instance.delete(`/taxonomies/${id}`);
