import { FormOperator } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const CONDITION_OPERATORS = (lang: string): IOption[] => {
	const { filled, empty, equal, notEqual, expression } = data[lang].conditions;

	return [
		{
			value: FormOperator.FILLED,
			label: filled,
		},
		{
			value: FormOperator.EMPTY,
			label: empty,
		},
		{
			value: FormOperator.EQUAL,
			label: equal,
		},
		{
			value: FormOperator.NOT_EQUAL,
			label: notEqual,
		},
		{
			value: FormOperator.TEST,
			label: expression,
		},
	];
};
