import React from 'react';
import { TextareaAutosize } from '@material-ui/core';
import './InputAutoSize.sass';

interface Props {
	value: string;
	updateValue(value: string): void;
	placeholder: string;
}
export const InputAutoSize: React.FC<Props> = ({
	value,
	updateValue,
	placeholder,
}) => {
	return (
		<>
			<TextareaAutosize
				className='input-auto-size'
				value={value}
				placeholder={placeholder}
				onChange={(e) => updateValue(e.target.value as string)}
			/>
		</>
	);
};
