import { DEFAULT_LANGUAGE } from 'constants/project';
import data from 'store/ducks/langs/data';
import getStorageLang from './getStorageLang';

export const translateNotificationStatuses = (
	status?: string,
	lang?: string,
) => {
	const { error, success } = data[getStorageLang].statuses;
	switch (status) {
		case 'error':
			return error;
		case 'success':
			return success;
		default:
			return '';
	}
};
