import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import arrayToTree from 'array-to-tree';
import { postCategoryServices } from 'api/services';
import { IResPostCategory } from 'api/services/postCategories/types';
import handleNotification from 'helpers/handleNotification';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomDND from 'shared/CustomDND/CustomDND';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import './PostCategoriesPage.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

export const PostCategoriesPage: React.FC = () => {
	const { taxonomyId } = useParams<{ taxonomyId: string }>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, posts } = i18n;
	const [treeCategories, setTreeCategories] = useState<IResPostCategory[]>([]);
	const [postCategoryId, setPostCategoryId] = useState<number>();
	const ref = useRef<any>();
	const { pathname } = useLocation();

	useEffect(() => {
		getPostCategories();
	}, [pathname, currentLang?.code]);

	const clearState = () => {
		setPostCategoryId(undefined);
	};

	const deleteConfirm = (id: number) => {
		setPostCategoryId(id);
		ref.current.onShow();
	};

	const generateTree = (res: IResPostCategory[]) => {
		const catTree = arrayToTree(res, {
			parentProperty: 'parentId',
			customID: 'id',
			childrenProperty: 'children',
		});
		setTreeCategories(catTree);
	};

	const getPostCategories = async () => {
		const res = await postCategoryServices
			.getPostCategories({ taxonomyId })
			.then((res) => res.data);
		generateTree(res);
	};

	const deletePostCategory = async () => {
		if (!postCategoryId) return;
		try {
			await postCategoryServices
				.deletePostCategory(postCategoryId)
				.then((res) => res.data);
			getPostCategories();
			clearState();
		} finally {
			handleNotification(main.removedSuccess, 'success');
		}
	};

	const renderItems = ({ item, collapseIcon }: any) => (
		<ul className='post-categories-page__list nestable__list'>
			<li className='nestable__text'>
				{collapseIcon} {item.title}
			</li>
			<li className='nestable__text'>{item.name}</li>
			<li className='nestable__text'>{item.description}</li>
			<li className='nestable__text'>
				{moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
			</li>
			<li className='table-last-item'>
				<Link
					to={`/taxonomies/${taxonomyId}/categories/${item.id}/posts`}
					className='table-icon table-icon--black'>
					<ArrowForwardIcon />
				</Link>
				<Link
					className='table-icon table-icon--black'
					to={`/taxonomies/${taxonomyId}/categories/${item.id}`}>
					<EditIcon />
				</Link>
				<DeleteForeverIcon
					color='secondary'
					className='table-icon'
					onClick={() => deleteConfirm(item.id)}
				/>
			</li>
		</ul>
	);
	return (
		<div className='post-categories-page'>
			<div className='post-categories-page__inner'>
				<MainTemplate
					url={`/taxonomies/${taxonomyId}/categories/create`}
					title='Категории'>
					<ul className='post-categories-page__list nestable__list'>
						<li className='nestable__text MuiTableCell-head'>{main.title}</li>
						<li className='nestable__text MuiTableCell-head'>{main.name}</li>
						<li className='nestable__text MuiTableCell-head'>
							{main.description}
						</li>
						<li className='nestable__text MuiTableCell-head'>
							{main.updatedDate}
						</li>
						<li className='nestable__text MuiTableCell-head table-last-item'>
							{main.action}
						</li>
					</ul>
					<CustomDND
						items={treeCategories}
						renderItems={renderItems}
						submitDrag={postCategoryServices.changePostCategoryOrder}
						refetchData={getPostCategories}
					/>
				</MainTemplate>
				<>
					<Confirmation
						ref={ref}
						text={posts.removeCategoryQuestion}
						submit={deletePostCategory}
						title={posts.removeCategory}
						changeState={clearState}
					/>
				</>
			</div>
		</div>
	);
};
