import {
  FormControl,
  Switch,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import { areEqual } from 'helpers/areEqual';
import React from 'react';
import { IFormDefaultProps, IFormSwitch } from 'shared/Form/types';

import './FormItemSwitch.sass';

interface Props {
  data: IFormSwitch;
  defaultProps: IFormDefaultProps;
}

const FormItemSwitchComp: React.FC<Props> = ({ data, defaultProps }) => {
  const { label, value, helperText, errorMsg } = data;
  const { name, onChange, disabled } = defaultProps;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange([{ name, value: e.target.checked }]);
  };

  return (
    <FormControl
      className="form-item-switch"
      variant="outlined"
      disabled={disabled}
      error={!!errorMsg}
    >
      <FormControlLabel
        control={
          <Switch checked={value} onChange={handleChange} color="primary" />
        }
        label={label}
        color="primary"
        className="checkbox--black"
      />
      <FormHelperText>{errorMsg || helperText}</FormHelperText>
    </FormControl>
  );
};

export const FormItemSwitch = React.memo(FormItemSwitchComp, areEqual);
