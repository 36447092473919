import instance from 'api/instance';
import { ILangById, IUpdateLang, INewLang } from './types';

export const getLangs = () => instance.get('/langs');

export const getLangById: ILangById = (id) => instance.get(`/langs/${id}`);

export const updateLang: IUpdateLang = (id, data) =>
	instance.put(`/langs/${id}`, data);

export const createLang: INewLang = (data) => instance.post('langs', data);

export const deleteLang: ILangById = (id) => instance.delete(`/langs/${id}`);
