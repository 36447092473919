import { FileTypes } from 'api/services/file/types';
import { DEFAULT_LANGUAGE } from 'constants/project';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const fields: IForm = {
	ogImage: {
		type: FormType.FILE,
		value: '',
		fileType: FileTypes.IMAGE,
		label: 'OgImage',
		extraKey: 'seo.ogImage',
	},
	title: {
		type: FormType.TEXT,
		value: '',
		label: data[DEFAULT_LANGUAGE]?.main.title,
		extraKey: 'seo.title',
	},
	description: {
		type: FormType.TEXTAREA,
		extraKey: 'seo.description',
		value: '',
		label: data[DEFAULT_LANGUAGE]?.main.description,
	},
	keywords: {
		type: FormType.TEXTAREA,
		value: '',
		extraKey: 'seo.keywords',
		label: data[DEFAULT_LANGUAGE]?.seo.keywords,
	},
};

export const view: IFormView = [
	{
		title: 'SEO',
		block: [['title'], ['description'], ['keywords'], ['ogImage']],
	},
];
