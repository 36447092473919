import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { IResPostCategory } from 'api/services/postCategories/types';
import { postCategoryServices, postService } from 'api/services';
import { IResPostWidthCategories } from 'api/services/posts/types';
import handleNotification from 'helpers/handleNotification';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Confirmation from 'shared/Confirmation/Confirmation';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './PostsPage.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

export const PostsPage: React.FC = () => {
	const { taxonomyId, categoryId } =
		useParams<{ taxonomyId: string; categoryId: string }>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, posts } = i18n;
	const confirmationRef = useRef<any>();
	const [data, setData] = useState<IResPostWidthCategories[]>([]);
	const [filterCategoryId, setFilterCategoryId] = useState<number>();
	const [page, setPage] = useState(0);
	const [take, setTake] = useState(20);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState(0);
	const [searchTrigger, setSearchTrigger] = useState(0);
	const [categories, setCategories] = useState<IResPostCategory[]>([]);
	const [currentPostId, setCurrentPostId] = useState<number>();

	useEffect(() => {
		if (categoryId) getData();
	}, [page, take, searchTrigger]);

	useEffect(() => {
		if (!categoryId) {
			getData();
			getCategories();
		}
	}, [page, take, searchTrigger, taxonomyId, currentLang?.code]);

	const getData = async () => {
		setLoading(true);
		const params = {
			take,
			skip: page * take,
			taxonomyId,
			categoryId: filterCategoryId || categoryId,
		};
		try {
			const res = await postService.getPosts(params).then((res) => res.data);
			setTotalCount(res.count);
			setData(res.data);
		} finally {
			setLoading(false);
		}
	};

	const getCategories = async () => {
		const res = await postCategoryServices
			.getPostCategories({ taxonomyId })
			.then((res) => res.data);
		setCategories(res);
	};

	const search = () => {
		setPage(0);
		setSearchTrigger((prev) => prev + 1);
	};

	const deletePost = async () => {
		if (!currentPostId) return;
		await postService.deletePost(currentPostId);
		handleNotification(posts.removedPost, 'success');
		confirmationRef.current.onHide();
		getData();
	};
	return (
		<>
			<Confirmation
				submit={deletePost}
				title={posts.removePost}
				text={posts.removePostQuestion}
				load={loading}
				ref={confirmationRef}
			/>
			<MainTemplate
				title={main.posts}
				filter={!categoryId}
				url={`/taxonomies/${taxonomyId}/${
					categoryId ? `categories/${categoryId}/` : ``
				}posts/create`}
				filterItems={categories}
				filterValue={categoryId}
				updateFilterValue={setFilterCategoryId}
				filterSearch={search}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.link}</TableCell>
							<TableCell>{main.category}</TableCell>
							<TableCell>{main.updatedDate}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((i) => (
							<TableRow key={i.id}>
								<TableCell>
									<Link
										className='table-link'
										to={`/taxonomies/${taxonomyId}/posts/${i.id}`}>
										{i.title}
									</Link>
								</TableCell>
								<TableCell>{i.slug || ''}</TableCell>
								<TableCell>{i.category.title || ''}</TableCell>
								<TableCell>
									{moment(i.createdAt).format('DD-MM-YYYY HH:mm')}
								</TableCell>
								<TableCell className='table-last-item'>
									<Link
										className='table-icon table-icon--black'
										to={`/taxonomies/${taxonomyId}/posts/${i.id}`}>
										<EditIcon />
									</Link>
									<DeleteForeverIcon
										className='table-icon'
										onClick={() => {
											confirmationRef.current.onShow();
											setCurrentPostId(i.id);
										}}
										color='secondary'
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={main.quantityPerPage}
					count={totalCount}
					page={page}
					component='div'
					onPageChange={(e, newPage) => setPage(newPage)}
					rowsPerPageOptions={[20, 30, 50]}
					rowsPerPage={take}
					onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
				/>
			</MainTemplate>
		</>
	);
};
