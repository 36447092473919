import React, { useState, useRef, useEffect } from 'react';
import { postCategoryServices } from 'api/services';
import {
	Grid,
	MenuItem,
	Paper,
	Select,
	FormControl,
	InputLabel,
	TextField,
	Typography,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import handleNotification from 'helpers/handleNotification';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { IPostCategory } from 'api/services/postCategories/types';
import { useParams } from 'react-router-dom';
import { templateServices } from 'api/services';
import { IResTemplate } from 'api/services/templates/types';
import getImageByImageSize from 'helpers/getImageByImageSize';
import { transliterate } from 'helpers/transliterate';
import { IFile } from 'api/services/file/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import './UpdatePostCategoryPage.sass';

export const UpdatePostCategoryPage: React.FC = () => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [title, setTitle] = useState('');
	const [file, setFile] = useState<IFile>();
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [templateId, setTemplateId] = useState<number>();
	const [templates, setTemplates] = useState<IResTemplate[]>([]);
	const { taxonomyId, categoryId } =
		useParams<{ taxonomyId: string; categoryId: string }>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, posts } = i18n;

	useEffect(() => {
		getPostCategory();
		getTemplates();
	}, [currentLang?.code]);

	const getTemplates = async () => {
		const res = await templateServices
			.getTemplates({ taxonomyId })
			.then((res) => res.data);
		setTemplates(res);
	};

	const getPostCategory = async () => {
		const res = await postCategoryServices
			.getPostCategoryById(categoryId)
			.then((res) => res.data);
		const { templateId, file, title, name, description } = res;
		setName(name);
		setTitle(title);
		setFile(file);
		setDescription(description);
		setTemplateId(templateId);
	};

	const createPost = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!name.trim() || !title.trim() || !templateId) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}
		setLoading(true);

		const requestData: IPostCategory = {
			name,
			description,
			title,
			fileId: file?.id || undefined,
			taxonomyId: Number(taxonomyId),
			templateId,
		};

		try {
			await postCategoryServices.updatePostCategory(categoryId, requestData);
			handleNotification(posts.savedCategory, 'success');
			getPostCategory();
		} finally {
			setLoading(false);
		}
	};

	const generationName = (value: string) => {
		setTitle(value);
		setName(transliterate(value));
	};

	return (
		<MainTemplate title={`${posts.updateCategory} - ${title}`} hideBtn>
			<Grid container>
				<Grid item md={8} className='update-post-category-page__main'>
					<TextField
						variant='outlined'
						label={main.title}
						autoFocus
						className='update-post-category-page__field form-field'
						value={title}
						onChange={(e) => generationName(e.target.value as string)}
						error={hasError && !title.trim()}
					/>
					<TextField
						variant='outlined'
						label={main.description}
						className='update-post-category-page__field form-field'
						rows={3}
						multiline
						value={description}
						onChange={(e) => setDescription(e.target.value as string)}
					/>
					<FormControl
						variant='outlined'
						error={!templateId && hasError}
						className='update-post-category-page__field form-field'>
						<InputLabel id='categories'>{main.defaultTemplate}</InputLabel>
						<Select
							label={main.defaultTemplate}
							value={templateId || ''}
							onChange={(e) => setTemplateId(Number(e.target.value as string))}>
							{templates?.map((i) => (
								<MenuItem key={i.id} value={i.id}>
									{i.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					md={4}
					container
					className='update-post-category-page__aside'
					spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label={main.name}
							className='form-field'
							value={name}
							onChange={(e) => setName(transliterate(e.target.value as string))}
							error={hasError && !name.trim()}
						/>
					</Grid>
					<Grid item xs={12}>
						<Paper variant='outlined'>
							<Grid container spacing={3} alignItems='stretch'>
								<Grid
									item
									xs={7}
									className='update-post-category-page__block post-page__block'>
									<Typography variant='h4' className='media-block__title'>
										{main.media}
									</Typography>
									<FileUploader text='Выбрать файл' selectFile={setFile} />
								</Grid>
								<Grid
									className='update-post-category-page__media post-page__media'
									container
									item
									alignItems='center'
									xs={5}>
									{file ? (
										<img
											className='update-post-category-page__media-item'
											src={getImageByImageSize({ src: file.src })}
											alt=''
										/>
									) : (
										<Typography
											className='update-post-category-page__media-item-title media-item-title'
											variant='h6'>
											{main.notSelectedImage}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} justifyContent='flex-end' container>
						<Button
							className='update-post-category-action-button action-button'
							size='large'
							color='primary'
							variant='contained'
							onClick={createPost}
							disabled={loading}>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								posts.updateCategory
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
