import React from 'react';
import { TextField } from '@material-ui/core';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';

export const SeoPage: React.FC = () => {
	return (
		<MainTemplate title='SEO'>
			<TextField variant='outlined' placeholder='Title' onChange={() => {}} />
		</MainTemplate>
	);
};
