import { Reducer } from 'redux';
import { IUserInitState, UserTypes } from './types';

const initState = {
	user: undefined,
};

const reducer: Reducer<IUserInitState> = (
	state: IUserInitState = initState,
	{ type, payload },
) => {
	switch (type) {
		case UserTypes.UPDATE_USER:
			return {
				...state,
				user: payload.user,
			};
		default:
			return state;
	}
};

export default reducer;
