import React, {
	useState,
	useEffect,
	useCallback,
	useImperativeHandle,
	forwardRef,
	useRef,
} from 'react';
import _ from 'lodash';
import { Grid, GridSize } from '@material-ui/core';
import { FormItemSelect } from './components/FormItemSelect/FormItemSelect';
import { FormItemText } from './components/FormItemText/FormItemText';
import { FormItemTextArea } from './components/FormItemTextArea/FormItemTextArea';
import {
	DictType,
	FormType,
	IForm,
	IFormAddHandler,
	IFormDefaultProps,
	IFormDeleteHandler,
	IFormItem,
	IFormOnChange,
	IFormRepeater,
	IFormSelect,
	IFormView,
} from './types';
import { FormItemFile } from './components/FormItemFile/FormItemFile';
import { FormItemMultiSelect } from './components/FormItemMultiSelect/FormItemMultiSelect';
import { FormItemSwitch } from './components/FormItemSwitch/FormItemSwitch';
import { FormRepeater } from './components/FormRepeater/FormRepeater';
import { CONDITION_OPERATORS } from 'dicts/CONDITION_OPERATORS';
import { FormTitle } from './components/FormTitle/FormTitle';
import { getFieldStates, getRowVisibility } from './helpers/getFieldStates';
import { validation, validationPayload } from './helpers/validation';
import { FormItemHTML } from './components/FormItemHTML/FormItemHTML';
import cn from 'classnames';
import './Form.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { DEFAULT_LANGUAGE } from 'constants/project';

interface Props {
	fields?: IForm;
	view?: IFormView;
	initialData?: IAnyObject;
	hasInitialData?: boolean;
}

interface PublicFunctions {
	submit(): validationPayload | undefined;
}

const Form: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
	props,
	ref,
) => {
	const { view, fields: propsFields, initialData, hasInitialData } = props;
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [fields, setFields] = useState<IForm>();
	const tabsRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const { currentLang } = useSelector((state: AppState) => state.langReducer);

	useEffect(() => {
		if (
			(propsFields && view && !hasInitialData) ||
			(hasInitialData && initialData && propsFields && view)
		) {
			init();
		}
	}, [!!propsFields, !!view, !!initialData]);

	useImperativeHandle(ref, () => ({
		submit,
	}));

	const init = () => {
		setFields(() => {
			const clone = _.cloneDeep(propsFields);
			if (clone && initialData) {
				Object.entries(clone).forEach(([key, value]) => {
					if (!value.extraKey) return;
					if (value.type === FormType.REPEATER) {
						clone[key].value = repeaterInit(value, initialData);
					} else {
						const val = _.get(initialData, value.extraKey);
						if (val) {
							clone[key].value = val;
						}
					}
				});
			}
			return clone;
		});
	};

	const repeaterInit = (repeater: IFormRepeater, data: IAnyObject) => {
		return _.get(data, repeater.extraKey || '', []).map(
			(i: any, index: number) => {
				const item: IAnyObject = {};
				Object.entries(repeater.initialValue).forEach(([key, value]) => {
					const val = _.get(i, value.extraKey || '');
					if (value.type === FormType.REPEATER) {
						item[key] = {
							...value,
							value: repeaterInit(value, i),
						};
					} else {
						item[key] = {
							...value,
							...(val ? { value: val } : {}),
							order: index,
						};
					}
				});
				return item;
			},
		);
	};

	const submit = (): validationPayload | undefined => {
		if (!fields) return;
		const res = validation(fields);
		setFields(res.fields);
		return res;
	};

	const renderItem = (name: string) => {
		if (!fields) return null;
		const item: IFormItem | undefined = _.get(fields, name);
		if (!item) return null;

		const defaultProps: IFormDefaultProps = {
			onChange,
			name,
			...getFieldStates(fields, name),
		};

		if (defaultProps.hidden) return null;

		const { dict } = item as IFormSelect;
		let options: IOption[] = [];
		if (dict) {
			options = getDict(dict);
		}

		switch (item.type) {
			case FormType.TEXT:
				return <FormItemText defaultProps={defaultProps} data={item} />;
			case FormType.TEXTAREA:
				return <FormItemTextArea defaultProps={defaultProps} data={item} />;
			case FormType.SELECT:
				return (
					<FormItemSelect
						defaultProps={defaultProps}
						data={{ ...item, ...(dict ? { options } : {}) }}
					/>
				);
			case FormType.SWITCH:
				return <FormItemSwitch defaultProps={defaultProps} data={item} />;
			case FormType.REPEATER:
				return (
					<FormRepeater
						defaultProps={defaultProps}
						data={item}
						addRow={addRow}
						deleteRow={deleteRow}
						renderItem={renderItem}
						fields={fields}
						handleSwap={handleSwap}
					/>
				);
			case FormType.HTML:
				return <FormItemHTML defaultProps={defaultProps} data={item} />;
			case FormType.TITLE:
				return <FormTitle defaultProps={defaultProps} data={item} />;
			case 'FILE':
				return <FormItemFile defaultProps={defaultProps} data={item} />;
			case 'MULTISELECT':
				return <FormItemMultiSelect defaultProps={defaultProps} data={item} />;
			default:
				return null;
		}
	};

	const onChange: IFormOnChange = useCallback((data) => {
		setFields((prev) => {
			if (!prev) return;
			const clone = _.cloneDeep(prev);
			data.forEach((i) => {
				const item = _.get(clone, i.name);
				if (!item) return;
				const { onChangeRegexp } = item;
				if (
					onChangeRegexp &&
					i.value &&
					!new RegExp(onChangeRegexp).test(i.value)
				) {
					return;
				}
				_.set(clone, `${i.name}.value`, i.value);
			});
			return clone;
		});
	}, []);

	const addRow: IFormAddHandler = useCallback((name) => {
		setFields((prev) => {
			if (!prev) return;
			const clone = _.cloneDeep(prev);
			const item = _.get(clone, name);
			if (!item || item.type !== FormType.REPEATER) return clone;
			const initialValue: any = item.initialValueRef
				? _.get(clone, `${item.initialValueRef}.initialValue`)
				: item.initialValue;
			if (initialValue) {
				item.value.push(_.cloneDeep(initialValue));
			}
			return clone;
		});
	}, []);

	const handleSwap = (
		e: React.MouseEvent<SVGSVGElement>,
		name: string,
		index: number,
		down?: boolean,
	) => {
		setFields((prev) => {
			if (!prev) return;
			const clone = _.cloneDeep(prev);
			const item = _.get(clone, name);
			if (!item || item.type !== FormType.REPEATER) return clone;
			const toIndex = !down ? index - 1 : index + 1;
			let element = item.value[index];
			item.value.splice(index, 1);
			item.value.splice(toIndex, 0, element);
			return clone;
		});
	};

	const deleteRow: IFormDeleteHandler = useCallback((name, index) => {
		setFields((prev) => {
			if (!prev) return;
			const clone = _.cloneDeep(prev);
			const item = _.get(clone, name);
			if (!item || item.type !== FormType.REPEATER) return clone;
			const array = _.cloneDeep(item.value);
			array.splice(index, 1);
			item.value = array;
			return clone;
		});
	}, []);

	const parseAllFieldsToDict = (): IOption[] => {
		if (!fields) return [];
		const res: IOption[] = [];
		((fields?.fields?.value as IForm[]) ?? []).forEach((i) =>
			Object.entries(i).forEach(([key, value]) => {
				res.push(...getChildrenFieldsToDict(value));
			}),
		);
		return res;
	};

	const getChildrenFieldsToDict = (item: IFormItem): IOption[] => {
		const res: IOption[] = [];
		if (item.type === FormType.TEXT && item.isFieldName && item.value) {
			res.push({
				value: item.value,
				label: item.value,
			});
		}
		if (item.type === FormType.REPEATER) {
			item.value.forEach((i) => {
				Object.entries(i).forEach(([key, value]) =>
					res.push(...getChildrenFieldsToDict(value)),
				);
			});
		}
		return res;
	};

	const handleActiveContainer = (index: number) => {
		setActiveIndex(index);
	};

	const getDict = (dict: DictType): IOption[] => {
		switch (dict) {
			case DictType.ALL_FIELDS:
				return parseAllFieldsToDict();
			case DictType.CONDITION_OPERATORS:
				return CONDITION_OPERATORS(currentLang?.code || DEFAULT_LANGUAGE);
			default:
				return [];
		}
	};
	if (!fields || !view) return null;

	return (
		<div className='form'>
			<div className='form__wrapper'>
				{view.some((i) => i.title) && (
					<div
						className='form__wrapper-tabs'
						ref={tabsRef}
						style={{ gridTemplateColumns: `repeat(${view.length}, 1fr)` }}>
						{view.map(
							(i, index) =>
								getRowVisibility(fields, _.flattenDeep(i.block)) && (
									<div
										key={index}
										className={cn('form-field__title form__wrapper-title', {
											'form__wrapper-title--active': index === activeIndex,
										})}
										onClick={() => handleActiveContainer(index)}>
										{i.title}
									</div>
								),
						)}
					</div>
				)}
				{view.map((i, iindex) => (
					<div
						key={iindex}
						className={cn('form__container', {
							'form__container--active': activeIndex === iindex,
						})}>
						{i.block.map((ii, index) => {
							if (!getRowVisibility(fields, ii)) return null;
							return (
								<Grid container spacing={2} key={index} className='form-field'>
									{ii.map((iii) => (
										<Grid item xs={(12 / ii.length) as GridSize} key={iii}>
											{renderItem(iii)}
										</Grid>
									))}
								</Grid>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};

export default forwardRef(Form);
