import { combineReducers } from 'redux';
import authReducer from './ducks/auth/reducers';
import notificationReducer from './ducks/notification/reducers';
import userReducer from './ducks/user/reducers';
import langReducer from './ducks/langs/reducers';
import taxonomyReducer from './ducks/taxonomies/reducers';

export default combineReducers({
	authReducer,
	notificationReducer,
	userReducer,
	langReducer,
	taxonomyReducer,
});
