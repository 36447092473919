import React, { useEffect, useRef, useState } from 'react';
import { history } from 'routes/history';
import moment from 'moment';
import arrayToTree from 'array-to-tree';
import { pageServices } from 'api/services';
import { IResPage } from 'api/services/pages/types';
import handleNotification from 'helpers/handleNotification';
import Confirmation from 'shared/Confirmation/Confirmation';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import CustomDND from 'shared/CustomDND/CustomDND';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import './PagesPage.sass';

export const PagesPage: React.FC = () => {
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const [pages, setPages] = useState<IResPage[]>([]);
	const [loading, setLoading] = useState(false);
	const [treePages, setTreePages] = useState<IResPage[]>([]);
	const [pageId, setPageId] = useState<string | number>('');
	const confirmation = useRef<any>();
	const { authorized } = useSelector((state: AppState) => state.authReducer);
	const { main } = i18n;

	useEffect(() => {
		getPages();
	}, [currentLang?.code]);

	const getPages = async () => {
		setLoading(true);
		try {
			const res = await pageServices.getPages().then((res) => res.data);
			setPages(res);
			generateTree(res);
		} finally {
			setLoading(false);
		}
	};

	const deletePage = async () => {
		setLoading(true);
		try {
			await pageServices.deletePage(pageId);
			handleNotification(i18n.pages.removedPage, 'success');
			confirmation.current.onHide();
			setLoading(false);
		} catch (e: any) {
			handleNotification(e.response.data.message);
		}
	};

	const generateTree = (res: IResPage[]) => {
		const catTree = arrayToTree(res, {
			parentProperty: 'parentId',
			customID: 'id',
			childrenProperty: 'children',
		});
		setTreePages(catTree);
	};

	const renderItems = ({ item, collapseIcon }: any) => (
		<ul className='pages__list nestable__list'>
			<li className='nestable__text'>
				{collapseIcon} {item.title}
			</li>
			<li className='nestable__text'>{item.slug}</li>
			<li className='nestable__text'>
				{item.visibility ? main.published : main.no}
			</li>
			<li className='nestable__text'>
				{moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
			</li>
			<li className='table-last-item'>
				<Link to={`/pages/${item.id}`} className='table-icon table-icon--black'>
					<EditIcon />
				</Link>
				<DeleteForeverIcon
					className='table-icon'
					onClick={() => {
						confirmation.current.onShow();
						setPageId(item.id);
					}}
					color='secondary'
				/>
			</li>
		</ul>
	);

	return (
		<>
			<Confirmation
				submit={deletePage}
				text={i18n.pages.removePageQuestion}
				title={i18n.pages.removePage}
				load={loading}
				ref={confirmation}
			/>
			<MainTemplate title={main.pages} url='/pages/create'>
				<div className='pages__table'>
					<ul className='pages__table-head pages__list nestable__list'>
						<li className='nestable__text MuiTableCell-head'>{main.name}</li>
						<li className='nestable__text MuiTableCell-head'>{main.link}</li>
						<li className='nestable__text MuiTableCell-head'>
							{main.published}
						</li>
						<li className='nestable__text MuiTableCell-head'>
							{main.updatedDate}
						</li>
						<li className='nestable__text MuiTableCell-head table-last-item'>
							{main.action}
						</li>
					</ul>
					<CustomDND
						items={treePages}
						renderItems={renderItems}
						submitDrag={pageServices.changePageChildren}
						refetchData={getPages}
					/>
				</div>
			</MainTemplate>
		</>
	);
};
