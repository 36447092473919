import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import './NotFoundPage.sass';

export const NotFoundPage = () => {
	const { backToMain, pageNotFound } = useSelector(
		(state: AppState) => state.langReducer.i18n.notFound,
	);
	return (
		<div className='not-found-page'>
			<Link to='/pages' className='not-found-page__link'>
				{backToMain}
			</Link>
			<div className='not-found-page__container'>
				<h1 className='not-found-page__title'>{pageNotFound}</h1>
			</div>
		</div>
	);
};
