import { UserRoles } from 'api/services/user/types';
import { DEFAULT_LANGUAGE } from 'constants/project';
import data from 'store/ducks/langs/data';

const i18n = data[DEFAULT_LANGUAGE]?.header;

const translateRoles = (role?: string) => {
	switch (role) {
		case UserRoles.SU:
			return i18n?.su;
		case UserRoles.ADMIN:
			return i18n?.admin;
		case UserRoles.USER:
			return i18n?.user;
		default:
			return '';
	}
};

export default translateRoles;
