import instance from 'api/instance';
import {
  ICreatePostCategory,
  IDeletePostCategory,
  IGetPostCategories,
  IGetPostCategoryById,
  IPostCatChangeOrder,
  IUpdatePostCategory,
} from './types';
import qs from 'query-string';

export const getPostCategories: IGetPostCategories = (query) =>
  instance.get(`post-categories?${qs.stringify(query || {})}`);

export const getPostCategoryById: IGetPostCategoryById = (id) =>
  instance.get(`post-categories/${id}`);

export const createPostCategory: ICreatePostCategory = (data) =>
  instance.post('post-categories', data);

export const updatePostCategory: IUpdatePostCategory = (id, data) =>
  instance.put(`post-categories/${id}`, data);

export const deletePostCategory: IDeletePostCategory = (id) =>
  instance.delete(`post-categories/${id}`);

export const changePostCategoryOrder: IPostCatChangeOrder = (data) =>
  instance.patch('post-categories/order', data);
