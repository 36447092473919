import React, { useEffect, useState, useRef } from 'react';
import {
	postCategoryServices,
	postService,
	taxonomyServices,
	templateServices,
} from 'api/services';
import { IResPostCategory } from 'api/services/postCategories/types';
import { useParams } from 'react-router-dom';
import {
	Grid,
	Paper,
	TextField,
	Typography,
	FormControl,
	Input,
	InputLabel,
	Select,
	Button,
	MenuItem,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import handleNotification from 'helpers/handleNotification';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { IPost } from 'api/services/posts/types';
import getImageByImageSize from 'helpers/getImageByImageSize';
import { IFile } from 'api/services/file/types';
import Form from 'shared/Form/Form';
import { transliterate } from 'helpers/transliterate';
import { IResTemplate } from 'api/services/templates/types';
import { CLIENT_URL } from 'constants/project';
import { IResTaxonomy } from 'api/services/taxonomies/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ISeoPayload, seoFields, seoView } from 'constants/seo';
import './UpdatePostPage.sass';
import { UserRoles } from 'api/services/user/types';
import { PostByImageSize } from 'helpers/PostByImageSize';

export const UpdatePostPage: React.FC = () => {
	const { taxonomyId, postId } =
		useParams<{ taxonomyId: string; postId: string }>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { langReducer, taxonomyReducer } = useSelector(
		(state: AppState) => state,
	);
	const { taxonomies } = taxonomyReducer;
	const { currentLang } = langReducer;
	const { main, posts } = langReducer.i18n;
	const [title, setTitle] = useState('');
	const [shortDescription, setShortDescription] = useState('');
	const [categories, setCategories] = useState<IResPostCategory[]>();
	const [categoryId, setCategoryId] = useState<number>();
	const [file, setFile] = useState<IFile>();
	const [slug, setSlug] = useState('');
	const [taxonomyName, setTaxonomyName] = useState('');
	const [categoryName, setCategoryName] = useState('');
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const formRef = useRef<any>();
	const seoRef = useRef<any>();
	const [activeTemplate, setActiveTemplate] = useState<IResTemplate>();
	const [templateId, setTemplateId] = useState<number>();
	const [templates, setTemplates] = useState<IResTemplate[]>();
	const [data, setData] = useState<IAnyObject>();
	const [seo, setSeo] = useState<ISeoPayload>();

	useEffect(() => {
		getPostById();
		getCategories();
		getTemplates();
	}, [currentLang?.code]);

	useEffect(() => {
		getTaxonomy();
	}, [currentLang?.code, taxonomies]);

	useEffect(() => {
		const template = templates?.find((i) => i.id === templateId);
		if (template) {
			setActiveTemplate(template);
		}
	}, [templateId, templates?.length]);

	const getTemplates = async () => {
		const query = { taxonomyId };
		const res = await templateServices
			.getTemplates(query)
			.then((res) => res.data);
		setTemplates(res);
	};

	const getTaxonomy = async () => {
		taxonomies?.forEach((i: IResTaxonomy) => {
			if (i.id === Number(taxonomyId)) {
				setTaxonomyName(`/${i.name}`);
			}
		});
	};

	const getPostById = async () => {
		const res = await postService.getPostById(postId).then((res) => res.data);
		const {
			title,
			slug,
			file,
			categoryId,
			data,
			templateId,
			seoDescription,
			seoKeywords,
			seoTitle,
			seoOgImage,
			category,
			shortDescription,
		} = res;
		setCategoryName(category.name);
		setTitle(title);
		setSlug(slug || '');
		setFile(file);
		setCategoryId(categoryId);
		setData(data);
		setTemplateId(templateId);
		setShortDescription(shortDescription || '');
		setSeo({
			description: seoDescription,
			keywords: seoKeywords,
			title: seoTitle,
			ogImage: seoOgImage,
		});
	};

	const getCategories = async () => {
		const res = await postCategoryServices
			.getPostCategories({ taxonomyId })
			.then((res) => res.data);
		setCategories(res);
	};
	const updatePost = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!title.trim() || !templateId) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}
		const formRes = formRef.current?.submit();
		if (
			formRes &&
			(!formRes || (formRes?.hasErrors && user?.role !== UserRoles.SU))
		) {
			handleNotification(main.fillFields);
			return;
		}
		const seoRes = seoRef.current?.submit();

		const requestData: IPost = {
			title,
			categoryId: Number(categoryId),
			fileId: file?.id,
			slug,
			data: formRes?.res ?? {},
			templateId,
			seoTitle: seoRes.res.title,
			seoDescription: seoRes.res.description,
			seoKeywords: seoRes.res.keywords,
			seoOgImage: seoRes.res.ogImage,
			shortDescription,
		};
		setLoading(true);
		try {
			await postService.updatePost(postId, requestData);
			handleNotification(main.saveSuccess, 'success');
			getPostById();
		} finally {
			setLoading(false);
			setHasError(false);
		}
	};

	const updateTitle = (value: string) => {
		setTitle(value);
		setSlug(transliterate(value));
	};

	const setCategory = (id: number) => {
		setCategoryId(id);
		const found = categories?.find((i) => i.id === id);
		if (found) {
			setCategoryName(`/${found.name}/`);
		}
	};

	return (
		<MainTemplate title={`${posts.updatePost} - ${title}`} hideBtn>
			<Grid container alignItems='flex-start'>
				<Grid item xs={8} className='update-post-page__main'>
					<Grid item xs={12} className='update-post-page__container'>
						<FormControl className='update-post-page__main-title'>
							<Input
								className='update-post-page__main-title-input'
								value={title}
								autoFocus
								placeholder={main.name}
								onChange={(e) => updateTitle(e.target.value as string)}
							/>
						</FormControl>
					</Grid>
					{activeTemplate && (
						<Form
							ref={formRef}
							view={activeTemplate.view}
							fields={activeTemplate.properties}
							initialData={data}
						/>
					)}
					<Grid item xs={12} className='update-post-page__seo'>
						<Form
							ref={seoRef}
							view={seoView}
							fields={seoFields}
							initialData={seo}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={4}
					container
					spacing={2}
					className='update-post-page__aside'>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label={main.link}
							className='form-field'
							value={slug}
							onChange={(e) => setSlug(transliterate(e.target.value as string))}
						/>
					</Grid>
					<Grid item xs={12}>
						<a
							href={`${CLIENT_URL}${taxonomyName ? taxonomyName : ''}${
								categoryName ? '/' + categoryName : ''
							}${categoryName ? '/' + slug : ''}`}
							className='update-page__aside-link'
							target='_blank'
							rel='noreferrer'>
							{`${CLIENT_URL}${taxonomyName ? taxonomyName : ''}${
								categoryName ? '/' + categoryName : ''
							}${categoryName ? '/' + slug : ''}`}
						</a>
					</Grid>
					<Grid item xs={12}>
						<FormControl
							variant='outlined'
							error={!categoryId && hasError}
							className='form-field'>
							<InputLabel id='categories'>{main.category}</InputLabel>
							<Select
								label={main.category}
								value={categoryId || ''}
								onChange={(e) => setCategory(e.target.value as number)}>
								{categories?.map((i) => (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl
							variant='outlined'
							className='form-field'
							error={!templateId && hasError}>
							<InputLabel id='categories'>{main.template}</InputLabel>
							<Select
								label={main.template}
								value={templateId || ''}
								onChange={(e) => setTemplateId(e.target.value as number)}>
								{templates?.map((i) => (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					{/*разные размеры изображений*/}
					{/* <PostByImageSize file={file} setFile={setFile} /> */}
					<Grid item xs={12}>
						<Paper variant='outlined'>
							<Grid container spacing={3} alignItems='stretch'>
								<Grid item xs={7} className='post-page__block'>
									<Typography
										variant='h4'
										style={{ marginBottom: '20px' }}
										className='media-block__title'>
										{main.media}
									</Typography>
									<FileUploader
										text={main.selectFile}
										width={1080}
										height={1080}
										selectFile={setFile}
									/>
								</Grid>
								<Grid
									className='post-page__media'
									container
									item
									alignItems='center'
									xs={5}>
									{file ? (
										<img
											className='post-page__media-item'
											src={getImageByImageSize({
												src: file.src,
											})}
											alt=''
										/>
									) : (
										<Typography className='media-item-title' variant='h6'>
											{main.notSelectedImage}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} justifyContent='flex-end' container>
						<Button
							className='post-page__button'
							size='large'
							color='primary'
							variant='contained'
							onClick={updatePost}
							disabled={loading}>
							{loading ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								main.save
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
