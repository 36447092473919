import React from 'react';
import { useSelector } from 'react-redux';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { AppState } from 'store/store';
// import './MediaPage.sass';

export const MediaPage: React.FC = () => {
	const { addFiles } = useSelector(
		(state: AppState) => state.langReducer.i18n.main,
	);
	return <FileUploader text={addFiles} staticPosition open />;
};
