import instance from 'api/instance';
import { ICreateImageSize, IGetImageSizes, IDeleteImageSize } from './types';

export const createImageSize: ICreateImageSize = (data) =>
  instance.post('image-sizes', data);

export const getImageSizes: IGetImageSizes = () => instance.get('image-sizes');

export const deleteImageSize: IDeleteImageSize = (id) =>
  instance.delete(`image-sizes/${id}`);
