import instance from 'api/instance';
import { IGetFiles, IUpdateFile, IUploadFile } from './types';
import qs from 'query-string';

export const deleteFile = (id: number) => instance.delete(`/files/${id}`);

export const getFiles: IGetFiles = (attrs) =>
	instance.get(`/files?${qs.stringify(attrs)}`);

export const uploadFile: IUploadFile = (file) =>
	instance.post(`/files`, file, {
		headers: {
			['Content-Type']: 'multipart/form-data',
		},
	});

export const updateFile: IUpdateFile = (id, data) =>
	instance.put(`/files/${id}`, data);
