import { IResTaxonomy } from 'api/services/taxonomies/types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';
import { taxonomyServices } from 'api/services';
import { TaxonomyActionTypes, TaxonomyType } from './types';

export const getTaxonomies =
	(): ThunkAction<void, AppState, unknown, TaxonomyActionTypes> =>
	async (dispatch) => {
		const taxonomies: IResTaxonomy[] = await taxonomyServices
			.getTaxonomies()
			.then((res) => res.data);
		dispatch({
			type: TaxonomyType.GET_TAXONOMIES,
			payload: { taxonomies },
		});
	};
