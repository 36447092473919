import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { dictOptionsServices } from 'api/services';
import Loader from 'shared/Loader/Loader';
import { Button } from '@material-ui/core';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { IResDictOption } from 'api/services/dict-option/types';
import Form from 'shared/Form/Form';
import { fields, view } from './formData';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import redirect from 'helpers/redirect';
import handleNotification from 'helpers/handleNotification';

export const UpdateDictOptionPage: React.FC = () => {
	const { dictId, dictOptionId } =
		useParams<{ dictId: string; dictOptionId: string }>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, directories } = i18n;
	const [loading, setLoading] = useState(false);
	const [dictOption, setDictOption] = useState<IResDictOption>();
	const formRef = useRef<any>();
	const history = useHistory();

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	useEffect(() => {
		getData();
	}, [currentLang?.code]);

	const getData = async () => {
		if (!dictOptionId) return;
		const res = await dictOptionsServices
			.getDictOptionById(dictOptionId)
			.then((res) => res.data);
		setDictOption(res);
	};

	const updateDictOption = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!dictOption) return;

		const formRes = formRef.current?.submit();
		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFields);
			return;
		}

		const requestData = {
			value: formRes.res.value,
			label: formRes.res.label,
			dictId: Number(dictId),
			parentId: dictOption.parentId,
		};

		try {
			setLoading(true);
			await dictOptionsServices.updateDictOption(dictOptionId, requestData);
			history.push(`/dicts/${dictId}/options`);
		} catch (e: any) {
			if (e.response.data.statusCode === 409) {
				handleNotification('This value already exists');
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={directories.editOption} hideBtn>
			<form className='update-dict-option-page' onSubmit={updateDictOption}>
				<Form
					ref={formRef}
					view={view}
					fields={fields}
					initialData={dictOption}
				/>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					<Loader loading={loading}>{directories.editOption}</Loader>
				</Button>
			</form>
		</MainTemplate>
	);
};
