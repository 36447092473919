export enum NotificationTypes {
	SHOW_NOTIFICATION = '@notification/SHOW_NOTIFICATION',
	HIDE_NOTIFICATION = '@notification/HIDE_NOTIFICATION',
}
type IShowNotification = {
	type: NotificationTypes.SHOW_NOTIFICATION;
};

type IHideNotification = {
	type: NotificationTypes.HIDE_NOTIFICATION;
};

export type NotificationActionTypes = IShowNotification | IHideNotification;

export interface INotificationInitState {
	showNotification: boolean;
	notificationTitle?: string;
	notificationType?: string;
	id?: string;
}
