import instance from 'api/instance';
import { IAuthUser, IResetPassword, IAuthConfirm } from './types';

export const authUser: IAuthUser = (data) =>
	instance.post('/auth/sign-in', data);

export const resetPassword: IResetPassword = (data) =>
	instance.post('/auth/reset-password', data);

export const confirmOtp: IAuthConfirm = (data) =>
	instance.post('/auth/otp', data);

export const updatePassword: IAuthConfirm = (data) =>
	instance.post('/auth/otp', data);
