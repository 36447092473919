import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@material-ui/core';
import cn from 'classnames';
import Loader from 'react-loader-spinner';
import './Confirmation.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
	submit: () => void;
	title: string;
	text: string;
	load?: boolean;
	changeState?(): void;
}

interface PublicFunctions {
	onShow(): void;
	onHide(): void;
}

const Confirmation: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
	props,
	ref,
) => {
	const { title, text, submit, load, changeState = () => {} } = props;
	const [visibility, setVisibility] = useState(false);
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);

	useImperativeHandle(ref, () => ({
		onShow,
		onHide,
		visibility,
	}));

	const confirm = () => {
		submit();
		onHide();
	};

	const onShow = () => {
		setVisibility(true);
	};

	const onHide = () => {
		setVisibility(false);
		changeState();
	};

	if (!visibility) return null;

	return (
		<div className='confirmation'>
			<div className='confirmation__blackout' onClick={onHide}></div>
			<div className='confirmation__window'>
				<CancelIcon onClick={onHide} className='confirmation__exit' />
				<div className='confirmation__title'>{title}</div>
				<div className='confirmation__text'>{text}</div>
				<div className='confirmation__btn-row'>
					<div className='confirmation__btn'>
						<Button
							color='primary'
							variant='contained'
							className={cn({
								'btn--hidden': load,
							})}
							fullWidth
							onClick={confirm}
							disabled={load}>
							{load ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								main?.confirm
							)}
						</Button>
					</div>
					<div className='confirmation__btn'>
						<Button variant='contained' fullWidth onClick={() => onHide()}>
							{main.cancel}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(Confirmation);
