import React, { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { dictOptionsServices } from 'api/services';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Loader from 'shared/Loader/Loader';
import { Button } from '@material-ui/core';
import Form from 'shared/Form/Form';
import { fields, view } from './formData';
import handleNotification from 'helpers/handleNotification';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

export const CreateDictOptionPage: React.FC = () => {
	const { dictId } = useParams<{ dictId: string }>();
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);
	const [loading, setLoading] = useState(false);
	const formRef = useRef<any>();
	const history = useHistory();

	const createDictOption = async (e: React.FormEvent) => {
		e.preventDefault();

		const formRes = formRef.current?.submit();
		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFeild);
			return;
		}

		const requestData = {
			value: formRes.res.value,
			label: formRes.res.label,
			dictId: Number(dictId),
		};

		try {
			setLoading(true);
			await dictOptionsServices.createDictOption(requestData);
			history.push(`/dicts/${dictId}/options`);
		} catch (e: any) {
			if (e.response.data.statusCode === 409) {
				handleNotification('This value already exists');
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={main.createOption} hideBtn>
			<form onSubmit={createDictOption} className='create-dict-option-page'>
				<Form view={view} fields={fields} ref={formRef} />
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					<Loader loading={loading}>{main.create}</Loader>
				</Button>
			</form>
		</MainTemplate>
	);
};
