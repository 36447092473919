import { AxiosPromise } from 'axios';

export interface IUploadFile {
	(formData: FormData): AxiosPromise<IFile>;
}

export interface IFile {
	id: number;
	menuId?: number;
	src: string;
	type: string;
	createdAt: string;
	alt: string;
	updatedAt: string;
	fileImageSizes?: IFileImageSize[];
}

export interface IResFile {
	data: IFile[];
	count: number;
}

export type IFileWithoutSize = Omit<IFile, 'src,  id'>;

export interface IUpdateFile {
	(
		id: number | string,
		data: {
			alt: string;
		},
	): AxiosPromise;
}

export interface IGetFiles {
	(attrs: {
		skip: number;
		take: number;
		type?: string;
	}): AxiosPromise<IResFile>;
}

export interface IFileImageSize {
	src: string;
	imageSize: {
		name: string;
	};
	id: number;
}

export enum FileTypes {
	AUDIO = 'AUDIO',
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	PDF = 'PDF',
	FILE = 'FILE',
}

type ImageType = FileTypes.IMAGE;

type AudioType = FileTypes.AUDIO;

type VideoType = FileTypes.VIDEO;

type PDFType = FileTypes.PDF;

type FileType = FileTypes.FILE;

export type FileActionTypes =
	| ImageType
	| AudioType
	| VideoType
	| PDFType
	| FileType;
