import axios from 'axios';
import { API_URL, DEFAULT_LANGUAGE } from 'constants/project';
import handleNotification from 'helpers/handleNotification';
import * as authActions from 'store/ducks/auth/actions';
import store from 'store/store';
import data from 'store/ducks/langs/data';

const instance = axios.create({
	baseURL: API_URL,
	headers: {
		common: {
			Accept: 'application/json',
			'Content-Type': 'application/json charset=utf-8',
			'Accept-language': DEFAULT_LANGUAGE,
		},
	},
});

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		switch (error?.response?.data.statusCode) {
			case 401:
				handleNotification(data[DEFAULT_LANGUAGE].main.notAuthUser);
				store.dispatch(authActions.signOut());
				break;
		}
		return Promise.reject(error);
	},
);

export default instance;
