import store from 'store/store';
import { signIn } from 'store/ducks/auth/actions';
import { history } from 'routes/history';
import { updateProfile } from 'store/ducks/user/actions';
import * as langActions from 'store/ducks/langs/actions';
import { DEFAULT_LANGUAGE } from 'constants/project';

const appConfig = () => {
	const { dispatch } = store;
	const token = localStorage.getItem('accessToken');
	const user = localStorage.getItem('user');
	const lang = localStorage.getItem('lang');
	if (!lang) {
		localStorage.setItem('lang', DEFAULT_LANGUAGE);
	}
	dispatch(langActions.getLangs());
	if (!token || !user) return history.push('/');
	dispatch(signIn(token));
	dispatch(updateProfile(JSON.parse(user)));
};

export default appConfig;
