import React, { useState, useEffect } from 'react';
import {
	TextField,
	Button,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import {
	componentsServices,
	templateServices,
	taxonomyServices,
} from 'api/services';
import { ITemplate, TemplateTypes } from 'api/services/templates/types';
import { IResTaxonomy } from 'api/services/taxonomies/types';
import { useHistory, useParams } from 'react-router-dom';
import { InputAutoSize } from 'shared/InputAutoSize/InputAutoSize';
import { IResComponent } from 'api/services/components/types';
import templateTypes from 'constants/templateTypes';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import redirect from 'helpers/redirect';
import './UpdateTemplatePage.sass';
import { DEFAULT_LANGUAGE } from 'constants/project';

export const UpdateTemplatePage: React.FC = () => {
	const [title, setTitle] = useState('');
	const [name, setName] = useState('');
	const [templateData, setTemplateData] = useState('');
	const [templateView, setTemplateView] = useState('');
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [componentIds, setComponentIds] = useState<number[]>([]);
	const [components, setComponents] = useState<IResComponent[]>();
	const [selectedTaxonomies, setSelectedTaxonomies] = useState<number[]>([]);
	const [templateType, setTemplateType] = useState('');
	const { templateId } = useParams<{ templateId: string }>();
	const history = useHistory();
	const id = Number(templateId);
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { taxonomies } = useSelector(
		(state: AppState) => state.taxonomyReducer,
	);
	const { main, templates } = i18n;

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	useEffect(() => {
		getComponents();
		getTemplateById();
	}, [currentLang?.code]);

	const getComponents = async () => {
		const params = {};
		const res = await componentsServices
			.getComponents(params)
			.then((res) => res.data);
		setComponents(res.data);
	};

	const updateTemplate = async (e: React.FormEvent) => {
		e.preventDefault();
		if (
			!title.trim() ||
			!componentIds ||
			!name.trim() ||
			!selectedTaxonomies ||
			!templateType
		) {
			setHasError(true);
			handleNotification(main.fillFields);
			return;
		}
		setLoading(true);
		const data: ITemplate = {
			title,
			name,
			components: componentIds,
			type: templateType as TemplateTypes,
			...(templateData ? { properties: JSON.parse(templateData) } : {}),
			...(templateView ? { view: JSON.parse(templateView) } : {}),
			...(templateType === 'POST' ? { taxonomyIds: selectedTaxonomies } : {}),
		};
		try {
			await templateServices.updateTemplate(id, data);
			handleNotification(templates.savedTemplate, 'success');
			history.push('/templates');
		} catch (e: any) {
			setLoading(false);
			handleNotification(e.resposne.data.message);
		}
	};

	const getTemplateById = async () => {
		try {
			const res = await templateServices
				.getTemplateById(id)
				.then((res) => res.data);
			setTitle(res.title);
			setName(res.name);
			setTemplateType(res.type);
			setTemplateData(res.properties ? JSON.stringify(res.properties) : '');
			setTemplateView(res.view ? JSON.stringify(res.view) : '');
			const taxonomyArr = res?.taxonomies?.map((i) => i.id);
			const componentsArr = res?.components?.map((i) => i.id);
			setSelectedTaxonomies(taxonomyArr || []);
			setComponentIds(componentsArr || []);
		} catch {
			handleNotification(main.tryAgain);
		}
	};

	return (
		<MainTemplate title={templates.updateTemplate} hideBtn>
			<form>
				<TextField
					variant='outlined'
					label={main.title}
					className='form-field'
					value={title}
					error={hasError && !title.trim()}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='form-field'
					value={name}
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				<FormControl variant='outlined' className='form-field'>
					<InputLabel id='categories'>{main.components}</InputLabel>
					<Select
						label={main.components}
						value={componentIds}
						multiple
						onChange={(e) => setComponentIds(e.target.value as number[])}>
						{components?.map((i) => (
							<MenuItem key={i.id} value={i.id}>
								{i.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl className='form-field' variant='outlined'>
					<InputLabel>{main.type}</InputLabel>
					<Select
						label={main.type}
						value={templateType}
						onChange={(e) => setTemplateType(e.target.value as string)}>
						{templateTypes(currentLang?.code || DEFAULT_LANGUAGE)?.map((i) => {
							return (
								<MenuItem key={i.label} value={i.value}>
									{i.label}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				{templateType === 'POST' && (
					<FormControl className='form-field' variant='outlined'>
						<InputLabel id='categories'>{main.taxonomies}</InputLabel>
						<Select
							labelId='categories'
							label={main.taxonomies}
							multiple
							value={selectedTaxonomies}
							onChange={(e) =>
								setSelectedTaxonomies(e.target.value as number[])
							}>
							{taxonomies?.map((i) => (
								<MenuItem key={i.id} value={i.id}>
									{i.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<InputAutoSize
					value={templateData}
					placeholder={main.templateData}
					updateValue={setTemplateData}
				/>
				<InputAutoSize
					value={templateView}
					placeholder={main.displaySettings}
					updateValue={setTemplateView}
				/>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					onClick={updateTemplate}
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						main.update
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
