import { FileTypes } from 'api/services/file/types';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import i18n from 'store/ducks/langs/data';
import { DEFAULT_LANGUAGE } from './project';

const { main, seo } = i18n[DEFAULT_LANGUAGE] || {};

export const seoFields: IForm = {
	title: {
		type: FormType.TEXT,
		value: '',
		label: main?.title,
		extraKey: 'title',
	},
	description: {
		type: FormType.TEXTAREA,
		label: main?.description,
		value: '',
		extraKey: 'description',
	},
	keywords: {
		type: FormType.TEXTAREA,
		value: '',
		label: seo?.keywords,
		extraKey: 'keywords',
	},
	ogImage: {
		type: FormType.FILE,
		value: '',
		fileType: FileTypes.IMAGE,
		label: seo?.socPicture,
		extraKey: 'ogImage',
	},
};

export const seoView: IFormView = [
	{
		title: 'SEO',
		block: [['title'], ['description'], ['keywords'], ['ogImage']],
	},
];

export interface ISeoPayload {
	title?: string;
	description?: string;
	keywords?: string;
	ogImage?: string;
}
