import instance from 'api/instance';
import qs from 'query-string';
import { IChangeDictOptionOrder, ICreateDictOption, IDeleteDictOption, IGetDictOptionById, IGetDictOptions, IUpdateDictOption } from './types';

export const createDictOption: ICreateDictOption = (data) => instance.post('dict-options', data)

export const updateDictOption: IUpdateDictOption = (id, data) =>
	instance.put(`dict-options/${id}`, data);

export const getDictOption: IGetDictOptions = (params) => instance.get(`dict-options?${qs.stringify(params)}`)

export const getDictOptionById: IGetDictOptionById = (dictId) => instance.get(`dict-options/${dictId}`)

export const changeDictOptionOrder: IChangeDictOptionOrder = (data) =>
	instance.patch('dict-options/order', data);

export const deleteDictOption: IDeleteDictOption = (dictId) => instance.delete(`dict-options/${dictId}`)