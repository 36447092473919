import { IResLang } from 'api/services/langs/types';

export enum LangTypes {
	CHANGE_LANG = '@/lang/CHANGE_LANG',
	GET_LANGS = '@/lang/GET_LANGS',
}

type IChangeLang = {
	type: LangTypes.CHANGE_LANG;
};

type IGetLang = {
	type: LangTypes.GET_LANGS;
};

export type LangActionTypes = IGetLang | IChangeLang;

export interface ILangInitState {
	currentLang?: IResLang;
	langs?: IResLang[];
	i18n: IInitData;
}

export type IInitData = {
	[key: string]: {
		[key: string]: string;
	};
};

export type IData = {
	[key: string]: {
		[key: string]: {
			[key: string]: string;
		};
	};
};
