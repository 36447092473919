import { DEFAULT_LANGUAGE } from 'constants/project';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const fields: IForm = {
	name: {
		type: FormType.TEXT,
		value: '',
		required: true,
		extraKey: 'name',
		label: data[DEFAULT_LANGUAGE]?.main.name,
	},
	title: {
		type: FormType.TEXT,
		value: '',
		required: true,
		extraKey: 'title',
		label: data[DEFAULT_LANGUAGE]?.main.title,
	},
};

export const view: IFormView = [
	{
		block: [['name'], ['title']],
	},
];
