import { AxiosPromise } from 'axios';
import _ from 'lodash';
import Nestable from 'react-nestable';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './CustomDND.sass';

interface Props {
	items: any[];
	renderItems: ({ item }: any) => JSX.Element;
	submitDrag: (data: { items: any[] }) => AxiosPromise;
	refetchData: () => Promise<void>;
	noOrder?: boolean;
	depth?: number;
}
const CustomDND = ({
	items,
	renderItems,
	submitDrag,
	refetchData,
	depth,
	noOrder,
}: Props) => {
	const changeDragItem = async (arg: any) => {
		const { dragItem, targetPath, items } = arg;

		const order = _.last(targetPath) as number;
		const parentPath = targetPath.slice(0, -1);

		let parentId: number | null = null;
		let same: any = [];

		if (targetPath.length === 1) {
			same = items;
			parentId = null;
		} else {
			const parent: any = _.get(
				items,
				parentPath.map((i: number) => `[${i}]`).join('.children'),
				[],
			);
			same = parent.children ?? [];
			parentId = parent.id;
		}

		same = same.filter((i: any) => i.id !== dragItem.id) ?? [];

		dragItem.parentId = parentId;
		const parsedItems = same.sort((a: any, b: any) => a.order - b.order);
		parsedItems.splice(order, 0, dragItem);
		const filtered = same.map((i: any, index: number) => ({
			id: i.id,
			parentId: parentId,
			...(noOrder ? {} : { order: index }),
		}));

		let reqData = {
			items: filtered,
		};
		try {
			await submitDrag(reqData);
		} finally {
			refetchData();
		}
	};

	return (
		<Nestable
			items={items}
			renderItem={renderItems}
			onChange={changeDragItem}
			renderCollapseIcon={({ isCollapsed }) => (
				<div className='nestable__button'>
					{isCollapsed ? <AddIcon /> : <RemoveIcon />}
				</div>
			)}
			collapsed={false}
			maxDepth={depth}
		/>
	);
};

export default CustomDND;
