import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { templateServices } from 'api/services';
import { IResTemplate } from 'api/services/templates/types';
import handleNotification from 'helpers/handleNotification';
import redirect from 'helpers/redirect';
import Confirmation from 'shared/Confirmation/Confirmation';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { TableCell, TableHead } from '@material-ui/core';
import { Table, TableBody, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import { UserRoles } from 'api/services/user/types';

export const TemplatesPage: React.FC = () => {
	const [templates, setTemplates] = useState<IResTemplate[]>([]);
	const [loading, setLoading] = useState(false);
	const [templateId, setTemplateId] = useState<number | null>(null);
	const confirmation = useRef<any>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;
	const { user } = useSelector((state: AppState) => state.userReducer);

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	useEffect(() => {
		getTemplates();
	}, [currentLang?.code]);

	const deleteTemplate = async () => {
		if (!templateId) return;
		try {
			await templateServices.deleteTemplate(templateId);
			handleNotification(i18n.templates.removedTemplate, 'success');
			getTemplates();
		} catch {
			handleNotification(main.tryAgain);
		} finally {
			setTemplateId(null);
		}
	};

	const getTemplates = async () => {
		const res = await templateServices.getTemplates().then((res) => res.data);
		setTemplates(res);
	};

	return (
		<>
			<Confirmation
				submit={deleteTemplate}
				title={i18n.templates.removeTemplate}
				text={i18n.templates.removeTemplateQuestion}
				load={loading}
				ref={confirmation}
			/>
			<MainTemplate title={main.templates} url='/templates/create'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.title}</TableCell>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.type}</TableCell>
							<TableCell>{main.updatedDate}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{templates?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>{i.type.toLowerCase()}</TableCell>
								<TableCell>
									{moment(i.updatedAt).format('DD.MM.YYYY HH:mm')}
								</TableCell>
								<TableCell className='tempaltes__actions table-last-item'>
									<Link
										to={`/templates/${i.id}`}
										className='table-icon table-icon--black'>
										<EditIcon />
									</Link>
									<DeleteForeverIcon
										color='secondary'
										onClick={() => {
											confirmation.current.onShow();
											setTemplateId(i.id);
										}}
										className='table-icon'
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</MainTemplate>
		</>
	);
};
