import { IData } from './types';

const data: IData = {
	ru: {
		header: {
			su: 'Супер пользователь',
			admin: 'Администратор',
			user: 'Пользователь',
		},
		main: {
			title: 'Заголовок',
			tagline: 'Слоган',
			name: 'Название',
			description: 'Описание',
			date: 'Дата',
			create: 'Создать',
			save: 'Сохранить',
			address: 'Адрес',
			addressList: 'Список адресов',
			phoneList: 'Список телефонов',
			update: 'Редактировать',
			edit: 'Изменить',
			add: 'Добавить',
			components: 'Компоненты',
			templates: 'Шаблоны',
			pages: 'Страницы',
			template: 'Шаблон',
			component: 'Компонент',
			page: 'Страница',
			taxonomy: 'Таксономия',
			taxonomies: 'Таксономии',
			langs: 'Языки',
			lang: 'Язык',
			user: 'Пользователь',
			users: 'Пользователи',
			categories: 'Категории',
			category: 'Категория',
			postCategories: 'Категории постов',
			postList: 'Список постов',
			post: 'Пост',
			posts: 'Посты',
			menu: 'Меню',
			forms: 'Формы',
			form: 'Форма',
			directory: 'Справочник',
			directories: 'Справочники',
			imgSizes: 'Размеры изображений',
			settings: 'Настройки',
			main: 'Основные',
			mail: 'Почта',
			createdDate: 'Дата создания',
			updatedDate: 'Дата обновления',
			media: 'Медиа',
			addFiles: 'Добвить файлы',
			action: 'Действие',
			removeComponentQuestion: 'Вы действительно хотите удалить компонент?',
			removeComponent: 'Удалить компонент',
			quantityPerPage: 'Количество на странице',
			fillFields: 'Заполните поля',
			savedComponent: 'Компонент сохранен успешно',
			saveSuccess: 'Успешно сохранено',
			removeSuccess: 'Успешно удалено',
			tryAgain: 'Ошибка попробуйте позже',
			createComponent: 'Создать компонент',
			directoryOptions: 'Опции Справочника',
			createOption: 'Создание опции',
			changeIcon: 'Выбрать другую иконку',
			published: 'Опубликован',
			defaultTemplate: 'Шаблон по умолчанию',
			notSelectedImage: 'Изображение не выбрано',
			selectImage: 'Выберите изображение',
			selectIcon: 'Выбрать иконку',
			selectFile: 'Выберите файл',
			selectRemoveFile: 'Выберите файл чтоб удалить',
			noValidJson: 'Не верный формат JSON',
			type: 'Тип',
			templateData: 'Данные шаблона',
			displaySettings: 'Настройка отображения',
			icon: 'Иконка',
			value: 'Значение',
			refresh: 'Обновить',
			status: 'Статус',
			phone: 'Телефон',
			new: 'Новая',
			viewed: 'Просмотренно',
			removed: 'Удалено',
			width: 'Ширина',
			height: 'Высота',
			remove: 'Удалить',
			code: 'Код',
			image: 'Изображение',
			saved: 'Сохранено',
			link: 'Ссылка',
			yes: 'Да',
			no: 'Нет',
			newWindow: 'Новое окно',
			publishedDate: 'Дата публикации',
			login: 'Войти',
			cancel: 'Отмена',
			added: 'Добавлено',
			removedFiles: 'Файлы удалены',
			mediathek: 'Медиатека',
			numberOfFiles: 'Количество файлов',
			removeSelected: 'Удалить отмеченные',
			removeSomeFilesQuestion:
				'Вы действительно хотите удалить выбранные файлы?',
			removeSomeFiles: 'Удалить файлы',
			removeOneFileQuestion: 'Вы действительно хотите удалить файл?',
			removeOneFile: 'Удалить файл',
			addFile: 'Добавить файл',
			addField: 'Добавить поле',
			requiredField: 'Обязательное поле',
			notMatch: 'Не совпадает',
			formatError: 'Ошибка формата',
			minLenght: 'Минимальное количество символов',
			confirmLogout: 'Вы действительно хотите выйти?',
			logout: 'Выход',
			filter: 'Фильтр',
			socs: 'Социальные сети',
			settingsData: 'Данные настроек',
			enterTitle: 'Введите заголовок',
			notAuthUser: 'Пользователь не авторизован',
			recommendedSize: 'Рекомендуемый размер',
			resolution: 'пикс',
			confirm: 'Подтвердить',
			message: 'Сообщение',
			back: 'Назад',
			bigPic: 'Большая картинка',
			smallPic: 'Маленькая картинка',
			text: 'Текст',
			linkType: 'Тип ссылки',
			externalRef: 'Внешняя ссылка',
			linkToPage: 'Ссылка на страницу',
			linkToPost: 'Ссылка на пост',
			linkToTaxonomy: 'Ссылка на таксономию',
			linkToCategory: 'Ссылка на категорию',
			newWindowQuestion: 'Открыть ссылку в новом окне?',
		},
		components: {
			updateComponent: 'Компонент изменен успешно',
			editComponent: 'Изменить компонент',
			componentData: 'Данные компонента',
		},
		menu: {
			updatedMenu: 'Меню обновлено',
			removedMenu: 'Меню удалено',
			removeLinkQuestion: 'Вы действительно хотите удалить эту ссылку?',
			removeLink: 'Удалить ссылку',
			createLink: 'Создать ссылку',
			editLink: 'Редактивароть ссылку',
			menuLinks: 'Ссылки меню',
			savedMenu: 'Меню сохранено успешно',
			removeMenu: 'Удалить меню',
			removeMenuQuestion: 'Вы действительно хотите удалить это меню?',
		},
		notFound: {
			backToMain: 'Вернуться на главную',
			pageNotFound: 'Страница не найдена',
		},
		directories: {
			createDirectory: 'Создать справочник',
			removedDirectory: 'Справочник удален',
			removeDirectoryQuestion: 'Вы действительно хотите удалить справочник?',
			removeDirectory: 'Удалить справочник',
			editDirectory: 'Редактировать справочник',
			createdOption: 'Опция справочника создана успешно',
			editOption: 'Изменить опцию справочника',
			removedOption: 'Опция справочника удалена',
			removeOptionQuestion:
				'Вы действительно хотите удалить опцию справочника?',
			removeOption: 'Удалить опцию справочника',
		},
		requests: {
			removedRequest: 'Заявка удалена',
			removeRequestQuestion: 'Вы действительно хотите удалить заявку?',
			removeRequest: 'Удалить заявку',
			requests: 'Заявки',
			removeFormQuestion: 'Вы действительно хотите удалить форму?',
			removeForm: 'Удалить форму',
			createForm: 'Создать форму',
			editForm: 'Редактировать форму',
		},
		seo: {
			keywords: 'Ключевые слова',
			socPictrure: 'Картинка для соц сетей',
		},
		templates: {
			savedTemplate: 'Шаблон сохранен успешно',
			titleTemplate: 'Заголовок шаблона',
			createTemplate: 'Создать шаблон',
			updateTemplate: 'Редактировать шаблон',
			removedTemplate: 'Шаблон удален',
			removeTemplate: 'Удалить шаблон',
			removeTemplateQuestion: 'Вы действительно хотите удалить шаблон?',
		},
		auth: {
			authSuccess: 'Авторизация прошла успешно',
			changedPassword: 'Пароль был изменен',
			resetPassword: 'Сбросить пароль',
			enterMain: 'Введите почту',
			enterCode: 'Введите код',
			repeatPassword: 'Повторите пароль',
			enterNewPassword: 'Введите новый пароль',
			password: 'Пароль',
			reset: 'Сбросить',
		},
		conditions: {
			filled: 'Заполнено',
			empty: 'Пусто',
			equal: 'Равно',
			notEqual: 'Не равно',
			expression: 'Выражение',
		},
		statuses: {
			error: 'Ошибка',
			success: 'Успешно',
			active: 'Активный',
			notActive: 'Не активный',
		},
		pages: {
			createPage: 'Создать страницу',
			parentPage: 'Родительская страница',
			pathPage: 'Путь страницы',
			removedPage: 'Страница удалена',
			removePageQuestion: 'Вы действительно хотите удалить страницу?',
			removePage: 'Удалить страницу',
			updatePage: 'Редактировать страницу',
		},
		posts: {
			savedCategory: 'Категория сохранена',
			createCategory: 'Создать категорию',
			updateCategory: 'Редактировать категорию',
			mustSelectCategory: 'Для создания поста необходимо выбрать категорию',
			createPost: 'Создать пост',
			updatePost: 'Редактировать пост',
			removeCategory: 'Удалить категорию поста',
			removeCategoryQuestion: 'Вы действительно хотите удалить категорию поста',
			removedPost: 'Пост удален',
			removePostQuestion: 'Вы действительно хотите удалить пост?',
			removePost: 'Удалить пост',
		},
		taxonomies: {
			savedTaxonomy: 'Таксономия сохранена',
			updateTaxonomy: 'Редактировать таксономию',
			createTaxonomy: 'Создать таксономию',
			removeTaxonomy: 'Удалить таксономию',
			removeTaxonomyQuestion: 'Вы действительно хотите удалить таксономию?',
		},
		user: {
			savedUser: 'Пользователь сохранен',
			createUser: 'Создание пользователя',
			updateUser: 'Редактировать пользователя',
			userName: 'Имя пользователя',
			userRole: 'Роль',
			userStatus: 'Статус пользователя',
		},
		images: {
			imageName: 'Название изображения',
			savedSizes: 'Размеры сохранены',
			sizesError: 'Размеры уже существуют',
			sizes: 'Размеры изображений',
			removeSizeQuestion: 'Вы действительно хотите удалить размеры?',
			removeSize: 'Удаление размеров',
			addSize: 'Добавить размер',
			imageSize: 'Размер изображения',
		},
		langs: {
			savedLang: 'Язык сохранен',
			createLang: 'Создать язык',
			selectLangIcon: 'Выбрать иконку для языка',
			codeLang: 'Код языка',
			changeCurrentLang: 'Смените действующий язык чтоб удалить',
			removedLang: 'Язык удален',
			removeLang: 'Удалить язык',
			removeLangQuestion: 'Вы действительно хотите удалить язык?',
			updateLang: 'Редактировать язык',
			langs: 'Языки',
		},
	},
	en: {
		header: {
			su: 'Super user',
			admin: 'Administrator',
			user: 'User',
		},
		main: {
			title: 'Title',
			tagline: 'Tagline',
			name: 'Name',
			description: 'Description',
			date: 'Date',
			create: 'Create',
			save: 'Save',
			address: 'Address',
			addressList: 'List of addresses',
			phoneList: 'Phone List',
			update: 'Edit',
			edit: 'Edit',
			add: 'Add',
			components: 'Components',
			templates: 'Templates',
			pages: 'Pages',
			template: 'Template',
			component: 'Component',
			page: 'Page',
			taxonomy: 'Taxonomy',
			taxonomies: 'Taxonomies',
			langs: 'Languages',
			lang: 'Language',
			user: 'User',
			users: 'Users',
			categories: 'Categories',
			category: 'Category',
			postCategories: 'Post categories',
			postList: 'List of posts',
			post: 'Post',
			posts: 'Posts',
			menu: 'Menu',
			forms: 'Forms',
			form: 'Form',
			directory: 'Directory',
			directories: 'Directories',
			imgSizes: 'Image sizes',
			settings: 'Settings',
			main: 'Main',
			mail: 'Mail',
			createdDate: 'Created date',
			updatedDate: 'Updated date',
			media: 'Media',
			addFiles: 'Add files',
			action: 'Action',
			removeComponentQuestion: 'Are you sure you want to remove the component?',
			removeComponent: 'Remove component',
			quantityPerPage: 'Quantity per page',
			fillFields: 'Fill in the fields',
			savedComponent: 'Component saved successfully',
			saveSuccess: 'Saved successfully',
			removeSuccess: 'Removed successfully',
			tryAgain: 'Please try again later',
			createComponent: 'Create component',
			directoryOptions: 'Directory options',
			createOption: 'Create an option',
			changeIcon: 'Choose another icon',
			published: 'Published',
			defaultTemplate: 'Default template',
			notSelectedImage: 'Image not selected',
			selectImage: 'Select an image',
			selectIcon: 'Select an icon',
			selectFile: 'Select a file',
			selectRemoveFile: 'Select file to delete',
			noValidJson: 'Invalid JSON format',
			type: 'Type',
			templateData: 'Template data',
			displaySettings: 'Display setting',
			icon: 'Icon',
			value: 'Value',
			refresh: 'Refresh',
			status: 'Status',
			phone: 'Phone',
			new: 'New',
			viewed: 'Viewed',
			removed: 'Removed',
			width: 'Width',
			height: 'Height',
			remove: 'Delete',
			code: 'Code',
			image: 'Image',
			saved: 'Saved',
			link: 'Link',
			yes: 'Yes',
			no: 'No',
			newWindow: 'New window',
			publishedDate: 'Date of publication',
			login: 'Login',
			cancel: 'Cancel',
			added: 'Added',
			removedFiles: 'Files deleted',
			mediathek: 'Mediathek',
			numberOfFiles: 'Number of files',
			removeSelected: 'Delete marked',
			removeSomeFilesQuestion:
				'Are you sure you want to delete the selected files?',
			removeSomeFiles: 'Delete files',
			removeOneFileQuestion: 'Are you sure you want to delete the file?',
			removeOneFile: 'Delete file',
			addFile: 'Add file',
			addField: 'Add field',
			requiredField: 'Required field',
			notMatch: 'Does not match',
			formatError: 'Format error',
			minLenght: 'Minimum number of characters',
			confirmLogout: 'Do you really want to leave?',
			logout: 'Exit',
			filter: 'Filter',
			socs: 'Social networks',
			settingsData: 'Setting data',
			enterTitle: 'Enter title',
			notAuthUser: 'User is not logged in',
			recommendedSize: 'Recommended size',
			resolution: 'px',
			confirm: 'Confirm',
			message: 'Message',
			back: 'Back',
			bigPic: 'Big image',
			smallPic: 'Small image',
			text: 'Text',
			linkType: 'Link type',
			externalRef: 'External reference',
			linkToPage: 'Link to the page',
			linkToPost: 'Link to post',
			linkToTaxonomy: 'Link to taxonomy',
			linkToCategory: 'Link to category',
			newWindowQuestion: 'Open a link in a new window?',
		},
		components: {
			updateComponent: 'Component changed successfully',
			editComponent: 'Change component',
			componentData: 'Component data',
		},
		menu: {
			updatedMenu: 'Menu updated',
			removedMenu: 'Menu removed',
			removeLinkQuestion: 'Are you sure you want to remove this link?',
			removeLink: 'Remove link',
			createLink: 'Create link',
			editLink: 'Edit link',
			menuLinks: 'Menu links',
			savedMenu: 'Menu saved successfully',
			removeMenu: 'Delete menu',
			removeMenuQuestion: 'Are you sure you want to delete this menu?',
		},
		notFound: {
			backToMain: 'Go back to the main page',
			pageNotFound: 'Page not found',
		},
		directories: {
			createDirectory: 'Create a directory',
			removedDirectory: 'Directory deleted',
			removeDirectoryQuestion: 'Are you sure you want to delete the directory?',
			removeDirectory: 'Delete directory',
			editDirectory: 'Edit directory',
			createdOption: 'Directory option created successfully',
			editOption: 'Edit directory option',
			removedOption: 'Directory option removed',
			removeOptionQuestion:
				'Are you sure you want to remove the directory option?',
			removeOption: 'Remove directory option',
		},
		requests: {
			removedRequest: 'Application deleted',
			removeRequestQuestion: 'Are you sure you want to delete the application?',
			removeRequest: 'Delete application',
			requests: 'Applications',
			removeFormQuestion: 'Are you sure you want to delete the form?',
			removeForm: 'Delete form',
			createForm: 'Create a form',
			editForm: 'Edit form',
		},
		seo: {
			keywords: 'Keywords',
			socPictrure: 'Picture for social networks',
		},
		templates: {
			savedTemplate: 'Template saved successfully',
			titleTemplate: 'Template header',
			createTemplate: 'Create template',
			updateTemplate: 'Edit Template',
			removedTemplate: 'Template deleted',
			removeTemplate: 'Delete template',
			removeTemplateQuestion: 'Are you sure you want to delete the template?',
		},
		auth: {
			authSuccess: 'Authorization was successful',
			changedPassword: 'Password has been changed',
			resetPassword: 'Reset the password',
			enterMain: 'Enter mail',
			enterCode: 'Enter code',
			repeatPassword: 'Repeat password',
			enterNewPassword: 'Enter a new password',
			password: 'Password',
			reset: 'Reset',
		},
		conditions: {
			filled: 'Filled',
			empty: 'Empty',
			equal: 'Equal',
			notEqual: 'Not equal',
			expression: 'Expression',
		},
		statuses: {
			error: 'Error',
			success: 'Success',
			active: 'Active',
			notActive: 'Not active',
		},
		pages: {
			createPage: 'Create a page',
			parentPage: 'Parent page',
			pathPage: 'Page path',
			removedPage: 'Page deleted',
			removePageQuestion: 'Are you sure you want to delete the page?',
			removePage: 'Delete page',
			updatePage: 'Edit page',
		},
		posts: {
			savedCategory: 'Category saved',
			createCategory: 'Create a category',
			updateCategory: 'Edit category',
			mustSelectCategory: 'To create a post, you must select a category',
			createPost: 'Create post',
			updatePost: 'Edit post',
			removeCategory: 'Remove post category',
			removeCategoryQuestion:
				'Are you sure you want to delete the post category?',
			removedPost: 'Post deleted',
			removePostQuestion: 'Are you sure you want to delete the post?',
			removePost: 'Delete post',
		},
		taxonomies: {
			savedTaxonomy: 'Taxonomy saved',
			updateTaxonomy: 'Edit taxonomy',
			createTaxonomy: 'Create taxonomy',
			removeTaxonomy: 'Remove taxonomy',
			removeTaxonomyQuestion: 'Are you sure you want to remove the taxonomy?',
		},
		user: {
			savedUser: 'User saved',
			createUser: 'Create user',
			updateUser: 'Edit user',
			userName: 'Username',
			userRole: 'Role',
			userStatus: 'User status',
		},
		images: {
			imageName: 'Image title',
			savedSizes: 'Dimensions saved',
			sizesError: 'Dimensions already exist',
			sizes: 'Image sizes',
			removeSizeQuestion: 'Are you sure you want to delete dimensions?',
			removeSize: 'Deleting Dimensions',
			addSize: 'Add size',
			imageSize: 'Image size',
		},
		langs: {
			savedLang: 'Language saved',
			createLang: 'Create language',
			selectLangIcon: 'Choose an icon for the language',
			codeLang: 'Language code',
			changeCurrentLang: 'Change the current language to delete',
			removedLang: 'Language removed',
			removeLang: 'Remove language',
			removeLangQuestion: 'Are you sure you want to delete the language?',
			updateLang: 'Edit language',
			langs: 'Languages',
		},
	},
	uz: {
		header: {
			su: 'Super foydalanuvchi',
			admin: 'Administrator',
			user: 'Foydalanuvchi',
		},
		main: {
			title: 'Sarlavha',
			tagline: 'Tagline',
			name: 'Ism',
			description: 'Tavsif',
			date: 'Sana',
			create: 'Yaratmoq',
			save: 'Saqlash',
			address: 'Manzil',
			addressList: "Manzil ro'yxati",
			phoneList: "Telefonlar ro'yxati",
			update: 'Tahrir qilish',
			edit: "O'zgartirish",
			add: "Qo'shish",
			components: 'Komponentlar',
			templates: 'Shablonlar',
			pages: 'Sahifalar',
			template: 'Andoza',
			component: 'Komponent',
			page: 'Sahifa',
			taxonomy: 'Taksonomiya',
			taxonomies: 'taksonomiyalar',
			langs: 'Tillar',
			lang: 'Til',
			user: 'Foydalanuvchi',
			users: 'Foydalanuvchilar',
			categories: 'Kategoriyalar',
			category: 'Turkum',
			postCategories: 'Post toifalari',
			postList: "Xabarlar ro'yxati",
			post: 'Xabar',
			posts: 'Xabarlar',
			menu: 'Menyu',
			forms: 'Shakllar',
			form: 'Shakl',
			directory: 'Katalog',
			directories: 'Kataloglar',
			imgSizes: "Rasm o'lchamlari",
			settings: 'Sozlamalar',
			main: 'Asosiy',
			mail: 'Pochta',
			createdDate: 'Yaratilgan sana',
			updatedDate: 'Yangilangan sana',
			media: 'Media',
			addFiles: "Fayllarni qo'shish",
			action: 'Harakat',
			removeComponentQuestion:
				'Haqiqatan ham komponentni olib tashlamoqchimisiz?',
			removeComponent: 'Komponentni olib tashlang',
			quantityPerPage: 'Har bir sahifa miqdori',
			fillFields: "Maydonlarni to'ldiring",
			savedComponent: 'Komponent muvaffaqiyatli saqlandi',
			saveSuccess: 'Muvaffaqiyatli saqlandi',
			removeSuccess: 'Muvaffaqiyatli o‘chirib tashlandi',
			tryAgain: "Iltimos keyinroq qayta urinib ko'ring",
			createComponent: 'Komponent yaratish',
			directoryOptions: 'Katalog variantlari',
			createOption: 'Variant yarating',
			changeIcon: 'Boshqa belgini tanlang',
			published: 'Nashr qilingan',
			defaultTemplate: 'Standart shablon',
			notSelectedImage: 'Rasm tanlanmagan',
			selectImage: 'Rasmni tanlang',
			selectIcon: 'Belgini tanlang',
			selectFile: 'Faylni tanlang',
			selectRemoveFile: "O'chirish uchun faylni tanlang",
			noValidJson: "JSON formati noto'g'ri",
			type: 'Turi',
			templateData: "Andoza ma'lumotlari",
			displaySettings: 'Displeyni sozlash',
			icon: 'Belgi',
			value: "Ma'nosi",
			refresh: 'Yangilang',
			status: 'Holat',
			phone: 'Telefon',
			new: 'Yangi',
			viewed: "Ko'rilgan",
			removed: 'Olib tashlandi',
			width: 'Kenglik',
			height: 'Balandlik',
			remove: "O'chirish",
			code: 'Kod',
			image: 'Rasm',
			saved: 'Saqlagan',
			link: 'Havola',
			yes: 'Ha',
			no: "Yo'q",
			newWindow: 'Yangi oyna',
			publishedDate: 'Nashr qilingan sana',
			login: 'Kirish',
			cancel: 'Bekor qilish',
			added: "Qo'shilgan",
			removedFiles: "Fayllar o'chirildi",
			mediathek: 'Mediatek',
			numberOfFiles: 'Fayllar soni',
			removeSelected: "Belgilangan o'chirish",
			removeSomeFilesQuestion:
				"Haqiqatan ham tanlangan fayllarni o'chirmoqchimisiz?",
			removeSomeFiles: "Fayllarni o'chirish",
			removeOneFileQuestion: 'Haqiqatan ham faylni o‘chirib tashlamoqchimisiz?',
			removeOneFile: "Faylni o'chirish",
			addFile: "Fayl qo'shish",
			addField: "Maydon qo'shing",
			requiredField: 'Majburiy maydon',
			notMatch: 'Mos kelmaydi',
			formatError: 'Format xatosi',
			minLenght: 'Belgilarning minimal soni',
			confirmLogout: 'Haqiqatan ham ketishni xohlaysizmi?',
			logout: 'Chiqish',
			filter: 'Filtrlash',
			socs: 'Ijtimoiy tarmoqlar',
			settingsData: "Ma'lumotlarni sozlash",
			enterTitle: 'Sarlavha kiriting',
			notAuthUser: 'Foydalanuvchi tizimga kirmagan',
			recommendedSize: "Tavsiya etilgan o'lcham",
			resolution: 'piks',
			confirm: 'Tasdiqlash',
			message: 'Xabar',
			back: 'Orqaga',
			bigPic: 'Katta rasm',
			smallPic: 'Kichik rasm',
			text: 'Matn',
			linkType: 'Havola turi',
			externalRef: 'Tashqi havola',
			linkToPage: 'Sahifaga havola',
			linkToPost: 'Postga havola',
			linkToTaxonomy: 'Taksonomiyaga havola',
			linkToCategory: 'Kategoriyaga havola',
			newWindowQuestion: 'Havola yangi oynada ochilsinmi?',
		},
		components: {
			updateComponent: "Komponent muvaffaqiyatli o'zgartirildi",
			editComponent: "Komponentni o'zgartirish",
			componentData: "Komponent ma'lumotlari",
		},
		menu: {
			updatedMenu: 'Menyu yangilandi',
			removedMenu: "Menyu o'chirildi",
			removeLinkQuestion: 'Bu havolani olib tashlamoqchimisiz?',
			removeLink: "Havolani o'chirish",
			createLink: 'Havola yaratish',
			editLink: 'Havolani tahrirlash',
			menuLinks: 'Menyu havolalari',
			savedMenu: 'Menyu saqlandi',
			removeMenu: "Menyuni o'chirish",
			removeMenuQuestion:
				'Haqiqatan ham bu menyuni o‘chirib tashlamoqchimisiz?',
		},
		notFound: {
			backToMain: 'Asosiy sahifaga qaytish',
			pageNotFound: 'Sahifa topilmadi',
		},
		directories: {
			createDirectory: 'Katalog yaratish',
			removedDirectory: "Katalog o'chirildi",
			removeDirectoryQuestion: "Haqiqatan ham katalogni o'chirmoqchimisiz?",
			removeDirectory: "Katalogni o'chirish",
			editDirectory: "Katalogni o'zgartirish",
			createdOption: 'Katalog opsiyasi muvaffaqiyatli yaratildi',
			editOption: 'Katalogni tahrirlash opsiyasi',
			removedOption: "Katalog opsiyasi o'chirildi",
			removeOptionQuestion:
				'Haqiqatan ham katalog variantini olib tashlamoqchimisiz?',
			removeOption: "Katalogni o'chirish",
		},
		requests: {
			removedRequest: "Ilova o'chirildi",
			removeRequestQuestion:
				'Haqiqatan ham ilovani o‘chirib tashlamoqchimisiz?',
			removeRequest: "Ilovani o'chirish",
			requests: 'Ilovalar',
			removeFormQuestion: "Haqiqatan ham shaklni o'chirmoqchimisiz?",
			removeForm: "Shaklni o'chirish",
			createForm: 'Shakl yarating',
			editForm: 'Shaklni tahrirlash',
		},
		seo: {
			keywords: "Kalit so'zlar",
			socPictrure: 'Har bir sahifadagi miqdor',
		},
		templates: {
			savedTemplate: 'Andoza muvaffaqiyatli saqlandi',
			titleTemplate: 'Shablon sarlavhasi',
			createTemplate: 'Shablon yaratish',
			updateTemplate: 'Shablonni tahrirlash',
			removedTemplate: "Andoza o'chirildi",
			removeTemplate: "Shablonni o'chirish",
			removeTemplateQuestion: "Haqiqatan ham shablonni o'chirmoqchimisiz?",
		},
		auth: {
			authSuccess: "Avtorizatsiya muvaffaqiyatli o'tdi",
			changedPassword: "Parol o'zgartirildi",
			resetPassword: "Parolni qayta o'rnating",
			enterMain: 'Pochta kiriting',
			enterCode: 'Kodni kiriting',
			repeatPassword: 'Parolni qayta kiriting',
			enterNewPassword: 'Yangi parolni kiriting',
			password: 'Parol',
			reset: "Qayta o'rnatish",
		},
		conditions: {
			filled: "To'ldirildi",
			empty: "Bo'sh",
			equal: 'Teng',
			notEqual: 'Teng emas',
			expression: 'Ifoda',
		},
		statuses: {
			error: 'Xato',
			success: 'Muvaffaqiyatli',
			active: 'Faol',
			notActive: 'Faol emas',
		},
		pages: {
			createPage: 'Sahifa yarating',
			parentPage: 'Ota -ona sahifasi',
			pagePath: "Sahifa yo'li",
			removedPage: 'Page deleted',
			removePageQuestion: "Haqiqatan ham sahifani o'chirmoqchimisiz?",
			removePage: "Sahifani o'chirish",
			updatePage: 'Sahifani tahrirlash',
		},
		posts: {
			savedCategory: 'Kategoriya saqlandi',
			createCategory: 'Kategoriya yarating',
			updateCategory: 'Kategoriyani tahrirlash',
			mustSelectCategory: 'Xabar yaratish uchun siz toifani tanlashingiz kerak',
			createPost: 'Xabar yaratish',
			updatePost: 'Xabarni tahrirlash',
			removeCategory: 'Xabar turkumini olib tashlash',
			removeCategoryQuestion:
				"Haqiqatan ham postlar toifasini o'chirmoqchimisiz?",
			removedPost: "Xabar o'chirildi",
			removePostQuestion: 'Haqiqatan ham postni o‘chirmoqchimisiz?',
			removePost: "Xabarni o'chirish",
		},
		taxonomies: {
			savedTaxonomy: 'Taksonomiya saqlandi',
			updateTaxonomy: 'Taksonomiyani tahrirlash',
			createTaxonomy: 'Taksonomiyani yarating',
			removeTaxonomy: 'Taksonomiyani olib tashlang',
			removeTaxonomyQuestion:
				'Haqiqatan ham taksonomiyani olib tashlamoqchimisiz?',
		},
		user: {
			savedUser: 'Foydalanuvchi saqlandi',
			createUser: 'Foydalanuvchi yaratish',
			updateUser: 'Foydalanuvchini tahrirlash',
			userName: 'Foydalanuvchi nomi',
			userRole: 'Rol',
			userStatus: 'Foydalanuvchi holati',
		},
		images: {
			imageName: 'Rasm sarlavhasi',
			savedSizes: "O'lchamlar saqlandi",
			sizesError: "O'lchovlar allaqachon mavjud",
			sizes: "Rasm o'lchamlari",
			removeSizeQuestion: "Haqiqatan ham o'lchamlarni olib tashlamoqchimisiz?",
			removeSize: "O'lchamlarni o'chirish",
			addSize: "O'lcham qo'shing",
			imageSize: 'Rasm hajmi',
		},
		langs: {
			savedLang: 'Til saqlandi',
			createLang: 'Til yaratish',
			selectLangIcon: 'Til uchun belgini tanlang',
			codeLang: 'Til kodi',
			changeCurrentLang: "O'chirish uchun joriy tilni o'zgartiring",
			removedLang: 'Til olib tashlandi',
			removeLang: 'Tilni olib tashlash',
			removeLangQuestion: 'Haqiqatan ham tilni o‘chirmoqchimisiz?',
			updateLang: 'Tilni tahrirlash',
			langs: 'Tillar',
		},
	},
};

export default data;
