import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MenuPage } from 'pages/MenuPage/MenuPage';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import { UpdatePage } from 'pages/UpdatePage/UpdatePage';
import { PagesPage } from 'pages/PagesPage/PagesPage';
import { PostCategoriesPage } from 'pages/PostCategoriesPage/PostCategoriesPage';
import { CreatePostPage } from 'pages/CreatePostPage/CreatePostPage';
import { PostsPage } from 'pages/PostsPage/PostsPage';
import { SeoPage } from 'pages/SeoPage/SeoPage';
import { UsersPage } from 'pages/UsersPage/UsersPage';
import { MenuItemPage } from 'pages/MenuItemPage/MenuItemPage';
import { CreatePage } from 'pages/CreatePage/CreatePage';
import { TemplatesPage } from 'pages/TemplatesPage/TemplatesPage';
import { CreateTemplatePage } from 'pages/CreateTemplatePage/CreateTemplatePage';
import { MediaPage } from 'pages/MediaPage/MediaPage';
import { ShopPage } from 'pages/ShopPage/ShopPage';
import { DictsPage } from 'pages/DictsPage/DictsPage';
import { DictPage } from 'pages/DictPage/DictPage';
import { UpdatePostPage } from 'pages/UpdatePostPage/UpdatePostPage';
import { CreateUserPage } from 'pages/CreateUserPage/CreateUserPage';
import { UpdateUserPage } from 'pages/UpdateUserPage/UpdateUserPage';
import { UpdateTemplatePage } from 'pages/UpdateTemplatePage/UpdateTemplatePage';
import { CreateComponentPage } from 'pages/CreateComponentPage/CreateComponentPage';
import { ComponentsPage } from 'pages/ComponentsPage/ComponentsPage';
import { UpdateComponentPage } from 'pages/UpdateComponentPage/UpdateComponentPage';
import { ImgSizesPage } from 'pages/ImgSizesPage/ImgSizesPage';
import { LangsPage } from 'pages/LangsPage/LangsPage';
import { CreateLangPage } from 'pages/CreateLangPage/CreateLangPage';
import { UpdateLangPage } from 'pages/UpdateLangPage/UpdateLangPage';
import { FormsPage } from 'pages/FormsPage/FormsPage';
import { CreatePostCategoryPage } from 'pages/CreatePostCategoryPage/CreatePostCategoryPage';
import { FormPage } from 'pages/FormPage/FormPage';
import { UpdatePostCategoryPage } from 'pages/UpdatePostCategoryPage/UpdatePostCategoryPage';
// import { CreatePostByCategoryIdPage } from 'pages/CreatePostByCategoryIdPage/CreatePostByCategoryIdPage';
// import { UpdatePostByCategoryIdPage } from 'pages/UpdatePostByCategoryIdPage/UpdatePostByCategoryIdPage';
import { TaxonomiesPage } from 'pages/TaxonomiesPage/TaxonomiesPage';
import { CreateTaxonomyPage } from 'pages/CreateTaxonomyPage/CreateTaxonomyPage';
import { UpdateTaxonomyPage } from 'pages/UpdateTaxonomyPage/UpdateTaxonomyPage';
import { CreateDictOptionPage } from 'pages/CreateDictOptionPage/CreateDictOptionPage';
import { UpdateDictOptionPage } from 'pages/UpdateDictOptionPage/UpdateDictOptionPage';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { FormItemPage } from 'pages/FormItemPage/FormItemPage';
import { HomePage } from 'pages/HomePage/HomePage';

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route component={HomePage} path='/' exact />
			<Route component={PagesPage} path='/pages' exact />
			<Route component={CreatePage} path='/pages/create' exact />
			<Route component={UpdatePage} path='/pages/:pageId' exact />
			<Route component={TemplatesPage} path='/templates' exact />
			<Route component={CreateTemplatePage} path='/templates/create' exact />
			<Route
				component={UpdateTemplatePage}
				path='/templates/:templateId'
				exact
			/>
			<Route component={MenuPage} path='/menu' exact />
			<Route component={SettingsPage} path='/settings' exact />
			<Route component={ImgSizesPage} path='/img-sizes' exact />
			<Route component={ComponentsPage} path='/components' exact />
			<Route component={CreateComponentPage} path='/components/create' exact />
			<Route
				component={UpdateComponentPage}
				path='/components/:componentId'
				exact
			/>
			<Route component={ShopPage} path='/shop' exact />
			<Route component={DictsPage} path='/dicts' exact />
			<Route component={DictPage} path='/dicts/:dictId/options' exact />
			<Route
				component={CreateDictOptionPage}
				path='/dicts/:dictId/options/create'
				exact
			/>
			<Route
				component={UpdateDictOptionPage}
				path='/dicts/:dictId/options/:dictOptionId'
				exact
			/>
			<Route component={SeoPage} path='/seo' exact />
			<Route component={UsersPage} path='/users' exact />
			<Route component={CreateUserPage} path='/users/create' exact />
			<Route component={UpdateUserPage} path='/users/:id' exact />
			<Route component={MediaPage} path='/media' exact />
			<Route component={MenuItemPage} path='/menu/:menuId' exact />
			<Route component={LangsPage} path='/languages' exact />
			<Route component={CreateLangPage} path='/languages/create' exact />
			<Route component={UpdateLangPage} path='/languages/:id' exact />
			<Route component={FormsPage} path='/forms' exact />
			<Route component={FormPage} path='/forms/:formId' exact />
			<Route
				component={FormItemPage}
				path='/forms/:formId/requests/:reqId'
				exact
			/>
			<Route component={TaxonomiesPage} path='/taxonomies' exact />
			<Route component={CreateTaxonomyPage} path='/taxonomies/create' exact />
			<Route
				component={UpdateTaxonomyPage}
				path='/taxonomies/:taxonomyId'
				exact
			/>
			<Route
				component={PostCategoriesPage}
				path='/taxonomies/:taxonomyId/categories'
				exact
			/>
			<Route
				component={CreatePostCategoryPage}
				path='/taxonomies/:taxonomyId/categories/create'
				exact
			/>
			<Route
				component={UpdatePostCategoryPage}
				path='/taxonomies/:taxonomyId/categories/:categoryId'
				exact
			/>
			<Route
				component={PostsPage}
				path='/taxonomies/:taxonomyId/categories/:categoryId/posts'
				exact
			/>
			<Route
				component={CreatePostPage}
				path='/taxonomies/:taxonomyId/categories/:categoryId/posts/create'
				exact
			/>
			<Route
				component={UpdatePostPage}
				path='/taxonomies/:taxonomyId/categories/:categoryId/posts/:postId'
				exact
			/>
			<Route component={PostsPage} path='/taxonomies/:taxonomyId/posts' exact />
			<Route
				component={CreatePostPage}
				path='/taxonomies/:taxonomyId/posts/create'
				exact
			/>
			<Route
				component={UpdatePostPage}
				path='/taxonomies/:taxonomyId/posts/:postId'
				exact
			/>
			<Route component={NotFoundPage} path='/*' exact />
		</Switch>
	);
};

export default Routes;
