import instance from 'api/instance';
import qs from 'query-string';
import {
	IDeleteFormRequest,
	IGetFormRequestById,
	IGetFormRequests,
} from './types';

export const getFormRequests: IGetFormRequests = (params) =>
	instance.get(`form-requests?${qs.stringify(params)}`);

export const deleteFormRequest: IDeleteFormRequest = (id) =>
	instance.delete(`form-requests/${id}`);

export const getFormRequestById: IGetFormRequestById = (id, params) =>
	instance.get(`/form-requests/${id}?${qs.stringify(params)}`);
