import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IResComponent } from 'api/services/components/types';
import { componentsServices } from 'api/services';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
	TableBody,
	Table,
	TableCell,
	TablePagination,
	TableRow,
	TableHead,
} from '@material-ui/core';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import EditIcon from '@material-ui/icons/Edit';
import Confirmation from 'shared/Confirmation/Confirmation';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export const ComponentsPage: React.FC = () => {
	const [data, setData] = useState<IResComponent[]>();
	const [page, setPage] = useState(0);
	const [take, setTake] = useState(50);
	const [totalCount, setTotalCount] = useState(0);
	const ref = useRef<any>();
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState<number>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const {
		title,
		name,
		action,
		quantityPerPage,
		removeComponentQuestion,
		removeComponent,
		components,
	} = i18n.main;

	useEffect(() => {
		getData();
	}, [page, take, currentLang?.code]);

	const getData = async () => {
		const params = {
			take,
			skip: page * take,
		};
		const res = await componentsServices
			.getComponents(params)
			.then((res) => res.data);
		setData(res.data);
		setTotalCount(res.count);
	};

	const openModal = (id: number) => {
		setId(id);
		ref.current.onShow();
	};

	const clearState = () => {
		setId(undefined);
	};

	const deleteComponent = async () => {
		if (!id) return;
		try {
			setLoading(true);
			await componentsServices.deleteComponent(id);
			getData();
			clearState();
			ref.current.onHide();
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={components} url='/components/create' hideBtnByRole>
			<div className='components-page'>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{title}</TableCell>
							<TableCell>{name}</TableCell>
							<TableCell className='table-last-item'>{action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell className='table-last-item'>
									<Link
										to={`/components/${i.id}`}
										className='table-icon table-icon--black'>
										<EditIcon />
									</Link>
									{user?.role === 'SU' && (
										<DeleteForeverIcon
											color='secondary'
											onClick={() => openModal(i.id)}
											className='table-icon'
										/>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={quantityPerPage}
					count={totalCount}
					page={page}
					component='div'
					onPageChange={(e, newPage) => setPage(newPage)}
					rowsPerPageOptions={[20, 30, 50]}
					rowsPerPage={take}
					onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
				/>
			</div>
			<Confirmation
				ref={ref}
				text={removeComponentQuestion}
				submit={deleteComponent}
				title={removeComponent}
				load={loading}
				changeState={() => clearState()}
			/>
		</MainTemplate>
	);
};
