import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { useHistory, useParams } from 'react-router-dom';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import { IResUser, IUser } from 'api/services/user/types';
import { userServices } from 'api/services';
import Form from 'shared/Form/Form';
import { updateFields, view } from 'pages/CreateUserPage/formData';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

import './UpdateUserPage.sass';

export const UpdateUserPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useState<IResUser>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, user } = i18n;
	const history = useHistory();
	const formRef = useRef<any>();

	useEffect(() => {
		getUserData();
	}, [currentLang?.code]);

	const updateUser = async (e: React.FormEvent) => {
		e.preventDefault();

		const formRes = formRef.current?.submit();
		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFields);
			return;
		}

		setLoading(true);
		const data: IUser = {
			name: formRes.res.name,
			email: formRes.res.email,
			...(formRes.res.password ? { password: formRes.res.password } : {}),
			role: formRes.res.role,
			isActive: formRes.res.isActive,
		};
		try {
			await userServices.updateUser(id, data);
			handleNotification(user.savedUser, 'success');
			history.push('/users');
		} catch (e: any) {
			handleNotification(e?.resposne?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	const getUserData = async () => {
		const res = await userServices.getUserById(id).then((res) => res.data);
		setCurrentUser(res);
	};

	return (
		<MainTemplate
			title={`${user.updateUser} - ${currentUser?.email ?? ''}`}
			hideBtn>
			<form>
				<Form
					ref={formRef}
					fields={updateFields}
					view={view}
					initialData={currentUser}
				/>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					onClick={updateUser}
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						user.updateUser
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
