import React, { useEffect, useState } from 'react';
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	Select,
	Typography,
	MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IResPostCategory } from 'api/services/postCategories/types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import './MainTemplate.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type FilterItems = IResPostCategory;

interface Props {
	link?: boolean;
	title: string;
	filter?: boolean;
	hideBtn?: boolean;
	url?: string;
	handler?(): void;
	filterItems?: FilterItems[];
	filterValue?: string | number;
	updateFilterValue?(value: number | undefined): void;
	filterSearch?(): void;
	hideBtnByRole?: boolean;
}

export const MainTemplate: React.FC<Props> = ({
	children,
	title,
	filter,
	hideBtn,
	url,
	filterItems,
	filterValue,
	updateFilterValue,
	filterSearch,
	handler,
	hideBtnByRole,
}) => {
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);
	const [offset, setOffset] = useState(0);
	const renderButton = () => {
		if ((user?.role !== 'SU' && hideBtnByRole) || hideBtn) {
			return null;
		} else {
			return (
				<Button
					variant='contained'
					component={url ? Link : 'button'}
					to={url}
					color='primary'
					className='main-template__button'
					size='large'
					{...(handler ? { onClick: handler } : {})}>
					<AddBoxIcon />
				</Button>
			);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeOffset);
		return () => window.removeEventListener('scroll', changeOffset);
	}, []);

	const changeOffset = () => {
		if (!window) return;
		setOffset(window.pageYOffset);
	};

	const toTop = () => {
		if (!document) return;
		const head = document.getElementById('header');
		head?.scrollIntoView({ block: 'start', behavior: 'smooth' });
	};

	return (
		<div className='main-template'>
			<div className='main-template__container'>
				<div className='main-template__top'>
					<Typography variant='h3'>{title}</Typography>
					{renderButton()}
				</div>
				{filter && updateFilterValue && (
					<Grid
						className='posts-page__container'
						container
						spacing={2}
						alignItems='flex-end'>
						<Grid item xs={4}>
							<FormControl>
								<InputLabel id='categories'>{main.categories}</InputLabel>
								<Select
									labelId='categories'
									label={main.categories}
									value={filterValue}
									onChange={(e) =>
										updateFilterValue(Number(e.target.value as string))
									}>
									{filterItems?.map((i) => {
										return (
											<MenuItem key={i.id} value={i.id}>
												{i.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<Button
								variant='contained'
								color='primary'
								size='large'
								onClick={() => (filterSearch ? filterSearch() : null)}>
								{main.filter}
							</Button>
						</Grid>
					</Grid>
				)}
			</div>
			<div className='main-template__page'>{children}</div>
			{offset > 300 && (
				<div className='to-top' onClick={toTop}>
					<KeyboardArrowUpIcon />
				</div>
			)}
		</div>
	);
};
