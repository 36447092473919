import { AppState } from '../../store';
import { ThunkAction } from 'redux-thunk';
import { v1 as uuidv1 } from 'uuid';
import { NotificationActionTypes, NotificationTypes } from './types';

export const showNotification =
	(
		title: string,
		notificationType?: string,
	): ThunkAction<void, AppState, unknown, NotificationActionTypes> =>
	(dispatch, getState) => {
		const id = uuidv1();
		dispatch({
			type: NotificationTypes.SHOW_NOTIFICATION,
			payload: {
				title: title,
				notificationType: notificationType,
				id: id,
			},
		});

		setTimeout(() => {
			if (id === getState().notificationReducer.id) {
				dispatch(hideNotification());
			}
		}, 3000);
	};

export const hideNotification = () => ({
	type: NotificationTypes.HIDE_NOTIFICATION,
});
