import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { menuServices } from 'api/services';
import { IResMenu } from 'api/services/menus/types';
import handleNotification from 'helpers/handleNotification';
import Confirmation from 'shared/Confirmation/Confirmation';
import CustomModal from 'shared/CustomModal/CustomModal';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './MenuPage.sass';
import { Link } from 'react-router-dom';

export const MenuPage: React.FC = () => {
	const [menus, setMenus] = useState<IResMenu[]>([]);
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [menuId, setMenuId] = useState<number | string>('');
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const confirmation = useRef<any>();
	const modal = useRef<any>();
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, menu } = i18n;
	const { user } = useSelector((state: AppState) => state.userReducer);

	useEffect(() => {
		getMenus();
	}, [currentLang?.code]);

	const getMenus = async () => {
		const res = await menuServices.getMenus().then((res) => res.data);
		setMenus(res);
	};

	const tempalteHandler = () => {
		modal.current.onShow();
	};

	const updateMenu = async (id: string | number) => {
		if (!id) return;
		setMenuId(id);
		try {
			const res = await menuServices
				.getMenuById(Number(id))
				.then((res) => res.data);
			setName(res.name);
			setTitle(res.title);
		} finally {
			modal.current.onShow();
		}
	};

	const saveMenu = async () => {
		if (!name || !title) {
			setHasError(true);
			handleNotification(main.fillFields);
			return;
		}
		setLoading(true);
		const menuData = {
			name,
			title,
		};
		try {
			menuId
				? await menuServices.updateMenu(menuId, menuData)
				: await menuServices.createMenu(menuData);
			handleNotification(menu.savedMenu, 'success');
			modal.current.onHide();
			getMenus();
		} catch (e: any) {
			handleNotification(e?.response.data.message || main.tryAgain);
		} finally {
			setHasError(false);
			setLoading(false);
		}
	};

	const clearField = () => {
		setName('');
		setTitle('');
		setMenuId('');
	};

	const deleteMenu = async () => {
		if (!menuId) return;
		await menuServices.deleteMenu(menuId);
		handleNotification(menu.menuRemoved, 'success');
		getMenus();
	};

	return (
		<>
			<MainTemplate title={main.menu} handler={tempalteHandler} hideBtnByRole>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{main.title}</TableCell>
							<TableCell>{main.name}</TableCell>
							<TableCell>{main.publishedDate}</TableCell>
							<TableCell className='table-last-item'>{main.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{menus?.map((i) => (
							<TableRow key={i.id}>
								<TableCell>{i.title}</TableCell>
								<TableCell>{i.name}</TableCell>
								<TableCell>
									{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
								</TableCell>
								<TableCell className='menus__actions table-last-item'>
									<Link
										to={`/menu/${i.id}`}
										className='table-icon table-icon--black'>
										<ArrowForwardIcon />
									</Link>
									<EditIcon
										onClick={() => updateMenu(i.id)}
										className='table-icon'
									/>
									{user?.role === 'SU' && (
										<DeleteForeverIcon
											onClick={() => {
												setMenuId(i.id);
												confirmation.current.onShow();
											}}
											className='table-icon'
											color='secondary'
										/>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</MainTemplate>
			<Confirmation
				ref={confirmation}
				text={menu.removeMenuQuestion}
				submit={deleteMenu}
				title={menu.removeMenu}
				changeState={clearField}
				load={loading}
			/>
			<CustomModal
				ref={modal}
				submit={saveMenu}
				title={main.menu}
				changeState={clearField}
				text={main.save}
				load={loading}>
				<TextField
					variant='outlined'
					label={main.title}
					className='custom-modal-item'
					value={title}
					autoFocus
					onChange={(e) => setTitle(e.target.value as string)}
					error={!title && hasError}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='custom-modal-item'
					value={name}
					onChange={(e) => setName(e.target.value as string)}
					error={!name && hasError}
				/>
			</CustomModal>
		</>
	);
};
