import React, { FormEvent, useState } from 'react';
import { Button } from '@material-ui/core';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import { settingsServices } from 'api/services';
import { useEffect } from 'react';
import Form from 'shared/Form/Form';
import './SettingsPage.sass';
import { useRef } from 'react';
import { UserRoles } from 'api/services/user/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import handleNotification from 'helpers/handleNotification';
import { InputAutoSize } from 'shared/InputAutoSize/InputAutoSize';
import { IForm, IFormView } from 'shared/Form/types';
import Loader from 'react-loader-spinner';
import { fields, view } from './formData/formData';
import { IResSettings } from 'api/services/settings/types';

export const SettingsPage: React.FC = () => {
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main } = i18n;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IAnyObject>();
	const [properties, setProperties] = useState<IForm>();
	const [settingsView, setSettingsView] = useState<IFormView>();
	const [propertiesValue, setPropertiesValue] = useState('');
	const [settingsViewValue, setSettingsViewValue] = useState('');
	const formRef = useRef<any>();

	useEffect(() => {
		getSettings();
	}, [currentLang?.code]);

	const getSettings = async () => {
		const res = await settingsServices.getSettings().then((res) => res.data);
		setPropertiesValue(JSON.stringify(getSeo(res).seoProp || ''));
		setSettingsViewValue(JSON.stringify(getSeo(res).seoView || ''));
		setSettingsView(res?.view);
		setProperties(res?.properties);
		setData(res?.data);
	};

	const getSeo = (response: IResSettings) => {
		return {
			seoProp: {
				...(response?.properties
					? {
							...fields,
							...response?.properties,
					  }
					: { ...fields }),
			},
			seoView: [
				...(response?.view
					? [
							...response?.view,
							...(response?.view.some((i) => i.title === 'SEO') ? [] : view),
					  ]
					: view),
			],
		};
	};

	const updateSettings = async (e: FormEvent) => {
		e.preventDefault();
		const formRes = formRef?.current?.submit();
		if (
			formRes &&
			(!formRes || (formRes?.hasErrors && user?.role !== UserRoles.SU))
		) {
			handleNotification(main.fillFields);
			return;
		}

		setLoading(true);

		const propVal = JSON.parse(propertiesValue || '{}');
		const viewVal = JSON.parse(settingsViewValue || '[]');
		const settingsData = {
			properties: propVal,
			view: viewVal,
			data: formRes?.res ?? {},
		};
		const data: IResSettings = {
			properties: getSeo(settingsData).seoProp,
			view: getSeo(settingsData).seoView,
			data: formRes?.res ?? {},
		};
		try {
			await settingsServices.updateSettings(data);
			handleNotification(main.saveSuccess, 'success');
			getSettings();
		} finally {
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={main.settings} hideBtn>
			<form onSubmit={updateSettings} className='settings-page'>
				<>
					{settingsView && properties && (
						<Form
							ref={formRef}
							view={settingsView}
							fields={properties}
							initialData={data}
						/>
					)}
					{user?.role === UserRoles.SU && (
						<>
							<InputAutoSize
								value={propertiesValue}
								placeholder={main.settingsData}
								updateValue={setPropertiesValue}
							/>
							<InputAutoSize
								value={settingsViewValue}
								placeholder={main.displaySettings}
								updateValue={setSettingsViewValue}
							/>
						</>
					)}
				</>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						main.save
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
