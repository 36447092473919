import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import { IUser } from 'api/services/user/types';
import { userServices } from 'api/services';

import './CreateUserPage.sass';
import Form from 'shared/Form/Form';
import { createFields, view } from './formData';
import { useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

export const CreateUserPage: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const { main, user } = useSelector(
		(state: AppState) => state.langReducer.i18n,
	);
	const formRef = useRef<any>();
	const history = useHistory();

	const createUser = async (e: React.FormEvent) => {
		e.preventDefault();

		const formRes = formRef.current?.submit();
		if (formRes && (!formRes || formRes?.hasErrors)) {
			handleNotification(main.fillFields);
			return;
		}

		setLoading(true);
		const data: IUser = {
			name: formRes.res.name,
			email: formRes.res.email,
			password: formRes.res.password,
			role: formRes.res.role,
			isActive: formRes.res.isActive,
		};

		try {
			await userServices.createUser(data);
			handleNotification(user.savedUser, 'success');
			history.push('/users');
		} catch (e: any) {
			handleNotification(e?.response?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={user.createUser} hideBtn>
			<form onSubmit={createUser}>
				<Form fields={createFields} view={view} ref={formRef} />
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						user.createUser
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
