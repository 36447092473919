import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useEffect,
} from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@material-ui/core';
import cn from 'classnames';
import Loader from 'react-loader-spinner';
import './CustomModal.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
	submit: () => void;
	title: string;
	text: string;
	load?: boolean;
	children: React.ReactNode;
	changeState?(): void;
	fileUploader?: boolean;
}

interface PublicFunctions {
	onShow(): void;
	onHide(): void;
}

const CustomModal: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
	props,
	ref,
) => {
	const {
		title,
		text,
		submit,
		load,
		changeState = () => {},
		fileUploader = false,
	} = props;
	const [visibility, setVisibility] = useState(false);
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);

	useImperativeHandle(ref, () => ({
		onShow,
		onHide,
	}));

	const onShow = () => {
		setVisibility(true);
	};

	const onHide = () => {
		setVisibility(false);
		changeState();
	};

	const exitByKey = (e: KeyboardEvent) => {
		if (e.keyCode === 27) {
			onHide();
		}
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		submit();
	};

	useEffect(() => {
		if (!fileUploader) {
			document.addEventListener('keydown', exitByKey);
		}
		return () => document.removeEventListener('keydown', exitByKey);
	}, []);

	if (!visibility) return null;

	return (
		<div
			className={cn('custom-modal', { 'custom-modal--static': fileUploader })}>
			<div className='custom-modal__blackout' onClick={onHide}></div>
			<form onSubmit={submitForm} className='custom-modal__window'>
				<CancelIcon onClick={onHide} className='custom-modal__exit' />
				<div className='custom-modal__title'>{title}</div>
				<div className='custom-modal__text'>{props.children}</div>
				<div className='custom-modal__btn-row'>
					<div className='custom-modal__btn'>
						<Button
							color='primary'
							variant='contained'
							type='submit'
							className={cn('', {
								'btn--hidden': load,
							})}
							fullWidth
							disabled={load}>
							{load ? (
								<Loader height={10} type='ThreeDots' color='#fff' />
							) : (
								text
							)}
						</Button>
					</div>
					<div className='custom-modal__btn'>
						<Button variant='contained' fullWidth onClick={onHide}>
							{main.cancel}
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default forwardRef(CustomModal);
