import { DEFAULT_LANGUAGE } from 'constants/project';
import { FormType, IForm, IFormView } from 'shared/Form/types';
import data from 'store/ducks/langs/data';

export const fields: IForm = {
	name: {
		type: FormType.TEXT,
		required: true,
		label: data[DEFAULT_LANGUAGE]?.main.name,
		extraKey: 'name',
		value: '',
	},
	title: {
		type: FormType.TEXT,
		required: true,
		label: data[DEFAULT_LANGUAGE]?.main.title,
		extraKey: 'title',
		value: '',
	},
	email: {
		type: FormType.TEXT,
		required: true,
		label: 'Email',
		extraKey: 'email',
		value: '',
		onSubmitRegexp:
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
};

export const view: IFormView = [
	{
		block: [['email'], ['name'], ['title']],
	},
];
