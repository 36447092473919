import React from 'react';
import { IFormDefaultProps, IFormTitle } from 'shared/Form/types';
import { Typography } from '@material-ui/core';
import { areEqual } from 'helpers/areEqual';

interface Props {
	data: IFormTitle;
	defaultProps: IFormDefaultProps;
}

export const FormTitleComp: React.FC<Props> = ({ data }) => {
	const { value } = data;
	return (
		<Typography variant='h5' className='form-title'>
			{value}
		</Typography>
	);
};

export const FormTitle = React.memo(FormTitleComp, areEqual);
