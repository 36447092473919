import { AxiosPromise } from 'axios';
import { IForm, IFormView } from 'shared/Form/types';
import { IResTaxonomy } from '../taxonomies/types';
import { IResComponent } from '../components/types';

export interface ITemplate {
  title: string;
  properties?: IForm;
  view?: IFormView;
  components?: number[];
  taxonomyIds?: number[];
  type: TemplateTypes;
  name: string;
}

export interface IGetTemplates {
  (query?: {
    taxonomyId?: number | string;
    type?: 'POST' | 'PAGE';
  }): AxiosPromise<IResTemplate[]>;
}

export interface ICreateTemplate {
  (data: ITemplate): AxiosPromise<IResTemplate[]>;
}

export interface IDeleteTemplate {
  (id: number): AxiosPromise;
}

export interface IUpdateTemplate {
  (id: number, data: ITemplate): AxiosPromise<IResTemplate>;
}

export interface IGetTemplateById {
  (id: number): AxiosPromise<IResTemplate>;
}

export interface IDeleteTemplate {
  (id: number): AxiosPromise<number>;
}

export interface IResTemplate
  extends Omit<ITemplate, 'components' | 'taxonomyIds'> {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  taxonomies?: IResTaxonomy[];
  components: IResComponent[];
}

export enum TemplateTypes {
  POST = 'POST',
  PAGE = 'PAGE',
}
