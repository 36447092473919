import { FormType, IForm, IFormRepeater, IFormText } from '../types';
import { getFieldStates } from './getFieldStates';
import store from 'store/store';
import _ from 'lodash';

export type validationPayload = {
	fields: IForm;
	res: IAnyObject;
	hasErrors: boolean;
};
const { main } = store.getState().langReducer.i18n;

export const validation = (fields: IForm): validationPayload => {
	const cloneFields = _.cloneDeep(fields);
	const res: IAnyObject = {};

	let hasErrors = false;
	Object.entries(cloneFields).forEach(([key, item]) => {
		const { disabled, hidden, required } = getFieldStates(fields, key);
		const { value, type, extraKey, onSubmitRegexp, equalRef } = item;
		_.set(cloneFields, `${key}.errorMsg`, '');
		if (!disabled && !hidden && required && !value) {
			hasErrors = true;
			_.set(cloneFields, `${key}.errorMsg`, main.requredField);
		}
		if (equalRef) {
			const refItem = _.get(cloneFields, equalRef);
			if (refItem && refItem.value !== value) {
				_.set(cloneFields, `${key}.errorMsg`, main.notMatch);
			}
		}
		switch (type) {
			case FormType.REPEATER:
				const repeaterRes = validateRepeater(
					item as IFormRepeater,
					fields,
					key,
				);
				cloneFields[key] = repeaterRes.repeater;
				if (extraKey) {
					_.set(res, extraKey, repeaterRes.res);
				}
				if (repeaterRes.hasErrors) {
					hasErrors = true;
				}
				break;
			case FormType.TEXT:
				const { value: textValue, minLength } = item as IFormText;
				const trimedTextValue = textValue ? textValue.trim() : '';
				if (
					trimedTextValue &&
					onSubmitRegexp &&
					!onSubmitRegexp.test(trimedTextValue as string)
				) {
					hasErrors = true;
					_.set(cloneFields, `${key}.errorMsg`, main.formatError);
				}
				if (trimedTextValue && minLength) {
					if ((trimedTextValue as string).length < minLength) {
						hasErrors = true;
						_.set(
							cloneFields,
							`${key}.errorMsg`,
							`${main.minLenght}: ${minLength}`,
						);
					}
				}
				if (!disabled && !hidden && required && !trimedTextValue.trim()) {
					hasErrors = true;
					_.set(cloneFields, `${key}.errorMsg`, main.requredField);
				}
				if (extraKey && !hidden && !disabled) {
					_.set(res, extraKey, trimedTextValue);
				}
				break;
			default:
				if (extraKey && !hidden && !disabled) {
					_.set(res, extraKey, value);
				}
		}
	});
	return {
		fields: cloneFields,
		res,
		hasErrors,
	};
};

const validateRepeater = (
	repeater: IFormRepeater,
	fields: IForm,
	parentName: string,
): {
	hasErrors: boolean;
	repeater: IFormRepeater;
	res: IAnyObject;
} => {
	let hasErrors = false;
	const res: IAnyObject[] = [];
	const clone = _.cloneDeep(repeater);

	clone.value.forEach((i, index) => {
		const resItem: IAnyObject = {};
		Object.entries(i).forEach(([key, item]) => {
			const name = `${parentName}.value.[${index}].${key}`;
			if (item.type === FormType.REPEATER) {
				const repeaterRes = validateRepeater(item, fields, name);
				i[key] = repeaterRes.repeater;
				if (item.extraKey) {
					_.set(resItem, item.extraKey, repeaterRes.res);
				}
				if (repeaterRes.hasErrors) {
					hasErrors = repeaterRes.hasErrors;
				}
			} else {
				const { value, extraKey } = item;
				const { disabled, hidden, required } = getFieldStates(fields, name);
				item.errorMsg = '';
				if (!disabled && !hidden && required && !value) {
					hasErrors = true;
					item.errorMsg = main.requredField;
				}
				if (extraKey) {
					_.set(resItem, extraKey, value);
				}
			}
		});
		res.push(resItem);
	});
	return { hasErrors, repeater: clone, res };
};

const buildConstructor = (data: IAnyObject): IAnyObject => {
	const res: IAnyObject = {};
	data?.fields.forEach((i: IAnyObject) => {
		res[i.name] = i;
		if (i.hiddenRef) {
			i.hiddenRef = i.hiddenRef.map((i: any) => {
				return i.and;
			});
		}
	});
	return res;
};
