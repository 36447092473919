import { history } from 'routes/history';
import { UserRoles } from 'api/services/user/types';

const redirect = (role: UserRoles) => {
	if (!role) return;
	if (role !== UserRoles.SU) {
		history.push('/');
		return;
	}
};

export default redirect;
