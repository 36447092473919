import React from "react";
import Load from "react-loader-spinner";
import cn from "classnames";
import "./Loader.sass";

interface Props {
	loading: boolean;
}

const Loader: React.FC<Props> = ({ loading, children }) => {
	return (
		<div className={cn("loader", { "loader--active": loading })}>
			<Load height={10} type='ThreeDots' color='#fff' />
			<div className='loader__text'>{children}</div>
		</div>
	);
};

export default Loader;
