import instance from 'api/instance';
import {
  ICreatePage,
  IDeletePage,
  IGetPageById,
  IGetPages,
  IUpdatePage,
  IChangePageChildren,
} from './types';
import qs from 'query-string';

export const getPages: IGetPages = (query = {}) =>
  instance.get(`pages?${qs.stringify(query)}`);

export const getPageById: IGetPageById = (id) => instance.get(`pages/${id}`);

export const createPage: ICreatePage = (data) => instance.post('pages', data);

export const updatePage: IUpdatePage = (id, data) =>
  instance.put(`pages/${id}`, data);

export const deletePage: IDeletePage = (id) => instance.delete(`pages/${id}`);

export const changePageChildren: IChangePageChildren = (data) =>
  instance.patch('pages/switch', data);
