import React, { useEffect, useRef, useState } from 'react';
import {
	Grid,
	AppBar,
	Button,
	TextField,
	IconButton,
	Toolbar,
	Typography,
	TablePagination,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { fileServices } from 'api/services';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import handleNotification from 'helpers/handleNotification';
import { IFile } from 'api/services/file/types';
import cn from 'classnames';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Confirmation from 'shared/Confirmation/Confirmation';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { FileTypes } from 'api/services/file/types';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import { SERVER_URL } from 'constants/project';
import CustomModal from 'shared/CustomModal/CustomModal';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import getImageByImageSize from 'helpers/getImageByImageSize';
import './FileUploader.sass';
import { RemoveRedEye, InsertDriveFile } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
	text: string;
	selectFile?(file: IFile): void;
	open?: boolean;
	staticPosition?: boolean;
	width?: number;
	height?: number;
	fileTypeVideo?: boolean;
	fileTypeFile?: boolean;
	fileTypeAudio?: boolean;
	fileTypeImage?: boolean;
	fileTypePDF?: boolean;
}

export const FileUploader: React.FC<Props> = ({
	text,
	selectFile,
	open,
	staticPosition,
	width,
	fileTypeFile,
	fileTypeVideo,
	fileTypeAudio,
	fileTypePDF,
	fileTypeImage,
	height,
}) => {
	const [dropzoneOpen, setDropzoneOpen] = useState<boolean>(open || false);
	const [selectedId, setSelectedId] = useState<number[]>([]);
	const [deleteFileId, setDeleteFileId] = useState<number>();
	const [fileObjects, setFileObjects] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [fileId, setFileId] = useState<number | undefined>();
	const [files, setFiles] = useState<IFile[]>([]);
	const [page, setPage] = useState<number>(0);
	const [take, setTake] = useState<number>(20);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [currentFile, setCurrentFile] = useState<IFile>();
	const [imageAlt, setImageAlt] = useState('');
	const confirmationRef = useRef<any>(null);
	const confirmationRef2 = useRef<any>(null);
	const modalRef = useRef<any>(null);
	const { main, statuses, images } = useSelector(
		(state: AppState) => state.langReducer.i18n,
	);

	useEffect(() => {
		setSelectedId([]);
		if (!files.length) getMedias(page, take);
	}, [dropzoneOpen]);

	const addMedia = async (file: any) => {
		const formData = new FormData();
		formData.append('file', file[0].file);
		if (width && height) {
			formData.append('width', width.toString());
			formData.append('height', height.toString());
		}

		try {
			await fileServices.uploadFile(formData).then((res) => res.data);
			handleNotification(main.added, 'success');
			getMedias(page, take);
		} catch (e: any) {
			handleNotification(e?.response.data.message || main.tryAgain);
		}
	};

	const checkType = () => {
		if (fileTypeAudio) return FileTypes.AUDIO;

		if (fileTypeVideo) return FileTypes.VIDEO;

		if (fileTypePDF) return FileTypes.PDF;

		if (fileTypeImage) return FileTypes.IMAGE;

		if (fileTypeFile) return FileTypes.FILE;
	};

	const getMedias = async (newPage: number, newTake: number) => {
		if (!dropzoneOpen) return;
		const allFormats =
			!fileTypeAudio && !fileTypeVideo && !fileTypeImage && !fileTypePDF;
		setLoading(true);
		const attrs = {
			take: newTake,
			skip: newPage * newTake,
			...(allFormats ? {} : { type: checkType() }),
		};
		const res = await fileServices.getFiles(attrs).then((res) => res.data);
		const data = res.data;
		setFiles(data);
		setTotalCount(res?.count);
		setLoading(false);
	};

	const choiceFile = (e: React.MouseEvent, file: IFile) => {
		const { id, src } = file;
		e.preventDefault();
		setSelectedId((prev) => {
			if (checkSelectedId(id)) {
				return prev.filter((mediaId) => mediaId !== id);
			}
			return [...prev, id];
		});
		if (selectFile) {
			selectFile(file);
			handleDropzone(false);
		}
	};

	const checkFormat = () => {
		let format = '';
		if (fileTypeAudio) format += 'audio/*,';
		if (fileTypeVideo) format += 'video/*,';
		if (fileTypePDF) format += 'application/pdf,';
		if (fileTypeImage) format += 'image/*';

		if (!fileTypeAudio && !fileTypeVideo && !fileTypePDF && !fileTypeImage) {
			format =
				'audio/*, video/*, image/*, application/pdf, .doc, .docx, .xls, .xlsx, .zip, .rar, .txt';
		}
		return format;
	};

	const clearState = () => {
		setSelectedId([]);
		setDeleteFileId(undefined);
		setFileId(undefined);
		setImageAlt('');
	};

	const deleteFiles = () => {
		if (selectedId.length === 0) {
			handleNotification(main.selectRemoveFile);
			return;
		}
		confirmationRef.current.onShow();
	};

	const deleteSelectedFiles = async () => {
		setLoading(true);
		try {
			await selectedId.forEach((i) => {
				fileServices.deleteFile(i);
			});
			setSelectedId([]);
			handleNotification(main.removedFiles, 'success');
			getMedias(page, take);
		} catch {
			handleNotification(main.tryAgain);
		} finally {
			setLoading(false);
		}
	};

	const checkSelectedId = (id: number) => {
		return selectedId.some((mediaId) => mediaId === id);
	};

	const handleDropzone = (value: boolean) => {
		setDropzoneOpen(value);
	};

	const editFile = (e: React.MouseEvent, file: IFile) => {
		e.stopPropagation();
		setCurrentFile(file);
		setFileId(file.id);
		modalRef.current.onShow();
	};

	const renderFile = (file: IFile) => {
		switch (file.type) {
			case FileTypes.IMAGE:
				return (
					<>
						<img
							className='media__list-image'
							src={getImageByImageSize({
								file: file,
								imageSize: '250x250',
							})}
							alt={file.alt || ''}
						/>
					</>
				);
			case FileTypes.PDF:
				return <PictureAsPdfIcon className='media__list-image' />;
			case FileTypes.AUDIO:
				return <AudiotrackIcon className='media__list-image' />;
			case FileTypes.VIDEO:
				return <OndemandVideoIcon className='media__list-image' />;
			case FileTypes.FILE:
				return <InsertDriveFile className='media__list-image' />;
			default:
				return <div className='media__list-empty'></div>;
		}
	};

	const submitEditFile = async () => {
		if (!fileId) return;
		try {
			await fileServices.updateFile(fileId, { alt: imageAlt });
			modalRef.current.onHide();
			handleNotification(statuses.success, 'success');
		} catch {
			handleNotification(main.tryAgain);
		}
	};

	const openWindow = (e: React.MouseEvent, file: IFile): void => {
		e.stopPropagation();
		window.open(`${SERVER_URL}/${file.src}`);
	};

	const deleteFileModal = (e: React.MouseEvent, id: number): void => {
		e.stopPropagation();
		confirmationRef2.current.onShow();
		setDeleteFileId(id);
	};

	const confirmDeleteFile = async () => {
		if (!deleteFileId) return;
		try {
			await fileServices.deleteFile(deleteFileId);
			handleNotification(main.removed, 'success');
			getMedias(page, take);
		} catch (e: any) {
			handleNotification(e?.response?.data?.message || main.tryAgain);
		}
		setDeleteFileId(undefined);
	};

	const changePage = (newPage: number, newTake: number) => {
		setPage(newPage);
		setTake(newTake);
		getMedias(newPage, newTake);
	};

	return (
		<div>
			{!dropzoneOpen && (
				<>
					{width && height && (
						<div className='form-item-file__sizes'>{`${main.recommendedSize} ${width} x ${height}`}</div>
					)}
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleDropzone(true)}>
						<AttachFileIcon className='file-uploader__add-icon' />
						{text}
					</Button>
				</>
			)}
			{dropzoneOpen && (
				<div
					className={cn('file-uploader', {
						'file-uploader__container': staticPosition,
					})}>
					{!staticPosition && (
						<AppBar className='file-uploader__header'>
							<Toolbar>
								<IconButton
									edge='start'
									color='inherit'
									onClick={() => handleDropzone(false)}
									aria-label='close'>
									<CloseIcon />
								</IconButton>
								<Typography variant='h6' className='file-uploader__title'>
									{main.close}
								</Typography>
							</Toolbar>
						</AppBar>
					)}
					<Typography
						variant='h3'
						className={cn('file-uploader__media-title', {
							'file-uploader__media-title--nomargin': staticPosition,
						})}>
						{main.mediathek}
					</Typography>
					<Grid container className='media'>
						<Grid item xs={12}>
							<DropzoneAreaBase
								acceptedFiles={[checkFormat()]}
								fileObjects={fileObjects}
								dropzoneText={main.selectImage}
								maxFileSize={200000000}
								showAlerts={false}
								filesLimit={1}
								dropzoneClass={cn('file-uploader__dropzone', {
									'file-loader__dropzone--hide': !dropzoneOpen,
								})}
								onAdd={addMedia}
							/>
							{totalCount > 0 && (
								<Typography variant='h3' className='media__total-count'>
									{main.numberOfFiles}: {totalCount}
								</Typography>
							)}
							<Grid className='media__list'>
								{files?.map((media) => (
									<div
										className={cn('media__list-item', {
											'media__list-item--selected': checkSelectedId(media.id),
										})}
										onClick={(e) => choiceFile(e, media)}
										key={media.id}>
										{renderFile(media)}
										{!selectFile && (
											<div className='media__list-item-actions'>
												<div
													className='media__list-item-actions-item'
													onClick={(e) => openWindow(e, media)}>
													{<RemoveRedEye />}
												</div>
												{media.type === 'IMAGE' && (
													<div
														className='media__list-item-actions-item'
														onClick={(e) => editFile(e, media)}>
														{<EditIcon />}
													</div>
												)}
												<div
													className='media__list-item-actions-item'
													onClick={(e) => deleteFileModal(e, media.id)}>
													{<DeleteForeverIcon />}
												</div>
											</div>
										)}
										{checkSelectedId(media.id) && (
											<CheckCircleIcon
												className='media__list-item-selected-icon'
												color='action'
											/>
										)}
									</div>
								))}
							</Grid>
							<Grid container alignItems='center' className='media__bottom'>
								{!selectFile && (
									<Grid item xs={6}>
										<Button
											variant='contained'
											color='secondary'
											onClick={deleteFiles}
											className='file-uploader__delete-btn'>
											{main.removeSelected}
										</Button>
									</Grid>
								)}
								<Grid item xs={selectFile ? 12 : 6}>
									<TablePagination
										labelRowsPerPage={main.quantityPerPage}
										count={totalCount}
										className='file-uploader__pagination'
										page={page}
										component='div'
										onPageChange={(e, newPage) => changePage(newPage, take)}
										rowsPerPageOptions={[20, 30, 50]}
										rowsPerPage={take}
										onRowsPerPageChange={(e) =>
											changePage(page, Number(e.target.value))
										}
									/>
								</Grid>
							</Grid>
							<Confirmation
								ref={confirmationRef}
								text={main.removeSomeFilesQuestions}
								submit={deleteSelectedFiles}
								title={main.removeSomeFiles}
								load={loading}
								changeState={() => clearState()}
							/>
							<Confirmation
								ref={confirmationRef2}
								text={main.removeOneFileQuestion}
								submit={confirmDeleteFile}
								title={main.removeOneFile}
								load={loading}
								changeState={() => clearState()}
							/>
						</Grid>
					</Grid>
				</div>
			)}
			<CustomModal
				ref={modalRef}
				submit={submitEditFile}
				title={images.imageName}
				changeState={clearState}
				text={main.save}
				load={loading}>
				<TextField
					variant='outlined'
					autoFocus
					label={main.name}
					value={imageAlt}
					onChange={(e) => setImageAlt(e.target.value as string)}
				/>
				{currentFile && (
					<div className='custom-modal__image'>
						<img
							src={getImageByImageSize({
								file: currentFile,
								imageSize: '250x250',
							})}
							alt=''
						/>
					</div>
				)}
			</CustomModal>
		</div>
	);
};
