import instance from 'api/instance';
import { history } from 'routes/history';
import { AuthTypes } from './types';

export const signIn = (accessToken: string) => {
	localStorage.setItem('accessToken', accessToken);
	instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

	return {
		type: AuthTypes.SIGN_IN,
		payload: {
			accessToken: accessToken,
		},
	};
};

export const signOut = () => {
	localStorage.setItem('accessToken', '');
	localStorage.setItem('user', '');
	history.push('/');
	return { type: AuthTypes.SIGN_OUT };
};
