import React, { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { InputAutoSize } from 'shared/InputAutoSize/InputAutoSize';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import Form from 'shared/Form/Form';
import handleNotification from 'helpers/handleNotification';
import { IComponent } from 'api/services/components/types';
import { componentsServices } from 'api/services';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { IForm, IFormView } from 'shared/Form/types';
import { UserRoles } from 'api/services/user/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

export const UpdateComponentPage: React.FC = () => {
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [componentData, setComponentData] = useState<IAnyObject>();
	const [properties, setProperties] = useState<IForm>();
	const [componentView, setComponentView] = useState<IFormView>();
	const [propertiesValue, setPropertiesValue] = useState('');
	const [componentViewValue, setComponentViewValue] = useState('');
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const formRef = useRef<any>();
	const { componentId } = useParams<{ componentId: string }>();
	const { user } = useSelector((state: AppState) => state.userReducer);
	const { currentLang, i18n } = useSelector(
		(state: AppState) => state.langReducer,
	);
	const { main, components } = i18n;
	const { pathname } = useLocation();

	useEffect(() => {
		getComponent();
	}, [currentLang?.code, pathname]);

	const getComponent = async () => {
		const res = await componentsServices
			.getComponentById(componentId)
			.then((res) => res.data);
		const { name, title, data, view, properties } = res;
		setName(name);
		setTitle(title);
		setComponentData(data);
		setProperties(properties);
		setComponentView(view);
		setPropertiesValue(properties ? JSON.stringify(properties) : '');
		setComponentViewValue(view ? JSON.stringify(view) : '');
	};

	const updateComponent = async (e: React.FormEvent) => {
		e.preventDefault();
		let validation = true;
		if (!name.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			validation = false;
		}

		if (!title.trim()) {
			handleNotification(main.fillFields);
			setHasError(true);
			validation = false;
		}

		if (!validation) return;

		const formRes = formRef?.current?.submit();
		if (formRes?.hasErrors && user?.role !== UserRoles.SU) {
			handleNotification(main.fillFields);
			return;
		}

		const data: IComponent = {
			name,
			title,
			data: formRes?.res ?? {},
			properties: JSON.parse(propertiesValue || '{}'),
			view: JSON.parse(componentViewValue || '[]'),
		};

		try {
			await componentsServices.updateComponent(componentId, data);
			handleNotification(components.updateComponent, 'success');
			getComponent();
		} catch (e: any) {
			handleNotification(e?.resposne?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={`${components.editComponent} - ${title}`} hideBtn>
			<form onSubmit={updateComponent} className='create-components-page'>
				<TextField
					variant='outlined'
					label={main.title}
					autoFocus
					className='template-page__field form-field'
					value={title}
					error={hasError && !title.trim()}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='template-page__field form-field'
					value={name}
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				<>
					{componentView && properties && (
						<Form
							ref={formRef}
							view={componentView}
							fields={properties}
							initialData={componentData}
						/>
					)}
					{user?.role === UserRoles.SU && (
						<>
							<InputAutoSize
								value={propertiesValue}
								placeholder={components.componentData}
								updateValue={setPropertiesValue}
							/>
							<InputAutoSize
								value={componentViewValue}
								placeholder={main.displaySettings}
								updateValue={setComponentViewValue}
							/>
						</>
					)}
				</>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						components.editComponent
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
