import instance from 'api/instance';
import {
  ICreateComponent,
  IDeleteComponent,
  IGetComponentById,
  IGetComponents,
  IUpdateComponent,
} from './types';
import qs from 'query-string';

export const getComponents: IGetComponents = (params) =>
  instance.get(`/components?${qs.stringify(params)}`);

export const createComponent: ICreateComponent = (data) =>
  instance.post('/components', data);

export const getComponentById: IGetComponentById = (id) =>
  instance.get(`/components/${id}`);

export const updateComponent: IUpdateComponent = (id, data) =>
  instance.put(`/components/${id}`, data);

export const deleteComponent: IDeleteComponent = (id) =>
  instance.delete(`/components/${id}`);
