import instance from 'api/instance';
import { IGetUsers, IUpdateUser, INewUser, IUserById } from './types';

// export const getUserProfile: IGetUser = () => instance.get(`users/profile`);

export const getUsers: IGetUsers = () => instance.get(`users`);

export const createUser: INewUser = (data) => instance.post('users', data);

export const updateUser: IUpdateUser = (id, data) =>
	instance.put(`users/${id}`, data);

export const getUserById: IUserById = (id) => instance.get(`users/${id}`);
